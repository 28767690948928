.custom-file {
    height: 150px;
    margin-bottom: 20px;
	.custom-file-input {
		height: 150px;		
		cursor: pointer;
	}
	.custom-file-label {
		height: 150px;
		border: none;
		font-size: $font-size-s;
        font-weight: $bold;
        color: $pcg-the-darkest-gray;
		background-color: $pcg-lightest-gray;
		text-align: center;
		line-height: 150px;
		border-radius: 5px;
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
		.pcg-file-loader,
		.pcg-icon-span  {
			text-align: center;
			display: table-row;
			width: 100%;
			height: 85px;	
			line-height: 85px;
		}
		.pcg-icon-span {		
			font-size: 46px;			
		}
		.pcg-text-span {
			display: table-row;
			line-height: 25px;
			height: 65px;
			width: 100%;
		}
		.pcg-upload-file{
			color: $pcg-light-gray;
		}
		.pcg-success-upload{
			color: $alert-success;
			span {
				color: $pcg-light-gray;
			}
		}
		i.pcg-error-upload {
			color: $alert-error;
		}
		.pcg-error-upload {
			color: $pcg-the-darkest-gray;
			span {
				color: $pcg-dark-gray;
			}
		}
		.pcg-loading-file {
			color: $main-active-color;
			span {
				color: $pcg-dark-gray;
			}
		}
		&.pcg-success {
			background-color: $alert-success-light;
			box-shadow: 0 3px 10px rgba(85, 198, 76, 0.2);
		}
		&.pcg-fail {
			background-color: $alert-error-light;
			box-shadow: 0 3px 10px rgba(252, 71, 71, 0.2);
		}
        &.pcg-loading {
            background-color: $additional-color;
            box-shadow: 0 3px 10px rgba($main-active-color, 0.2);
        }
		&::after {
			display: none;
		}
	}

	&:hover {
		.custom-file-label {
			background-color: $additional-color;
			box-shadow: 0 3px 10px rgba($main-active-color, 0.2);
			.pcg-upload-file,
			.pcg-success-upload,
			i.pcg-error-upload{
				color: $main-active-color;
			}
		}
	}	

    &:disabled {
      .custom-file-label {
        background-color: $disabled-color;
        box-shadow: 0 3px 10px rgba($disabled-color, 0.2);
        .pcg-upload-file,
        .pcg-success-upload,
        i.pcg-error-upload{
            color: $pcg-white;
        }
      }
    }
}