.nav-tabs {
  .nav-item {
    .nav-link {
      font-size: $font-size-m;
      font-weight: $bold;
      line-height: 1.36rem;
      border: none;
      padding-bottom: 0.71rem;
      color: $pcg-light-gray;
      &.active {        
        background-color: transparent;
        color: $main-active-color;
        border-bottom: 2px solid $main-active-color;
      }
    }    
  }  
}
.tab-content {
  .tab-pane {
    margin-top: 1.43rem;
  }
}

.pcg-slide-tabs {
  display: inline-block;
  border-radius: 100px;
  background-color: $disabled-color;
  box-shadow: 0px 2px 3px rgba(0,0,0, 0.05);  
  .pcg-slide-tab-btn {
    margin: 0;    
    border-radius: 100px;
    font-weight: $bold;
    cursor: pointer;
    background-color: $disabled-color;
    color: $pcg-white;
    border: none;
    padding: 0 2.14rem;
    font-size: $font-size-s;
    height: 2.14rem;
    @extend .pcg-button-without-outline;
    &.active {
      border: 2px solid $main-active-color;
      background-color: $main-active-color;
      box-shadow: 0px 2px 3px rgba($main-active-color, 1);  
    }
    &:disabled {
      cursor: not-allowed;
    }
  }  
}