body, html {
    font-size: $font-size-base;
    font-family: 'Roboto', sans-serif;    
    font-weight: $regular;
    background-color: $pcg-the-lightest-gray;
}

.pcg-wcag-contrast-green-black {
    background-color: $color-wcag-background;
    body, html {
        background-color: $color-wcag-background;
    }
}


.text-size-1{
    body{
        font-size: 16px;
    }
}

.text-size-2{
    body{
        font-size: 18px;
    }
}