.bootstrap-select {
    &:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
        width: 100%;
    }    
    .dropdown-toggle {
        color: $pcg-gray;
        box-shadow: 0 3px 10px rgba(32, 19, 19, 0.2);
        border-radius: 100px;
        height: 2.86rem;
        line-height: 1rem;
        font-size: $font-size-s;
        padding: 1rem 1.5rem 0.86rem 1.5rem;
        border: none;
        background-color: $pcg-white;   
        &::after {
            color: $main-active-color;
        }
        &:hover {
            box-shadow: 0 3px 10px rgba($main-active-color, 0.3);
            color: $main-active-color;
            background-color: $pcg-white;   
        }        
        @extend .pcg-button-without-outline;
        .filter-option-inner-inner{
            text-overflow: ellipsis;
        }
		&:focus {
			outline: none !important;
		}
    }
    &.show {
        .dropdown-toggle {
            color: $pcg-white;
            background-color: $main-active-color;
            &::after {
                color: $pcg-white;
            }
        }
    }
    .dropdown-menu {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        margin-top: 0.71rem;
        padding: 0;
        background-color: $pcg-white;
        font-size: $font-size-s;
        font-weight: $regular;
        line-height: 1rem;
        border: none;
        .dropdown-item {
            color: $pcg-gray;
            padding: 1.29rem 1.43rem;
            white-space: initial; 
			a:focus{
				outline: none !important;
			}
            &:hover {
                background-color: $pcg-lightest-gray;
                color: $main-active-color;
            }
            &.selected,
            &.active {                
                font-weight: $bold;
                background-color: $main-select-color;
                color: $pcg-white;
            }
        }
    }
}

.dropdown-list {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 0.71rem;
    padding: 0 !important;
    background-color: $pcg-white;
    font-size: $font-size-s;
    font-weight: $regular;
    line-height: 1rem;
    border-radius: 5px;
    .arrow-up {
        display: none;
    }
    .list-area {
        border-radius: 5px;
        border: none;
        .select-all {
            color: $pcg-gray;
            border: none;
        }
        .list-filter {
            color: $pcg-gray;
            border-radius: 100px;
            border: solid 1px $pcg-gray;
            padding-right: 1.43rem;
        }
    }
    ul {
        border-radius: 5px;
        padding: 0 !important;
        li.pure-checkbox {
            color: $pcg-gray;
            padding: 1.29rem 0.5rem;
            white-space: initial; 
            line-height: 1rem;
            &:hover {
                background-color: $pcg-lightest-gray;
                color: $main-active-color;
            }
            &.selected-item {                
                font-weight: $bold;
                background-color: $main-select-color;
                color: $pcg-white;
            }
        }
        li {
            &:first-child {
                padding-top: 1.29rem !important;
            }
            &:last-child {
                padding-bottom: 1.29rem !important;
            }
            label {
                margin: 0;
                cursor: pointer;
            }
        }
    }
}
//style for angular2-multiselect-dropdown    
.pcg-select {
    .selected-list {
        color: $pcg-gray;
        box-shadow: 0 3px 10px rgba(32, 19, 19, 0.2);
        border-radius: 100px;
        line-height: 1rem;
        font-size: $font-size-s;
        border: none;
        background-color: $pcg-white;   
        cursor: pointer;
        span {
            padding-left: 0.36rem;
        }
        .c-btn {
            width: unset !important;
            @extend .pcg-button-without-outline;
        }
        .c-angle-down,
        .c-angle-up {
            right: 1.43rem !important;
            width: 0.71rem !important;
            svg {
                fill: $main-active-color !important;
            }
        }
        &:hover {
            box-shadow: 0 3px 10px rgba($main-active-color, 0.3);
            color: $main-active-color;
            background-color: $pcg-white;   
        }  
    }
    &.ng-invalid {
        .selected-list{
            box-shadow: 0 3px 10px rgba($alert-error, 0.3);
        }
    }
} 
//style for ng-select
.pcg-select {
    .ng-arrow-wrapper {
        width: auto;
        padding-right: 1.43rem;
        .ng-arrow {
            display: none !important;
            font-size: .875rem;
        }
    }
    &.ng-select {
        .ng-select-container {
            padding-left: .71rem;
            background-color: $pcg-white;
            border-radius: 100px;
            border: none;
            color: $pcg-the-darkest-gray;
            height: 2.86rem;    
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            font-size: $font-size-s; 
            font-weight: $regular;
            cursor: pointer;
            input {
                cursor: pointer !important;
            }
            .ng-arrow-wrapper:before {
                content: $pcg-icon-arrow-down;
                display: inline-block;
                font: normal normal normal 1.43rem/1 'PCGFont';
                font-size: 1.43rem;              
                vertical-align: middle;
                text-rendering: auto;
                color: $main-active-color;
            }                
            
            .ng-value-container .ng-input >input{
                padding-left: .71rem;
                padding-top: .5rem;
                font-size: $font-size-s;
                font-weight: $regular;
                text-rendering: auto;
                color: $pcg-white;
            }
        }
        &.ng-select-multiple {
            .ng-select-container .ng-value-container .ng-value {
                background-color: $additional-color;
                border-color: $main-select-color;
                color: $pcg-the-darkest-gray;
                .ng-value-icon.left {
                    border-right-color: $main-select-color;
                    &:hover {
                        color: $pcg-white;
                        background-color: $main-select-color;
                    }
                }
            }
        }
    }    
  
    &:hover {
        &.ng-select:not(.ng-select-opened, .ng-select-focused) {
            .ng-select-container {
                color: $main-active-color;
                box-shadow: 0 3px 10px rgba($main-active-color, 0.3);              
            }
        }    
    }
    &.ng-select-disabled {
        &.ng-select .ng-select-container {
            background-color: $pcg-lightest-gray;
            color: $pcg-light-gray;
            box-shadow: none;  
            cursor: not-allowed; 
            input {
                cursor: not-allowed !important;
            }
            .ng-arrow-wrapper:before {
                color: $pcg-light-gray;
            }                       
        }
    }
    &.ng-select-opened, &.ng-select-focused {
        &.ng-select-bottom,
        &.ng-select-top { 
            .ng-select-container {
              .ng-value-container .ng-placeholder{
                color: $pcg-white;
              }              
                border-radius: 100px;
                color: $pcg-white;
                background-color: $main-active-color;
                box-shadow: 0 3px 10px rgba($main-active-color, 0.3);
                .ng-arrow-wrapper:before {
                    content: $pcg-icon-arrow-down;
                    display: inline-block;
                    font: normal normal normal 1.43rem/1 'PCGFont';
                    font-size: 1.43rem;
                    text-rendering: auto;
                    vertical-align: middle;
                    color: $main-active-color;
                }
            }
        }
    }
    &.ng-select-focused {
        &.ng-select-bottom,
        &.ng-select-top {
            .ng-select-container {
                .ng-arrow-wrapper:before {
                    content: $pcg-icon-arrow-down;
                    color: $pcg-white;
                }
            }
        }
    }
    &.ng-select-opened {
         &.ng-select-bottom,
         &.ng-select-top {
            .ng-select-container {
                .ng-arrow-wrapper:before {
                    content: $pcg-icon-arrow-up;
                    color: $pcg-white;
                }
            }
        }
    }
    &.ng-dropdown-panel {
        ::-webkit-scrollbar {
          width: .71rem !important;
        }
        min-width: min-content;
        margin-top: .71rem;
        border: none;
        border-radius: 5px;
        .ng-dropdown-panel-items {
            border-radius: 5px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            .ng-option {
                white-space: normal;
                color: $pcg-gray;
                font-size: $font-size-s;
                font-weight: $regular; 
                line-height: 1rem;
                padding: 1.29rem 1.43rem; 
    
                &.ng-option-marked, &.ng-option-selected {
                    color: $main-active-color;
                    background-color: $pcg-lightest-gray;
                    font-weight: $bold; 
                }
            }
        }        
    }   
    &.ng-invalid {
      .ng-select-container {
          box-shadow: 0 3px 10px rgba($alert-error, 0.3);
      }
    } 
  }