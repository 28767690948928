.popover {
    background-color: $main-color;    
    border-radius: 10px;
    .popover-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        &::before {
            border: none;
        }
    }
    .popover-body {
        border-radius: 10px;
    }
    .popover-header,
    .popover-body {
        background-color: $main-color;    
        color: $pcg-white;
        font-size: $font-size-xs;
        font-weight: $regular;
        padding:  0.71rem 1.43rem;
    }
}
.bs-popover-left .arrow:after {
    border-left-color: $main-color;
}
.bs-popover-right .arrow:after {
    border-right-color: $main-color;
}
.bs-popover-top .arrow:after {
    border-top-color: $main-color;
}
.bs-popover-bottom .arrow:after {
    border-bottom-color: $main-color;
}