.pcg-search {
    margin-bottom: 1.43rem;
    .pcg-input {
        padding-right: 2.86rem;
    } 
    span {
        float: right;
        margin-right: 1.46rem;
        margin-top: -1.80rem;
        position: relative;
        z-index: 2;
        color: $main-active-color;
    }
}