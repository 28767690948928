.pcg-languages-list {
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    width: 60px;
    height: 20px;
    background: $main-active-color;
    box-shadow: $header_buttons_shadow;
    z-index: 1000; 
    border-radius: 100px;
    line-height: initial;

    &:hover{
        background: $main-select-color;
    }

    & .selected-language {
        color: $pcg-white;
        font-size: $font-size-xs;
        font-weight: $medium;
        padding: 0 7px;
        display: flex;
        align-items: center;
        height: 100%;
    }

    & .language-arrow {
        margin-left: auto;
    }
}

.pcg-languages-list > .pcg-lang-options {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top:5px;
    list-style-type: none;
    background: $pcg-white;
    border-radius: 5px;
    opacity: 1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    
    & > a,
    & > button{
        border: none; 
        background-color: transparent;
        width: 100%;
        min-height: 20px;
        padding: 3px 7px;
        display: flex;
        align-items: center;

        &:hover{
            background-color: #EEF0F5;
        }

        &:first-child{
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-child{
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
 
    a {
        text-decoration: none;
    }
}

.pcg-languages-list.show > .pcg-lang-options {
    display: block;
}
 
.lenguage-name {
	color: $pcg-gray;
	font-size: $font-size-xs;
}

.lenguage-icon {
    margin-right: 3px;
    width: 14px;
    height: 14px;
}

.icon-pl {
	background-image: url('../../images/src/republic-of-poland.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.icon-en {
	background-image: url('../../images/src/united-kingdom.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.icon-es {
    background-image: url('../../images/src/spain.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.icon-it {
    background-image: url('../../images/src/italy.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.icon-de {
    background-image: url('../../images/src/germany.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.icon-fr {
    background-image: url('../../images/src/france.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.pcg-wcag-contrast-green-black {
    .pcg-languages-list {
        background: $color-wcag-contrast;
        box-shadow: none;

        &:hover{
            background: $color-wcag-contrast;
            opacity: 80%;
        }
    
        & .selected-language {
            color: $color-wcag-background;
        }
    }
    
    .pcg-languages-list > .pcg-lang-options {
        background: $color-wcag-background;
        border: 1px solid $color-wcag-contrast;
     
        & > button{    
 
            &:hover{
                background-color: $color-wcag-contrast;
                & .lenguage-name {
                    color: $color-wcag-background;
                }
            }
        }
    }
     
    .lenguage-name {
        color: $color-wcag-contrast;
    }
}