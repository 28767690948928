$offset: 187;
$duration: 1.4s;

.pcg-spinner {
    animation: rotator $duration linear infinite;
    width: 4.64rem;
    height: 4.64rem;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.pcg-spinner-path,
.pcg-spinner-path-file {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
}
.pcg-spinner-path {
    animation:
        dash $duration ease-in-out infinite, 
        colors ($duration*4) ease-in-out infinite;
}
.pcg-spinner-path-file {
    animation:
        dash $duration ease-in-out infinite, 
        colors2 ($duration*2) ease-in-out infinite;
}

@keyframes colors2 {
	0% { stroke: $main-color; }
    100% { stroke: $main-color; }
}

@keyframes colors {
	0% { stroke: #4285F4; }
	25% { stroke: #DE3E35; }
	50% { stroke: #F7C223; }
	75% { stroke: #1B9A59; }
    100% { stroke: #4285F4; }
}

@keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
        stroke-dashoffset: $offset/4;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform:rotate(450deg);
    }
}