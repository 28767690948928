@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('./#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tn0orq');
  src:  url('./#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tn0orq#iefix') format('embedded-opentype'),
    url('./#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tn0orq') format('truetype'),
    url('./#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tn0orq') format('woff'),
    url('./#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tn0orq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
 
[class^="pcg-icon-"], [class*=" pcg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pcg-icon-circle-person-view {
  &:before {
    content: $pcg-icon-circle-person-view; 
  }
}
.pcg-icon-circle-phone {
  &:before {
    content: $pcg-icon-circle-phone; 
  }
}
.pcg-icon-circle-picture {
  &:before {
    content: $pcg-icon-circle-picture; 
  }
}
.pcg-icon-circle-printer {
  &:before {
    content: $pcg-icon-circle-printer; 
  }
}
.pcg-icon-circle-radio {
  &:before {
    content: $pcg-icon-circle-radio; 
  }
}
.pcg-icon-circle-ranking {
  &:before {
    content: $pcg-icon-circle-ranking; 
  }
}
.pcg-icon-circle-reset-settings {
  &:before {
    content: $pcg-icon-circle-reset-settings; 
  }
}
.pcg-icon-circle-rotate-left {
  &:before {
    content: $pcg-icon-circle-rotate-left; 
  }
}
.pcg-icon-circle-rotate-right {
  &:before {
    content: $pcg-icon-circle-rotate-right; 
  }
}
.pcg-icon-circle-save {
  &:before {
    content: $pcg-icon-circle-save; 
  }
}
.pcg-icon-circle-share {
  &:before {
    content: $pcg-icon-circle-share; 
  }
}
.pcg-icon-circle-size-decrease {
  &:before {
    content: $pcg-icon-circle-size-decrease; 
  }
}
.pcg-icon-circle-size-increase {
  &:before {
    content: $pcg-icon-circle-size-increase; 
  }
}
.pcg-icon-circle-trash {
  &:before {
    content: $pcg-icon-circle-trash; 
  }
}
.pcg-icon-circle-upload {
  &:before {
    content: $pcg-icon-circle-upload; 
  }
}
.pcg-icon-clone {
  &:before {
    content: $pcg-icon-clone; 
  }
}
.pcg-icon-close {
  &:before {
    content: $pcg-icon-close; 
  }
}
.pcg-icon-comment {
  &:before {
    content: $pcg-icon-comment; 
  }
}
.pcg-icon-contact {
  &:before {
    content: $pcg-icon-contact; 
  }
}
.pcg-icon-contact-book {
  &:before {
    content: $pcg-icon-contact-book; 
  }
}
.pcg-icon-cookie {
  &:before {
    content: $pcg-icon-cookie; 
  }
}
.pcg-icon-copy {
  &:before {
    content: $pcg-icon-copy; 
  }
}
.pcg-icon-cross {
  &:before {
    content: $pcg-icon-cross; 
  }
}
.pcg-icon-delete-user {
  &:before {
    content: $pcg-icon-delete-user; 
  }
}
.pcg-icon-document {
  &:before {
    content: $pcg-icon-document; 
  }
}
.pcg-icon-dormitory {
  &:before {
    content: $pcg-icon-dormitory; 
  }
}
.pcg-icon-employees {
  &:before {
    content: $pcg-icon-employees; 
  }
}
.pcg-icon-enrollment {
  &:before {
    content: $pcg-icon-enrollment; 
  }
}
.pcg-icon-envelope {
  &:before {
    content: $pcg-icon-envelope; 
  }
}
.pcg-icon-expand-arrows {
  &:before {
    content: $pcg-icon-expand-arrows; 
  }
}
.pcg-icon-facebook {
  &:before {
    content: $pcg-icon-facebook; 
  }
}
.pcg-icon-file-about {
  &:before {
    content: $pcg-icon-file-about; 
  }
}
.pcg-icon-file-check {
  &:before {
    content: $pcg-icon-file-check; 
  }
}
.pcg-icon-file-marks {
  &:before {
    content: $pcg-icon-file-marks; 
  }
}
.pcg-icon-filter {
  &:before {
    content: $pcg-icon-filter; 
  }
}
.pcg-icon-first-cycle-studies {
  &:before {
    content: $pcg-icon-first-cycle-studies; 
  }
}
.pcg-icon-first-cycle-studies2 {
  &:before {
    content: $pcg-icon-first-cycle-studies2; 
  }
}
.pcg-icon-foreigners {
  &:before {
    content: $pcg-icon-foreigners; 
  }
}
.pcg-icon-graduation-cap {
  &:before {
    content: $pcg-icon-graduation-cap; 
  }
}
.pcg-icon-hand-holding-money {
  &:before {
    content: $pcg-icon-hand-holding-money; 
  }
}
.pcg-icon-help {
  &:before {
    content: $pcg-icon-help; 
  }
}
.pcg-icon-home {
  &:before {
    content: $pcg-icon-home; 
  }
}
.pcg-icon-info {
  &:before {
    content: $pcg-icon-info; 
  }
}
.pcg-icon-instagram {
  &:before {
    content: $pcg-icon-instagram; 
  }
}
.pcg-icon-insurance {
  &:before {
    content: $pcg-icon-insurance; 
  }
}
.pcg-icon-linkedin {
  &:before {
    content: $pcg-icon-linkedin; 
  }
}
.pcg-icon-log-history {
  &:before {
    content: $pcg-icon-log-history; 
  }
}
.pcg-icon-logout {
  &:before {
    content: $pcg-icon-logout; 
  }
}
.pcg-icon-maps {
  &:before {
    content: $pcg-icon-maps; 
  }
}
.pcg-icon-marks {
  &:before {
    content: $pcg-icon-marks; 
  }
}
.pcg-icon-master-degree {
  &:before {
    content: $pcg-icon-master-degree; 
  }
}
.pcg-icon-master-degree2 {
  &:before {
    content: $pcg-icon-master-degree2; 
  }
}
.pcg-icon-money {
  &:before {
    content: $pcg-icon-money; 
  }
}
.pcg-icon-news {
  &:before {
    content: $pcg-icon-news; 
  }
}
.pcg-icon-notify {
  &:before {
    content: $pcg-icon-notify; 
  }
}
.pcg-icon-paperclip {
  &:before {
    content: $pcg-icon-paperclip; 
  }
}
.pcg-icon-pdf {
  &:before {
    content: $pcg-icon-pdf; 
  }
}
.pcg-icon-person {
  &:before {
    content: $pcg-icon-person; 
  }
}
.pcg-icon-persons {
  &:before {
    content: $pcg-icon-persons; 
  }
}
.pcg-icon-person-view {
  &:before {
    content: $pcg-icon-person-view; 
  }
}
.pcg-icon-picture {
  &:before {
    content: $pcg-icon-picture; 
  }
}
.pcg-icon-plus {
  &:before {
    content: $pcg-icon-plus; 
  }
}
.pcg-icon-postgraduate-studies {
  &:before {
    content: $pcg-icon-postgraduate-studies; 
  }
}
.pcg-icon-postgraduate-studies2 {
  &:before {
    content: $pcg-icon-postgraduate-studies2; 
  }
}
.pcg-icon-printer {
  &:before {
    content: $pcg-icon-printer; 
  }
}
.pcg-icon-printer-small {
  &:before {
    content: $pcg-icon-printer-small; 
  }
}
.pcg-icon-proces-done {
  &:before {
    content: $pcg-icon-proces-done; 
  }
}
.pcg-icon-proces-error {
  &:before {
    content: $pcg-icon-proces-error; 
  }
}
.pcg-icon-proces-in-progress {
  &:before {
    content: $pcg-icon-proces-in-progress; 
  }
}
.pcg-icon-proces-negative-decision {
  &:before {
    content: $pcg-icon-proces-negative-decision; 
  }
}
.pcg-icon-proces-positive-decision {
  &:before {
    content: $pcg-icon-proces-positive-decision; 
  }
}
.pcg-icon-radio {
  &:before {
    content: $pcg-icon-radio; 
  }
}
.pcg-icon-save {
  &:before {
    content: $pcg-icon-save; 
  }
}
.pcg-icon-scholarship {
  &:before {
    content: $pcg-icon-scholarship; 
  }
}
.pcg-icon-search {
  &:before {
    content: $pcg-icon-search; 
  }
}
.pcg-icon-second-cycle-studies {
  &:before {
    content: $pcg-icon-second-cycle-studies; 
  }
}
.pcg-icon-second-cycle-studies2 {
  &:before {
    content: $pcg-icon-second-cycle-studies2; 
  }
}
.pcg-icon-send {
  &:before {
    content: $pcg-icon-send; 
  }
}
.pcg-icon-settings {
  &:before {
    content: $pcg-icon-settings; 
  }
}
.pcg-icon-star {
  &:before {
    content: $pcg-icon-star; 
  }
}
.pcg-icon-statistics {
  &:before {
    content: $pcg-icon-statistics; 
  }
}
.pcg-icon-status-closed {
  &:before {
    content: $pcg-icon-status-closed; 
  }
}
.pcg-icon-status-deleted {
  &:before {
    content: $pcg-icon-status-deleted; 
  }
}
.pcg-icon-status-obligatory {
  &:before {
    content: $pcg-icon-status-obligatory; 
  }
}
.pcg-icon-status-selected {
  &:before {
    content: $pcg-icon-status-selected; 
  }
}
.pcg-icon-status-to-select {
  &:before {
    content: $pcg-icon-status-to-select; 
  }
}
.pcg-icon-system-support {
  &:before {
    content: $pcg-icon-system-support; 
  }
}
.pcg-icon-third-cycle-studies {
  &:before {
    content: $pcg-icon-third-cycle-studies; 
  }
}
.pcg-icon-third-cycle-studies2 {
  &:before {
    content: $pcg-icon-third-cycle-studies2; 
  }
}
.pcg-icon-time {
  &:before {
    content: $pcg-icon-time; 
  }
}
.pcg-icon-trash {
  &:before {
    content: $pcg-icon-trash; 
  }
}
.pcg-icon-twitter {
  &:before {
    content: $pcg-icon-twitter; 
  }
}
.pcg-icon-university {
  &:before {
    content: $pcg-icon-university; 
  }
}
.pcg-icon-university-guide {
  &:before {
    content: $pcg-icon-university-guide; 
  }
}
.pcg-icon-youtube {
  &:before {
    content: $pcg-icon-youtube; 
  }
}
.pcg-icon-add {
  &:before {
    content: $pcg-icon-add; 
  }
}
.pcg-icon-add-comment {
  &:before {
    content: $pcg-icon-add-comment; 
  }
}
.pcg-icon-add-message {
  &:before {
    content: $pcg-icon-add-message; 
  }
}
.pcg-icon-adjust {
  &:before {
    content: $pcg-icon-adjust; 
  }
}
.pcg-icon-arrow-down {
  &:before {
    content: $pcg-icon-arrow-down; 
  }
}
.pcg-icon-arrow-left {
  &:before {
    content: $pcg-icon-arrow-left; 
  }
}
.pcg-icon-arrow-long-right {
  &:before {
    content: $pcg-icon-arrow-long-right; 
  }
}
.pcg-icon-arrow-right {
  &:before {
    content: $pcg-icon-arrow-right; 
  }
}
.pcg-icon-arrows {
  &:before {
    content: $pcg-icon-arrows; 
  }
}
.pcg-icon-arrow-slim-left {
  &:before {
    content: $pcg-icon-arrow-slim-left; 
  }
}
.pcg-icon-arrow-up {
  &:before {
    content: $pcg-icon-arrow-up; 
  }
}
.pcg-icon-attachment {
  &:before {
    content: $pcg-icon-attachment; 
  }
}
.pcg-icon-automatic {
  &:before {
    content: $pcg-icon-automatic; 
  }
}
.pcg-icon-bell {
  &:before {
    content: $pcg-icon-bell; 
  }
}
.pcg-icon-burger {
  &:before {
    content: $pcg-icon-burger; 
  }
}
.pcg-icon-calendar {
  &:before {
    content: $pcg-icon-calendar; 
  }
}
.pcg-icon-catalog {
  &:before {
    content: $pcg-icon-catalog; 
  }
}
.pcg-icon-certification {
  &:before {
    content: $pcg-icon-certification; 
  }
}
.pcg-icon-check {
  &:before {
    content: $pcg-icon-check; 
  }
}
.pcg-icon-check-double {
  &:before {
    content: $pcg-icon-check-double; 
  }
}
.pcg-icon-check-list {
  &:before {
    content: $pcg-icon-check-list; 
  }
}
.pcg-icon-children-university {
  &:before {
    content: $pcg-icon-children-university; 
  }
}
.pcg-icon-children-university2 {
  &:before {
    content: $pcg-icon-children-university2; 
  }
}
.pcg-icon-circle-add-comment {
  &:before {
    content: $pcg-icon-circle-add-comment; 
  }
}
.pcg-icon-circle-add-message {
  &:before {
    content: $pcg-icon-circle-add-message; 
  }
}
.pcg-icon-circle-add-user {
  &:before {
    content: $pcg-icon-circle-add-user; 
  }
}
.pcg-icon-circle-attachment {
  &:before {
    content: $pcg-icon-circle-attachment; 
  }
}
.pcg-icon-circle-brightness-decrease {
  &:before {
    content: $pcg-icon-circle-brightness-decrease; 
  }
}
.pcg-icon-circle-brightness-increase {
  &:before {
    content: $pcg-icon-circle-brightness-increase; 
  }
}
.pcg-icon-circle-calendar {
  &:before {
    content: $pcg-icon-circle-calendar; 
  }
}
.pcg-icon-circle-check {
  &:before {
    content: $pcg-icon-circle-check; 
  }
}
.pcg-icon-circle-check-list {
  &:before {
    content: $pcg-icon-circle-check-list; 
  }
}
.pcg-icon-circle-clone {
  &:before {
    content: $pcg-icon-circle-clone; 
  }
}
.pcg-icon-circle-concrast-decrease {
  &:before {
    content: $pcg-icon-circle-concrast-decrease; 
  }
}
.pcg-icon-circle-concrast-increase {
  &:before {
    content: $pcg-icon-circle-concrast-increase; 
  }
}
.pcg-icon-circle-contact-book {
  &:before {
    content: $pcg-icon-circle-contact-book; 
  }
}
.pcg-icon-circle-copy {
  &:before {
    content: $pcg-icon-circle-copy; 
  }
}
.pcg-icon-circle-create {
  &:before {
    content: $pcg-icon-circle-create; 
  }
}
.pcg-icon-circle-cross {
  &:before {
    content: $pcg-icon-circle-cross; 
  }
}
.pcg-icon-circle-details {
  &:before {
    content: $pcg-icon-circle-details; 
  }
}
.pcg-icon-circle-document {
  &:before {
    content: $pcg-icon-circle-document; 
  }
}
.pcg-icon-circle-download {
  &:before {
    content: $pcg-icon-circle-download; 
  }
}
.pcg-icon-circle-ellipsis {
  &:before {
    content: $pcg-icon-circle-ellipsis; 
  }
}
.pcg-icon-circle-employees {
  &:before {
    content: $pcg-icon-circle-employees; 
  }
}
.pcg-icon-circle-enrollment {
  &:before {
    content: $pcg-icon-circle-enrollment; 
  }
}
.pcg-icon-circle-envelope {
  &:before {
    content: $pcg-icon-circle-envelope; 
  }
}
.pcg-icon-circle-expand-arrows {
  &:before {
    content: $pcg-icon-circle-expand-arrows; 
  }
}
.pcg-icon-circle-file-about {
  &:before {
    content: $pcg-icon-circle-file-about; 
  }
}
.pcg-icon-circle-file-check {
  &:before {
    content: $pcg-icon-circle-file-check; 
  }
}
.pcg-icon-circle-file-marks {
  &:before {
    content: $pcg-icon-circle-file-marks; 
  }
}
.pcg-icon-circle-hand-holding-money {
  &:before {
    content: $pcg-icon-circle-hand-holding-money; 
  }
}
.pcg-icon-circle-location-arrow {
  &:before {
    content: $pcg-icon-circle-location-arrow; 
  }
}
.pcg-icon-circle-marks {
  &:before {
    content: $pcg-icon-circle-marks; 
  }
}
.pcg-icon-circle-message {
  &:before {
    content: $pcg-icon-circle-message; 
  }
}
.pcg-icon-circle-money {
  &:before {
    content: $pcg-icon-circle-money; 
  }
}
.pcg-icon-circle-pencil {
  &:before {
    content: $pcg-icon-circle-pencil; 
  }
}
.pcg-icon-circle-persons {
  &:before {
    content: $pcg-icon-circle-persons; 
  }
}

