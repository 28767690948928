.pcg-notification-group {    
    cursor: pointer;
    display: inline-block;
    .pcg-notification-container {
        display: none;
        position: absolute;
        z-index: 4;
        width: 21.43rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background-color: $pcg-white;
        &.pcg-notification-open {
            display: block;
        }
        .pcg-notification-header {
            color: $main-color;
            font-size: $font-size-m;
            font-weight: $bold;
            line-height: 1.36rem;
            padding-top: 1.5rem;
            padding-bottom: 1.43rem;
            text-align: center;
        }
        .pcg-notification-body {
            padding-bottom: 1.43rem;
            line-height: 1.07rem;
            .pcg-notification {
                padding: 0.68rem 2.14rem;
                font-size: $font-size-s;
                background-color: $pcg-the-lightest-gray;
                border-bottom: 1px solid $pcg-white;
                cursor: pointer;
                .pcg-notification-icon {        
                    font-size: 2.93rem;
                    padding: 0;
                }
                .pcg-notification-title,
                .pcg-notification-message {
                    overflow: hidden;
                    white-space: nowrap; 
                    text-overflow: ellipsis; 
                    padding-right: 0;
                }
                .pcg-notification-title {
                    color: $pcg-the-darkest-gray;
                    font-weight: $bold;
                    padding-bottom: 0.36rem;
                }
                .pcg-notification-message {
                    color: $pcg-dark-gray;
                }
                &.pcg-notification-unreaded {
                    background-color: $additional-color;
                    .pcg-notification-title,
                    .pcg-notification-message{
                        color: $main-active-color;
                    }
                }
            }
        }
    }
}