.pcg-profile-group {
    display: inline-block;
    margin-left: auto;
    cursor: pointer;
    .pcg-profile-btn {
        width: 63px; 
        height: 2.86rem;
        background-color: $pcg-the-lightest-gray;
        color: $main-active-color;
        border-radius: 100px;
        text-align: center;
        border: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        padding: 0 2px;
        .pcg-icon-person{
            font-size: 18px;
            margin: 8px;
            vertical-align: middle;
            padding: 0rem;
        }
        .pcg-icon-profile-arrow {
            font-size: 1.2rem;
            vertical-align: middle;
            @extend .pcg-icon-arrow-down;
        }
        &.show {
            .pcg-icon-profile-arrow {
                @extend .pcg-icon-arrow-up;
            }
        }
        @extend .pcg-button-without-outline;
        &:after {
            display: none;
        }
    }
    .pcg-profile-btn-img {
        @extend .pcg-profile-btn;
        width: max-content;
        padding: 0 0.57rem 0 0.57rem;
        text-align: right;
        .pcg-profile-img {
            width: 2.21rem;
            height: 2.21rem;
            display: inline-block;
            border-radius: 50%;
        }
        
    }
    &.show {
        .pcg-profile-btn {
            .pcg-icon-profile-arrow {
                @extend .pcg-icon-arrow-up;
            }
        }
        .pcg-profile-container {
            display: block;
            right: 1.4rem;
            width: 200px;
            a:last-child{
                border-radius: 0 0 5px 5px;
            }
            a:first-child{
                border-radius:  5px 5px 0 0;
            }
            @include medium-up {
                right: 4.11rem;
            }

        }
    }
    &.btn-group {
        display: inline-flex;
    }
}

.pcg-profile-container,
.dropdown-menu {
    border: none;
    display: none; 
    position: absolute;
    z-index: 4;
    padding: 0px;
    margin-top: 0.86rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: $pcg-white;
    .pcg-profile-option {
        line-height: 3.57rem;
        padding: 0 1.43rem;
        cursor: pointer;
        .pcg-profile-option-txt,
        .pcg-font-icon {
            font-size: $font-size-s;
            display: inline-block;
            color: $pcg-the-darkest-gray;
            font-weight: $medium;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        } 
        .pcg-font-icon {
            margin-right: 1.43rem;
            width: 1rem;
            text-align: center;
        }        
        &:active,
        &:hover {
            background-color: $pcg-lightest-gray;
            color: $main-active-color;
            .pcg-profile-option-txt,
            .pcg-font-icon {
                color: $main-active-color;
            } 
        }
    }
    &.show {
        display: block;
    }
}