.pcg-header-container {
    height: $header-height;
    position: relative;

    .pcg-navbar-container {
        z-index: 1;
        width: 100%;
        background-color: $pcg-white;
        min-height: 65px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        border-bottom: 1px solid $pcg-the-lightest-gray;
        .pcg-toolbar-items  {
            margin-left: auto;
        }

        .pcg-icon-dot  {
            height: 12px;
            width: 12px;
            background-color: $main-active-color; 
            border-radius: 50%;
            display: inline-block;
            margin-right: 20px;
        }
  
        
        .logged-user-info{    
            display: inline-block; 
            vertical-align: middle; 
            line-height: normal;
            font-family: 'Roboto', sans-serif;
            font-weight: $bold;
            color: $main-color;
        }
    }

    .pcg-logo-container {
        z-index: 2;
        width: 260px;
        line-height: 104px;
        background-color: $pcg-white;
        border-bottom: solid 1px $pcg-lightest-gray;
        padding: 0 30px;
        display: none;        
        box-shadow: 6px 0 30px rgba(0, 0, 0, 0.1);
        @include medium-up {
            display: block;
        }
        &-img {
            max-width: 200px;
            max-height: 104px;
            background-image: $logo-header-url;
        }
    }

    .pcg-top-container {
        width: 100%;
        background-color: $pcg-the-lightest-gray;
        display: flex;
        min-height: 40px !important;
        height: auto !important;
        justify-content: space-between;
        .pcg-txt-right{
            display: flex;
            align-items: center;
        }
        .pcg-txt-right:only-child{
            margin-left:auto
        }
 
        &-img{
            background-image: $logo-header-url;
            width:  $logo-header-width;
            height:  $logo-header-height;
            background-repeat: no-repeat;
        }
    }

    .pcg-top-container,
    .pcg-navbar-container{
        padding: 10px 20px;
        @include medium-up {
            padding: 10px 60px;
        }
    }
}

.pcg-top-btn {
    font-size: $font-size-s;
    height: 20px;     
    line-height: 20px; 
    padding: 0 1rem;
    margin: 0px 0px 0px 10px;
    box-shadow: $header_buttons_shadow;
}


.pcg-login-container{
    &-img{
        background-image: $logo-login-header-url;
        width:  $logo-login-header-width;
        height:  $logo-login-header-height;
        background-repeat: no-repeat;
    }
}
.pcg-wcag-contrast-green-black {
    .pcg-header-container {
        border: 1px solid $color-wcag-contrast;
    }

    .pcg-logo-container{
        background-color: $color-wcag-background;
        border: none;
    }
    .pcg-top-container{
        background-color: $color-wcag-background;
    }

    .pcg-navbar-container{
        background-color: $color-wcag-background;
        border: none;
        
        .pcg-icon-dot{
            background-color: $color-wcag-contrast;
        }
        .logged-user-info{
            color: $color-wcag-static;
        }
    }
    .pcg-profile-group {
        & .pcg-profile-btn {
            background-color: $color-wcag-contrast;
            color: $color-wcag-background;
        }
        & .pcg-profile-container {
            background-color: $color-wcag-background;
            border: .1rem solid $color-wcag-contrast;
            box-shadow: $color-wcag-shadow;
 
            & a {
                color: $color-wcag-contrast;
                background-color: $color-wcag-background;

                .pcg-profile-option{
                    background-color: transparent;
                }
                &:hover {
                    color: $color-wcag-background;
                    background-color: $color-wcag-contrast;
                    & .pcg-font-icon, .pcg-profile-option-txt {
                        color: $color-wcag-background;
                    }
                }
                & .pcg-font-icon, .pcg-profile-option-txt{
                    color: $color-wcag-contrast;
                }
            }
            &:hover {
                background-color: $color-wcag-contrast;
            }
        }
    }
}


 