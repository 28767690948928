.pcg-panel {
   font-family: Roboto;
   width: 100%;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
   margin-bottom: 20px;

   &-collapsed {
      .pcg-panel-content {
         display: none;
      }

      .pcg-action-collapse {
         .action-hide {
            display: none;
         }

         .action-show {
            display: block;
         }
      }
   }

   &-expanded {
      display: block;

      .pcg-action-collapse {
         .action-hide {
            display: block;
         }

         .action-show {
            display: none;
         }
      }
   }

   &-content {
      padding: 0px 30px 50px 30px;

      .pcg-action-column {
         display: flex;
         align-items: center;
         align-content: center;
         height: 100%;
         flex-wrap: wrap;

         a {
            margin-right: 10px;
            font-size: $font-size-base;

            &:hover,
            &:active,
            &:focus {
               text-decoration: none;
            }
         }
      }
   }

   &-header {
      cursor: pointer;
      padding: 20px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-title {
         color: $main-color;
         font-weight: $bold;
         font-size: $font-size-xl;
         line-height: 42px;
      }

      &-actions {
         margin-left: auto;
         color: $main-active-color;
         font-size: $font-size-s;
         display: flex;
         font-weight: $bold;


         .pcg-action-item {
            display: flex;
            align-items: center;
            min-width: 40px;
            padding-left: 30px;

            .pcg-action-collapse{
               cursor: pointer;
            }

            .pcg-action-priority {
               display: flex;
               flex-direction: column;

               &-button {
                  text-decoration: none;
                  font-size: $font-size-s;

                  &:active,
                  &:focus,
                  &:hover {
                     text-decoration: none;
                  }
               }

               .pcg-icon {
                  background-color: $main-active-color;
                  color: $pcg-white;
                  width: 18px;
                  height: 18px;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  align-content: center;
                  margin-left: 10px;

                  &:hover {
                     background-color: $main-select-color;
                  }

                  &-priority-up {
                     border-radius: 100px 100px 0 0;
                     margin-bottom: 1px;
                  }

                  &-priority-down {
                     border-radius: 0 0 100px 100px;
                     margin-top: 1px;
                  }
               }

               .aspNetDisabled  .pcg-icon {
                  background-color: $disabled-color;
                  &:hover {
                     background-color: $disabled-color;
                  }

               }
            }
         }

         span {
            font-size: 1em;
         }
      }
   }
}

.pcg-wcag-contrast-green-black {
   .pcg-panel {
      border: 1px solid $color-wcag-static;
      &-header {
         &-title {
            color: $color-wcag-static;
         }
   
         &-actions {
            color: $color-wcag-contrast;

            .pcg-action-item {
               .pcg-action-priority {
                  &-button {
                     text-decoration: none;
                     font-size: $font-size-s;
   
                     &:active,
                     &:focus,
                     &:hover {
                        text-decoration: none;
                     }
                  }
   
                  .pcg-icon {
                     background-color: $color-wcag-contrast;
                     color: $color-wcag-background;

                     &:hover {
                        background-color: $color-wcag-contrast;
                     }
                  }
   
                  .aspNetDisabled  .pcg-icon {
                     background-color: $color-wcag-background;
                     border: 1px solid $color-wcag-static;
                     color: $color-wcag-static  !important;;
                     &:hover {
                        background-color: $color-wcag-background;
                        color: $color-wcag-static  !important;;
                     }
   
                  }
               }
            }
         }
      }

      &-content {
         .pcg-input-label,.pcg-input-label-value{
            color: $color-wcag-static;
         }
      }
   }
}