.pcg-message-group {    
    cursor: pointer;
    display: inline-block;
    .pcg-message-container {
        display: none;
        position: absolute;
        z-index: 4;
        width: 27.07rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background-color: $pcg-white;
        &.pcg-message-open {
            display: block;
        }
        .pcg-message-header {
            color: $main-color;
            font-size: $font-size-m;
            font-weight: $bold;
            line-height: 1.36rem;
            padding-top: 1.5rem;
            padding-bottom: 1.43rem;
            text-align: center;
        }
        .pcg-message-body {
            line-height: 1.07rem;
            .pcg-message {
                padding: 0.68rem 2.14rem;
                font-size: $font-size-s;
                background-color: $pcg-the-lightest-gray;
                border-bottom: 1px solid $pcg-white;
                cursor: pointer;
                .pcg-message-icon {        
                    font-size: 2.93rem;
                    padding: 0;
                }
                .pcg-message-text {
                    padding-top: 0.36rem;
                    padding-bottom: 0.36rem;
                }
                .pcg-message-from,
                .pcg-message-content,
                .pcg-message-time {
                    overflow: hidden;
                    white-space: nowrap; 
                    text-overflow: ellipsis; 
                    padding-right: 0;
                }
                .pcg-message-from {
                    color: $pcg-the-darkest-gray;
                    font-weight: $bold;
                    border-right: 1.5px solid $pcg-the-darkest-gray;
                    margin-right: 2px;
                }
                .pcg-message-content {
                    color: $pcg-the-darkest-gray;
                    font-weight: $regular;
                }
                .pcg-message-time {
                    color: $pcg-dark-gray;
                    font-weight: $regular;
                    font-size: $font-size-xs;
                    line-height: 0.79rem;
                }
                &.pcg-message-unreaded {
                    background-color: $additional-color;
                    .pcg-message-from,
                    .pcg-message-content,
                    .pcg-message-time {
                        color: $main-active-color;
                    }
                    .pcg-message-from {
                        border-right-color: $main-active-color;
                    }
                }
            }
        }
        .pcg-message-footer {
            color: $main-active-color;
            font-size: 1rem;
            font-weight: $medium;
            line-height: 1.07rem;
            padding-top: 1.61rem;
            padding-bottom: 1.64rem;
            text-align: center;
        }
    }
}
