.pcg-basic-alert {  
    .alert {
        border-radius: 5px;
        border: none;        
        margin: 0.5rem 0;
        button {
            @extend .pcg-button-without-outline;
        }
         
        .pcg-alert-section{
            .card {
                background-color: unset;
                background-clip: unset;
                border: none;
                border-radius: unset;
                .card-header {
                    padding: 0;
                    margin: 0;
                    background-color: unset;
                    border: none;
                    .btn.focus, .btn:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                .card-body {
                    padding: 0;
                    padding-left: 1.25rem;
                }
                .pcg-alert-message,
                .pcg-alert-error-code,
                .pcg-alert-additional-info {
                    font-size: $font-size-xs;
                    color: $pcg-dark-gray;
                    font-weight: $bold;
                    span {
                        font-weight: $regular;
                        display: block;
                        margin-bottom: 5px
                    }
                }
            }
        } 
        .pcg-alert-header {
            font-size: $font-size-s;
            font-weight: $bold;
            padding: 0;
        }
    }
    
    .alert-danger {
        background-color: $alert-error-light;        
        .pcg-alert-header {
            color: $alert-error;
        }
    }
    .alert-success {
        background-color: $alert-success-light;
        .pcg-alert-header {
            color: $alert-success;
        }
    }
    .alert-info {
        background-color: $alert-info-light;
        .pcg-alert-header {
            color: $alert-info;
        }
    }
    .alert-warning {
        background-color:  $alert-warning-light;
        .pcg-alert-header {
            color: $alert-warning;
        } 
    } 
 
}
.toast-container {
    .ngx-toastr {
        border-radius: 5px;
        box-shadow: none;
        padding: 1rem;
        width: 250px;
        background: none;
        .toast-title {
            font-size: $font-size-s;
            font-weight: $bold;
        }
        .toast-message {
            font-size: $font-size-xs;
        }
        &.toast-info {
            background-image: none;
            background-color: $highlighted-background-color;
            .toast-title {
                color: $pressed-color;
            }
            .toast-message {
                color: $pcg-dark-gray;
            }
        }
        &.toast-success {
            background-image: none;
            background-color: $alert-success-light;
            .toast-title {
                color: $alert-success;
            }
            .toast-message {
                color: $pcg-dark-gray;
            }
        }
        &:hover {
            box-shadow: none;
        }
    }
}

.pcg-wcag-contrast-green-black{
    .pcg-basic-alert {     
        .alert-danger {
            background-color: $color-wcag-background;    
            border: 1px solid $alert-error;
        }
        
        .alert-success {
            background-color:  $color-wcag-background;
            border: 1px solid  $alert-success;
        }

        .alert-info {
            background-color: $color-wcag-background;
            border: 1px solid $color-wcag-static;

            .pcg-alert-header {
                color: $color-wcag-static;
            } 
        }

        .alert-warning {
            border: 1px solid $alert-warning;
            background-color: $color-wcag-background;
        }
    }
}
 