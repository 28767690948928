@mixin extra-small {
    @media (max-width: $screen-xs-max) {
        @content
    }
};

@mixin small {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content
    }
};

@mixin small-up {
    @media screen and (min-width: $screen-sm-min) {
        @content
    }
};

@mixin medium {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @content
    }
};

@mixin medium-up {
    @media screen and (min-width: $screen-md-min) {
        @content
    }
};

@mixin large  {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        @content
    }
};

@mixin large-up  {
    @media screen and (min-width: $screen-lg-min) {
        @content
    }
};

@mixin extra-large  {
    @media screen and (min-width: $screen-xlg-min) {
        @content
    }
};