@charset "UTF-8";
.pcg-stack {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: auto; }

.pcg-icon-notify:before {
  color: #00B4EB;
  background-color: #FFFFFF;
  border-radius: 100%;
  font-size: 0.7em;
  position: absolute; }

.pcg-icon-envelope-notify:before {
  right: 0.7em;
  top: -0.2em; }

.pcg-icon-bell-notify:before {
  right: 1em;
  top: -0.1em; }

.pcg-icon-disabled {
  color: #BDBEBD !important; }

.pcg-icon-circle-action {
  border-radius: 100%;
  background-color: #ECF9FD;
  color: #00B4EB; }
  .pcg-icon-circle-action:hover {
    text-decoration: none; }

.pcg-icon-circle-notify-unreaded {
  border-radius: 100%;
  background-color: #00B4EB;
  color: #FFFFFF; }
  .pcg-icon-circle-notify-unreaded:hover {
    text-decoration: none; }

.pcg-icon-circle-notify-readed {
  border-radius: 100%;
  background-color: #BDBEBD;
  color: #FFFFFF; }
  .pcg-icon-circle-notify-readed:hover {
    text-decoration: none; }

.pcg-icon-circle {
  background-color: #ECF9FD;
  border-radius: 100%;
  color: #00B4EB; }
  .pcg-icon-circle:active, .pcg-icon-circle:focus, .pcg-icon-circle:hover {
    color: #4C97B7;
    text-decoration: none; }

.pcg-icon-circle-arrow {
  border-radius: 100%;
  background-color: #00B4EB;
  color: #FFFFFF; }

.pcg-icon-circle-action {
  border-radius: 100%;
  background-color: #ECF9FD;
  color: #00B4EB; }

.pcg-icon-circle-notify-unreaded {
  border-radius: 100%;
  background-color: #00B4EB;
  color: #FFFFFF; }

.pcg-icon-circle-notify-readed {
  border-radius: 100%;
  background-color: #BDBEBD;
  color: #FFFFFF; }

.pcg-icon-proces-negative-decision {
  color: #FC4747; }

.pcg-icon-proces-positive-decision {
  color: #55C64C; }

.pcg-wcag-contrast-green-black .pcg-icon-notify:before {
  color: #66FFA7;
  background-color: #000000; }

.pcg-wcag-contrast-green-black .pcg-icon-circle {
  background-color: #66FFA7;
  color: #000000; }
  .pcg-wcag-contrast-green-black .pcg-icon-circle:active, .pcg-wcag-contrast-green-black .pcg-icon-circle:focus, .pcg-wcag-contrast-green-black .pcg-icon-circle:hover {
    color: #000000; }

.pcg-wcag-contrast-green-black .pcg-icon-arrow-circle {
  background-color: #66FFA7;
  color: #000000; }
  .pcg-wcag-contrast-green-black .pcg-icon-arrow-circle:active, .pcg-wcag-contrast-green-black .pcg-icon-arrow-circle:focus, .pcg-wcag-contrast-green-black .pcg-icon-arrow-circle:hover {
    color: #000000; }

.pcg-wcag-contrast-green-black .pcg-icon-circle-arrow {
  background-color: #66FFA7;
  color: #000000; }

.pcg-wcag-contrast-green-black .pcg-icon-circle-action {
  background-color: #ECF9FD;
  color: #00B4EB; }

.pcg-wcag-contrast-green-black .pcg-icon-circle-notify-unreaded {
  background-color: #66FFA7;
  color: #000000;
  border: 1px solid #000000; }

.pcg-wcag-contrast-green-black .pcg-icon-circle-notify-readed {
  background-color: #000000;
  color: #66FFA7;
  border: 1px solid #66FFA7; }

.pcg-xs {
  font-size: .45em; }

.pcg-sm {
  font-size: .75em; }

.pcg-md {
  font-size: .875em; }

.pcg-lg {
  font-size: 1.33333em; }

.pcg-2x {
  font-size: 2em; }

.pcg-3x {
  font-size: 3em; }

.pcg-4x {
  font-size: 4em; }

.pcg-5x {
  font-size: 5em; }

.pcg-6x {
  font-size: 6em; }

.pcg-7x {
  font-size: 7em; }

@font-face {
  font-family: "PCGFont";
  src: url("./settings/fonts/PCGFont.eot?tn0orq");
  src: url("./settings/fonts/PCGFont.eot?tn0orq#iefix") format("embedded-opentype"), url("./settings/fonts/PCGFont.ttf?tn0orq") format("truetype"), url("./settings/fonts/PCGFont.woff?tn0orq") format("woff"), url("./settings/fonts/PCGFont.svg?tn0orq#PCGFont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="pcg-icon-"], [class*=" pcg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PCGFont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.pcg-icon-circle-person-view:before {
  content: ""; }

.pcg-icon-circle-phone:before {
  content: ""; }

.pcg-icon-circle-picture:before {
  content: ""; }

.pcg-icon-circle-printer:before {
  content: ""; }

.pcg-icon-circle-radio:before {
  content: ""; }

.pcg-icon-circle-ranking:before {
  content: ""; }

.pcg-icon-circle-reset-settings:before {
  content: ""; }

.pcg-icon-circle-rotate-left:before {
  content: ""; }

.pcg-icon-circle-rotate-right:before {
  content: ""; }

.pcg-icon-circle-save:before {
  content: ""; }

.pcg-icon-circle-share:before {
  content: ""; }

.pcg-icon-circle-size-decrease:before {
  content: ""; }

.pcg-icon-circle-size-increase:before {
  content: ""; }

.pcg-icon-circle-trash:before {
  content: ""; }

.pcg-icon-circle-upload:before {
  content: ""; }

.pcg-icon-clone:before {
  content: ""; }

.pcg-icon-close:before {
  content: ""; }

.pcg-icon-comment:before {
  content: ""; }

.pcg-icon-contact:before {
  content: ""; }

.pcg-icon-contact-book:before {
  content: ""; }

.pcg-icon-cookie:before {
  content: ""; }

.pcg-icon-copy:before {
  content: ""; }

.pcg-icon-cross:before {
  content: ""; }

.pcg-icon-delete-user:before {
  content: ""; }

.pcg-icon-document:before {
  content: ""; }

.pcg-icon-dormitory:before {
  content: ""; }

.pcg-icon-employees:before {
  content: ""; }

.pcg-icon-enrollment:before {
  content: ""; }

.pcg-icon-envelope:before {
  content: ""; }

.pcg-icon-expand-arrows:before {
  content: ""; }

.pcg-icon-facebook:before {
  content: ""; }

.pcg-icon-file-about:before {
  content: ""; }

.pcg-icon-file-check:before {
  content: ""; }

.pcg-icon-file-marks:before {
  content: ""; }

.pcg-icon-filter:before {
  content: ""; }

.pcg-icon-first-cycle-studies:before {
  content: ""; }

.pcg-icon-first-cycle-studies2:before {
  content: ""; }

.pcg-icon-foreigners:before {
  content: ""; }

.pcg-icon-graduation-cap:before {
  content: ""; }

.pcg-icon-hand-holding-money:before {
  content: ""; }

.pcg-icon-help:before {
  content: ""; }

.pcg-icon-home:before {
  content: ""; }

.pcg-icon-info:before {
  content: ""; }

.pcg-icon-instagram:before {
  content: ""; }

.pcg-icon-insurance:before {
  content: ""; }

.pcg-icon-linkedin:before {
  content: ""; }

.pcg-icon-log-history:before {
  content: ""; }

.pcg-icon-logout:before {
  content: ""; }

.pcg-icon-maps:before {
  content: ""; }

.pcg-icon-marks:before {
  content: ""; }

.pcg-icon-master-degree:before {
  content: ""; }

.pcg-icon-master-degree2:before {
  content: ""; }

.pcg-icon-money:before {
  content: ""; }

.pcg-icon-news:before {
  content: ""; }

.pcg-icon-notify:before {
  content: ""; }

.pcg-icon-paperclip:before {
  content: ""; }

.pcg-icon-pdf:before {
  content: ""; }

.pcg-icon-person:before {
  content: ""; }

.pcg-icon-persons:before {
  content: ""; }

.pcg-icon-person-view:before {
  content: ""; }

.pcg-icon-picture:before {
  content: ""; }

.pcg-icon-plus:before {
  content: ""; }

.pcg-icon-postgraduate-studies:before {
  content: ""; }

.pcg-icon-postgraduate-studies2:before {
  content: ""; }

.pcg-icon-printer:before {
  content: ""; }

.pcg-icon-printer-small:before {
  content: ""; }

.pcg-icon-proces-done:before {
  content: ""; }

.pcg-icon-proces-error:before {
  content: ""; }

.pcg-icon-proces-in-progress:before {
  content: ""; }

.pcg-icon-proces-negative-decision:before {
  content: ""; }

.pcg-icon-proces-positive-decision:before {
  content: ""; }

.pcg-icon-radio:before {
  content: ""; }

.pcg-icon-save:before {
  content: ""; }

.pcg-icon-scholarship:before {
  content: ""; }

.pcg-icon-search:before {
  content: ""; }

.pcg-icon-second-cycle-studies:before {
  content: ""; }

.pcg-icon-second-cycle-studies2:before {
  content: ""; }

.pcg-icon-send:before {
  content: ""; }

.pcg-icon-settings:before {
  content: ""; }

.pcg-icon-star:before {
  content: ""; }

.pcg-icon-statistics:before {
  content: ""; }

.pcg-icon-status-closed:before {
  content: ""; }

.pcg-icon-status-deleted:before {
  content: ""; }

.pcg-icon-status-obligatory:before {
  content: ""; }

.pcg-icon-status-selected:before {
  content: ""; }

.pcg-icon-status-to-select:before {
  content: ""; }

.pcg-icon-system-support:before {
  content: ""; }

.pcg-icon-third-cycle-studies:before {
  content: ""; }

.pcg-icon-third-cycle-studies2:before {
  content: ""; }

.pcg-icon-time:before {
  content: ""; }

.pcg-icon-trash:before {
  content: ""; }

.pcg-icon-twitter:before {
  content: ""; }

.pcg-icon-university:before {
  content: ""; }

.pcg-icon-university-guide:before {
  content: ""; }

.pcg-icon-youtube:before {
  content: ""; }

.pcg-icon-add:before {
  content: ""; }

.pcg-icon-add-comment:before {
  content: ""; }

.pcg-icon-add-message:before {
  content: ""; }

.pcg-icon-adjust:before {
  content: ""; }

.pcg-icon-arrow-down:before, .pcg-dropdown .pcg-icon-dropdown-arrow:before, .pcg-table-section .pcg-section-header .pcg-icon-section-header:before, .pcg-profile-group .pcg-profile-btn .pcg-icon-profile-arrow:before, .pcg-profile-group .pcg-profile-btn-img .pcg-icon-profile-arrow:before, .pcg-sidebar-container .pcg-sidebar-section-header .pcg-icon-sidebar-section:before {
  content: ""; }

.pcg-icon-arrow-left:before, .pcg-btn-sidebar-menu .pcg-icon-menu-arrow:before {
  content: ""; }

.pcg-icon-arrow-long-right:before {
  content: ""; }

.pcg-icon-arrow-right:before, .pcg-btn-sidebar-menu.hidden .pcg-icon-menu-arrow:before {
  content: ""; }

.pcg-icon-arrows:before {
  content: ""; }

.pcg-icon-arrow-slim-left:before {
  content: ""; }

.pcg-icon-arrow-up:before, .pcg-dropdown.show .pcg-icon-dropdown-arrow:before, .pcg-table-section.pcg-table-section-active .pcg-section-header .pcg-icon-section-header:before, .pcg-profile-group .pcg-profile-btn.show .pcg-icon-profile-arrow:before, .pcg-profile-group .show.pcg-profile-btn-img .pcg-icon-profile-arrow:before, .pcg-profile-group.show .pcg-profile-btn .pcg-icon-profile-arrow:before, .pcg-profile-group.show .pcg-profile-btn-img .pcg-icon-profile-arrow:before, .pcg-sidebar-container .pcg-sidebar-section-header.active .pcg-icon-sidebar-section:before, .pcg-sidebar-container .panel-group .panel-open .pcg-sidebar-section-header .pcg-icon-sidebar-section:before {
  content: ""; }

.pcg-icon-attachment:before {
  content: ""; }

.pcg-icon-automatic:before {
  content: ""; }

.pcg-icon-bell:before {
  content: ""; }

.pcg-icon-burger:before {
  content: ""; }

.pcg-icon-calendar:before {
  content: ""; }

.pcg-icon-catalog:before {
  content: ""; }

.pcg-icon-certification:before {
  content: ""; }

.pcg-icon-check:before {
  content: ""; }

.pcg-icon-check-double:before {
  content: ""; }

.pcg-icon-check-list:before {
  content: ""; }

.pcg-icon-children-university:before {
  content: ""; }

.pcg-icon-children-university2:before {
  content: ""; }

.pcg-icon-circle-add-comment:before {
  content: ""; }

.pcg-icon-circle-add-message:before {
  content: ""; }

.pcg-icon-circle-add-user:before {
  content: ""; }

.pcg-icon-circle-attachment:before {
  content: ""; }

.pcg-icon-circle-brightness-decrease:before {
  content: ""; }

.pcg-icon-circle-brightness-increase:before {
  content: ""; }

.pcg-icon-circle-calendar:before {
  content: ""; }

.pcg-icon-circle-check:before {
  content: ""; }

.pcg-icon-circle-check-list:before {
  content: ""; }

.pcg-icon-circle-clone:before {
  content: ""; }

.pcg-icon-circle-concrast-decrease:before {
  content: ""; }

.pcg-icon-circle-concrast-increase:before {
  content: ""; }

.pcg-icon-circle-contact-book:before {
  content: ""; }

.pcg-icon-circle-copy:before {
  content: ""; }

.pcg-icon-circle-create:before {
  content: ""; }

.pcg-icon-circle-cross:before {
  content: ""; }

.pcg-icon-circle-details:before {
  content: ""; }

.pcg-icon-circle-document:before {
  content: ""; }

.pcg-icon-circle-download:before {
  content: ""; }

.pcg-icon-circle-ellipsis:before {
  content: ""; }

.pcg-icon-circle-employees:before {
  content: ""; }

.pcg-icon-circle-enrollment:before {
  content: ""; }

.pcg-icon-circle-envelope:before {
  content: ""; }

.pcg-icon-circle-expand-arrows:before {
  content: ""; }

.pcg-icon-circle-file-about:before {
  content: ""; }

.pcg-icon-circle-file-check:before {
  content: ""; }

.pcg-icon-circle-file-marks:before {
  content: ""; }

.pcg-icon-circle-hand-holding-money:before {
  content: ""; }

.pcg-icon-circle-location-arrow:before {
  content: ""; }

.pcg-icon-circle-marks:before {
  content: ""; }

.pcg-icon-circle-message:before {
  content: ""; }

.pcg-icon-circle-money:before {
  content: ""; }

.pcg-icon-circle-pencil:before {
  content: ""; }

.pcg-icon-circle-persons:before {
  content: ""; }

body, html {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: #F7F8FA; }

.pcg-wcag-contrast-green-black {
  background-color: #000000; }
  .pcg-wcag-contrast-green-black body, .pcg-wcag-contrast-green-black html {
    background-color: #000000; }

.text-size-1 body {
  font-size: 16px; }

.text-size-2 body {
  font-size: 18px; }

.pcg-title {
  font-size: 1.71em;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.04rem;
  color: #253B80;
  margin: 40px 0;
  text-transform: uppercase;
  display: block; }

.pcg-login-title {
  font-family: 'Roboto';
  font-size: 2.71em;
  font-weight: 500;
  letter-spacing: 1.52px;
  color: #253B80; }

.pcg-subtitle {
  font-size: 2em;
  color: #253B80;
  font-weight: 500;
  line-height: 1.36em; }

h2 {
  font-size: 1.29em;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.02rem; }

h3 {
  font-size: 1.14em;
  font-weight: 500;
  line-height: 1.36rem;
  letter-spacing: 0.02rem; }

.pcg-date {
  font-size: 1.14em;
  font-weight: 400;
  line-height: 1.36rem;
  letter-spacing: 0.04rem; }

.pcg-name {
  font-weight: 500;
  line-height: 1.14rem;
  letter-spacing: 0.04rem; }

h4 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.14rem;
  letter-spacing: 0.02rem; }

.pcg-text-medium {
  font-weight: 400; }

.pcg-menu-txt {
  font-weight: 400;
  line-height: 1.14rem;
  letter-spacing: 0.04rem; }

.pcg-txt-small-bold {
  font-size: 0.86em;
  font-weight: 500;
  line-height: 1rem; }

.pcg-txt-small {
  font-size: 0.86em;
  font-weight: 300;
  line-height: 1rem; }

.pcg-txt-xs {
  font-size: 0.71em;
  font-weight: 300;
  line-height: 1rem; }

.pcg-txt-right {
  float: right; }

.pcg-text {
  font-family: 'Roboto', sans-serif; }
  .pcg-text-bold {
    font-weight: 500; }
  .pcg-text-font-size-s {
    font-size: 0.86em; }
  .pcg-text-font-size-m {
    font-size: 1.14em; }
  .pcg-text-font-size-l {
    font-size: 1.29em; }
  .pcg-text-color-gray {
    color: #9D9D9D; }
  .pcg-text-color-dark-gray {
    color: #808080; }
  .pcg-text-color-the-darkest-gray {
    color: #585858; }
  .pcg-text-color-light-gray {
    color: #585858; }

.pcg-wcag-contrast-green-black .pcg-title {
  color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-login-title {
  color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-subtitle {
  color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-text-color-gray {
  color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-text-color-dark-gray {
  color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-text-color-the-darkest-gray {
  color: #FFFFFF; }

.pcg-button-without-outline:focus:focus, .pcg-button:focus:focus, .pcg-datetimepicker-btn:focus:focus, .pcg-menu-btn:focus:focus, .pcg-slide-tabs .pcg-slide-tab-btn:focus:focus, .bootstrap-select .dropdown-toggle:focus:focus, .pcg-select .selected-list .c-btn:focus:focus, .pcg-profile-group .pcg-profile-btn:focus:focus, .pcg-profile-group .pcg-profile-btn-img:focus:focus, .pcg-basic-alert .alert button:focus:focus, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid button:focus:focus, .pcg-basic-alert .ng-valid ~ .pcg-field-valid button:focus:focus, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid button:focus:focus, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid button:focus:focus, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid button:focus:focus, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid button:focus:focus,
.pcg-button-without-outline:focus .focus,
.pcg-button:focus .focus,
.pcg-datetimepicker-btn:focus .focus,
.pcg-menu-btn:focus .focus,
.pcg-slide-tabs .pcg-slide-tab-btn:focus .focus,
.bootstrap-select .dropdown-toggle:focus .focus,
.pcg-select .selected-list .c-btn:focus .focus,
.pcg-profile-group .pcg-profile-btn:focus .focus,
.pcg-profile-group .pcg-profile-btn-img:focus .focus,
.pcg-basic-alert .alert button:focus .focus,
.pcg-basic-alert .ng-invalid ~ .pcg-field-invalid button:focus .focus,
.pcg-basic-alert .ng-valid ~ .pcg-field-valid button:focus .focus,
.pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid button:focus .focus,
.pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid button:focus .focus,
.pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid button:focus .focus,
.pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid button:focus .focus,
.pcg-button-without-outline .focus:focus,
.pcg-button .focus:focus,
.pcg-datetimepicker-btn .focus:focus,
.pcg-menu-btn .focus:focus,
.pcg-slide-tabs .pcg-slide-tab-btn .focus:focus,
.bootstrap-select .dropdown-toggle .focus:focus,
.pcg-select .selected-list .c-btn .focus:focus,
.pcg-profile-group .pcg-profile-btn .focus:focus,
.pcg-profile-group .pcg-profile-btn-img .focus:focus,
.pcg-basic-alert .alert button .focus:focus,
.pcg-basic-alert .ng-invalid ~ .pcg-field-invalid button .focus:focus,
.pcg-basic-alert .ng-valid ~ .pcg-field-valid button .focus:focus,
.pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid button .focus:focus,
.pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid button .focus:focus,
.pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid button .focus:focus,
.pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid button .focus:focus,
.pcg-button-without-outline .focus .focus,
.pcg-button .focus .focus,
.pcg-datetimepicker-btn .focus .focus,
.pcg-menu-btn .focus .focus,
.pcg-slide-tabs .pcg-slide-tab-btn .focus .focus,
.bootstrap-select .dropdown-toggle .focus .focus,
.pcg-select .selected-list .c-btn .focus .focus,
.pcg-profile-group .pcg-profile-btn .focus .focus,
.pcg-profile-group .pcg-profile-btn-img .focus .focus,
.pcg-basic-alert .alert button .focus .focus,
.pcg-basic-alert .ng-invalid ~ .pcg-field-invalid button .focus .focus,
.pcg-basic-alert .ng-valid ~ .pcg-field-valid button .focus .focus,
.pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid button .focus .focus,
.pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid button .focus .focus,
.pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid button .focus .focus,
.pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid button .focus .focus {
  outline: none; }

.pcg-button, .pcg-datetimepicker-btn {
  border-radius: 100px;
  font-weight: 500;
  cursor: pointer;
  min-width: max-content;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: max-content; }
  .pcg-button-login {
    min-height: 40px;
    border: none;
    width: 100%;
    position: relative;
    font-weight: 500;
    border-radius: 100px;
    letter-spacing: 0.28px; }
    .pcg-button-login-emphasize {
      background: #253B80;
      box-shadow: 0px 3px 10px rgba(37, 59, 128, 0.2);
      max-width: 220px;
      width: 100%;
      line-height: 18px;
      color: #FFFFFF; }
      .pcg-button-login-emphasize:hover {
        background-color: #304da8;
        box-shadow: 0px 10px 20px rgba(48, 77, 168, 0.4); }
    .pcg-button-login-external {
      background: #FFFFFF;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      color: #808080;
      margin-bottom: 20px; }
      .pcg-button-login-external:hover {
        box-shadow: 0px 10px 20px rgba(0, 180, 235, 0.4); }
    .pcg-button-login .provider-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px;
      background-repeat: no-repeat;
      background-position: center; }
    .pcg-button-login.btn-google {
      color: #808080; }
      .pcg-button-login.btn-google .provider-icon {
        background-image: url("../../images/src/google.svg"); }
    .pcg-button-login.btn-fb {
      color: #3B5998; }
      .pcg-button-login.btn-fb .provider-icon {
        background-image: url("../../images/src/facebook.svg"); }

.pcg-btn-return {
  margin-bottom: 2.86rem;
  display: inline-flex;
  align-self: start; }
  .pcg-btn-return .pcg-button, .pcg-btn-return .pcg-datetimepicker-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 15px; }
  .pcg-btn-return .pcg-nav-btn {
    height: 30px;
    width: auto; }
  .pcg-btn-return .pcg-return-title {
    margin-left: 2.14rem;
    font-size: 1.29em;
    color: #253B80;
    font-weight: 500;
    line-height: 2rem; }
  .pcg-btn-return .pcg-icon-arrow-left, .pcg-btn-return .pcg-btn-sidebar-menu .pcg-icon-menu-arrow, .pcg-btn-sidebar-menu .pcg-btn-return .pcg-icon-menu-arrow {
    line-height: 1.21rem; }

.pcg-main-btn, .pcg-datetimepicker-btn {
  background-color: #00B4EB;
  color: #FFFFFF;
  border: none;
  padding: 0 2.14rem;
  box-shadow: 0px 10px 20px rgba(0, 180, 235, 0.4); }
  .pcg-main-btn span, .pcg-datetimepicker-btn span {
    color: #FFFFFF; }
  .pcg-main-btn:hover, .pcg-datetimepicker-btn:hover {
    background-color: #4C97B7;
    box-shadow: 0px 10px 20px rgba(76, 151, 183, 0.4); }
  .pcg-main-btn:active, .pcg-datetimepicker-btn:active, .pcg-main-btn:focus, .pcg-datetimepicker-btn:focus {
    background-color: #167AA5;
    box-shadow: 0px 10px 20px rgba(22, 122, 165, 0.4); }
  .pcg-main-btn:disabled, .pcg-datetimepicker-btn:disabled {
    cursor: not-allowed;
    background-color: #ABE3F5;
    box-shadow: 0px 10px 20px rgba(171, 227, 245, 0.4); }
  .pcg-main-btn.pcg-danger-btn, .pcg-danger-btn.pcg-datetimepicker-btn {
    background-color: #FC4747;
    box-shadow: 0px 10px 20px rgba(252, 71, 71, 0.4); }
    .pcg-main-btn.pcg-danger-btn:hover, .pcg-danger-btn.pcg-datetimepicker-btn:hover, .pcg-main-btn.pcg-danger-btn:active, .pcg-danger-btn.pcg-datetimepicker-btn:active, .pcg-main-btn.pcg-danger-btn:focus, .pcg-danger-btn.pcg-datetimepicker-btn:focus {
      background-color: #fb1515;
      box-shadow: 0px 10px 20px rgba(252, 71, 71, 0.4); }
    .pcg-main-btn.pcg-danger-btn:disabled, .pcg-danger-btn.pcg-datetimepicker-btn:disabled, .pcg-main-btn.pcg-danger-btn.disabled, .pcg-danger-btn.disabled.pcg-datetimepicker-btn {
      cursor: not-allowed;
      background-color: #feb5b5;
      box-shadow: 0px 10px 20px rgba(252, 71, 71, 0.25); }

.pcg-normal-btn {
  height: 2.86rem; }

.pcg-small-btn, .pcg-datetimepicker-btn {
  font-size: 0.86em;
  height: 2.14rem; }

.pcg-additional-btn {
  background-color: transparent;
  color: #00B4EB;
  border: solid 2px #00B4EB;
  padding: 0 2rem; }
  .pcg-additional-btn:hover {
    color: #4C97B7;
    border-color: #4C97B7; }
  .pcg-additional-btn:active, .pcg-additional-btn:focus {
    color: #167AA5;
    border-color: #167AA5; }
  .pcg-additional-btn:disabled {
    cursor: not-allowed;
    color: #ABE3F5;
    border-color: #ABE3F5; }
  .pcg-additional-btn.pcg-danger-btn {
    color: #FC4747;
    border-color: #FC4747; }
    .pcg-additional-btn.pcg-danger-btn:hover, .pcg-additional-btn.pcg-danger-btn:active, .pcg-additional-btn.pcg-danger-btn:focus {
      color: #fb1515;
      border-color: #fb1515; }

.pcg-nav-btn {
  background-color: transparent;
  color: #00B4EB;
  border: 2px solid #00B4EB;
  height: 2.14rem;
  padding: 0;
  width: 3.57rem;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center; }
  .pcg-nav-btn span {
    color: #00B4EB; }
  .pcg-nav-btn:hover {
    color: #4C97B7;
    border-color: #4C97B7;
    text-decoration: none; }
    .pcg-nav-btn:hover span {
      color: #4C97B7; }
  .pcg-nav-btn:active, .pcg-nav-btn:focus {
    color: #167AA5;
    border-color: #167AA5;
    text-decoration: none; }
    .pcg-nav-btn:active span, .pcg-nav-btn:focus span {
      color: #167AA5; }
  .pcg-nav-btn:disabled {
    cursor: not-allowed;
    color: #ABE3F5;
    border-color: #ABE3F5;
    text-decoration: none; }
    .pcg-nav-btn:disabled span {
      color: #ABE3F5; }

.pcg-menu-btn {
  background: transparent;
  border: none; }
  .pcg-menu-btn:hover i {
    color: #4C97B7; }
  .pcg-menu-btn:active i {
    color: #167AA5; }
  .pcg-menu-btn i {
    cursor: pointer;
    color: #253B80; }
  @media screen and (min-width: 768px) {
    .pcg-menu-btn {
      display: none; } }

.pcg-dropdown .dropdown-toggle::after {
  display: none; }

.pcg-dropdown .pcg-icon-dropdown-arrow {
  font-size: 1rem;
  vertical-align: middle; }

.pcg-dropdown .pcg-dropdown-option-container {
  border: none;
  display: none;
  position: absolute;
  z-index: 4;
  padding: 0px;
  margin-top: 0.86rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #FFFFFF; }
  .pcg-dropdown .pcg-dropdown-option-container .pcg-dropdown-option {
    cursor: pointer;
    font-size: 0.86em;
    line-height: 3.57rem;
    padding: 0 1.43rem;
    color: #9D9D9D;
    font-weight: 400;
    text-decoration: none;
    display: block; }
    .pcg-dropdown .pcg-dropdown-option-container .pcg-dropdown-option:hover {
      border-radius: 5px;
      text-decoration: none;
      background-color: #EEF0F5;
      color: #00B4EB; }

.pcg-wcag-contrast-green-black .pcg-nav-btn,
.pcg-wcag-contrast-green-black .pcg-nav-btn:hover,
.pcg-wcag-contrast-green-black .pcg-nav-btn:focus {
  border-color: #66FFA7;
  color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-nav-btn span,
  .pcg-wcag-contrast-green-black .pcg-nav-btn:hover span,
  .pcg-wcag-contrast-green-black .pcg-nav-btn:focus span {
    color: #66FFA7; }

.pcg-wcag-contrast-green-black .pcg-button-login-emphasize {
  background-color: #66FFA7;
  box-shadow: none;
  color: #000000; }

.pcg-wcag-contrast-green-black .pcg-button-login-external {
  background-color: #66FFA7;
  box-shadow: none;
  color: #000000; }

.pcg-wcag-contrast-green-black .pcg-button-login:hover {
  color: #000000;
  background-color: #66FFA7;
  opacity: 80%; }

.pcg-wcag-contrast-green-black .pcg-button-login .provider-icon {
  background-image: none; }

.pcg-wcag-contrast-green-black .pcg-main-btn, .pcg-wcag-contrast-green-black .pcg-datetimepicker-btn {
  background-color: #66FFA7;
  color: #000000;
  box-shadow: none; }
  .pcg-wcag-contrast-green-black .pcg-main-btn span, .pcg-wcag-contrast-green-black .pcg-datetimepicker-btn span {
    color: #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-main-btn:hover, .pcg-wcag-contrast-green-black .pcg-datetimepicker-btn:hover, .pcg-wcag-contrast-green-black .pcg-main-btn:active, .pcg-wcag-contrast-green-black .pcg-datetimepicker-btn:active, .pcg-wcag-contrast-green-black .pcg-main-btn:focus, .pcg-wcag-contrast-green-black .pcg-datetimepicker-btn:focus {
    background-color: #66FFA7;
    color: #000000;
    opacity: 80%; }
  .pcg-wcag-contrast-green-black .pcg-main-btn:disabled, .pcg-wcag-contrast-green-black .pcg-datetimepicker-btn:disabled {
    background-color: #66FFA7;
    color: #000000;
    box-shadow: none;
    opacity: 40%; }

.pcg-wcag-contrast-green-black .pcg-additional-btn {
  color: #66FFA7;
  border: solid 2px #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-additional-btn:hover {
    color: #66FFA7;
    border-color: #66FFA7;
    opacity: 80%; }
  .pcg-wcag-contrast-green-black .pcg-additional-btn:active, .pcg-wcag-contrast-green-black .pcg-additional-btn:focus {
    color: #66FFA7;
    border-color: #66FFA7;
    opacity: 80%; }
  .pcg-wcag-contrast-green-black .pcg-additional-btn:disabled {
    cursor: not-allowed;
    color: #66FFA7;
    border-color: #66FFA7;
    opacity: 40%; }

.pcg-checkbox-container,
.pcg-radio-container {
  display: inline-block;
  position: relative;
  padding-left: 2.43rem;
  margin-right: 2.43rem;
  margin-bottom: 0.86rem;
  cursor: pointer;
  color: #808080;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .pcg-checkbox-container input,
  .pcg-radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .pcg-checkbox-container:hover,
  .pcg-radio-container:hover {
    color: #00B4EB; }
    .pcg-checkbox-container:hover .pcg-checkmark,
    .pcg-checkbox-container:hover .pcg-radio,
    .pcg-radio-container:hover .pcg-checkmark,
    .pcg-radio-container:hover .pcg-radio {
      border-color: #00B4EB; }

.pcg-checkbox-container {
  line-height: 1.14rem; }
  .pcg-checkbox-container .pcg-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 2px solid #BDBEBD; }
    .pcg-checkbox-container .pcg-checkmark:after {
      content: "";
      position: absolute;
      display: none; }
  .pcg-checkbox-container:hover .pcg-checkmark {
    border-color: #00B4EB; }
  .pcg-checkbox-container input:checked ~ .pcg-checkmark {
    background-color: #00B4EB;
    border-color: #00B4EB; }
    .pcg-checkbox-container input:checked ~ .pcg-checkmark:after {
      display: block;
      left: 0.25rem;
      top: 0.1rem;
      width: 0.29rem;
      height: 0.43rem;
      border: solid white;
      border-width: 0 0.11rem 0.11rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
  .pcg-checkbox-container input:indeterminate ~ .pcg-checkmark {
    background-color: #00B4EB;
    border-color: #00B4EB; }
    .pcg-checkbox-container input:indeterminate ~ .pcg-checkmark:after {
      content: "";
      display: block;
      left: 0.05rem;
      top: 0.03rem;
      font-color: #FFFFFF;
      width: 0.62rem;
      height: 0.43rem;
      border: solid white;
      border-width: 0 0 0.20rem 0; }

.pcg-radio-container {
  line-height: 1.29rem; }
  .pcg-radio-container input:checked ~ .pcg-radio {
    background-color: #FFFFFF;
    border-color: #00B4EB;
    border-width: 0.43rem;
    height: 1.29rem;
    width: 1.29rem; }
  .pcg-radio-container .pcg-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.29rem;
    width: 1.29rem;
    border: 2px solid #BDBEBD;
    background-color: #FFFFFF;
    border-radius: 50%; }

.pcg-wcag-contrast-green-black .pcg-checkbox-container,
.pcg-wcag-contrast-green-black .pcg-radio-container {
  color: #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-checkbox-container:hover,
  .pcg-wcag-contrast-green-black .pcg-radio-container:hover {
    color: #66FFA7; }
    .pcg-wcag-contrast-green-black .pcg-checkbox-container:hover .pcg-checkmark,
    .pcg-wcag-contrast-green-black .pcg-checkbox-container:hover .pcg-radio,
    .pcg-wcag-contrast-green-black .pcg-radio-container:hover .pcg-checkmark,
    .pcg-wcag-contrast-green-black .pcg-radio-container:hover .pcg-radio {
      border-color: #66FFA7; }

.pcg-wcag-contrast-green-black .pcg-checkbox-container:hover .pcg-checkmark {
  border-color: #66FFA7; }

.pcg-wcag-contrast-green-black .pcg-checkbox-container input:indeterminate ~ .pcg-checkmark {
  background-color: #66FFA7;
  border-color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-checkbox-container input:indeterminate ~ .pcg-checkmark:after {
    border: solid black;
    border-width: 0 0.11rem 0.11rem 0; }

.pcg-wcag-contrast-green-black .pcg-checkbox-container input:checked ~ .pcg-checkmark {
  background-color: #66FFA7;
  border-color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-checkbox-container input:checked ~ .pcg-checkmark:after {
    border: solid black;
    border-width: 0 0.11rem 0.11rem 0; }

.pcg-wcag-contrast-green-black .pcg-radio-container input:checked ~ .pcg-radio {
  background-color: #FFFFFF;
  border-color: #66FFA7; }

.custom-file {
  height: 150px;
  margin-bottom: 20px; }
  .custom-file .custom-file-input {
    height: 150px;
    cursor: pointer; }
  .custom-file .custom-file-label {
    height: 150px;
    border: none;
    font-size: 0.86em;
    font-weight: 500;
    color: #585858;
    background-color: #EEF0F5;
    text-align: center;
    line-height: 150px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); }
    .custom-file .custom-file-label .pcg-file-loader,
    .custom-file .custom-file-label .pcg-icon-span {
      text-align: center;
      display: table-row;
      width: 100%;
      height: 85px;
      line-height: 85px; }
    .custom-file .custom-file-label .pcg-icon-span {
      font-size: 46px; }
    .custom-file .custom-file-label .pcg-text-span {
      display: table-row;
      line-height: 25px;
      height: 65px;
      width: 100%; }
    .custom-file .custom-file-label .pcg-upload-file {
      color: #BDBEBD; }
    .custom-file .custom-file-label .pcg-success-upload {
      color: #55C64C; }
      .custom-file .custom-file-label .pcg-success-upload span {
        color: #BDBEBD; }
    .custom-file .custom-file-label i.pcg-error-upload {
      color: #FC4747; }
    .custom-file .custom-file-label .pcg-error-upload {
      color: #585858; }
      .custom-file .custom-file-label .pcg-error-upload span {
        color: #808080; }
    .custom-file .custom-file-label .pcg-loading-file {
      color: #00B4EB; }
      .custom-file .custom-file-label .pcg-loading-file span {
        color: #808080; }
    .custom-file .custom-file-label.pcg-success {
      background-color: #E2F4E1;
      box-shadow: 0 3px 10px rgba(85, 198, 76, 0.2); }
    .custom-file .custom-file-label.pcg-fail {
      background-color: #FED8D8;
      box-shadow: 0 3px 10px rgba(252, 71, 71, 0.2); }
    .custom-file .custom-file-label.pcg-loading {
      background-color: #ECF9FD;
      box-shadow: 0 3px 10px rgba(0, 180, 235, 0.2); }
    .custom-file .custom-file-label::after {
      display: none; }
  .custom-file:hover .custom-file-label {
    background-color: #ECF9FD;
    box-shadow: 0 3px 10px rgba(0, 180, 235, 0.2); }
    .custom-file:hover .custom-file-label .pcg-upload-file,
    .custom-file:hover .custom-file-label .pcg-success-upload,
    .custom-file:hover .custom-file-label i.pcg-error-upload {
      color: #00B4EB; }
  .custom-file:disabled .custom-file-label {
    background-color: #ABE3F5;
    box-shadow: 0 3px 10px rgba(171, 227, 245, 0.2); }
    .custom-file:disabled .custom-file-label .pcg-upload-file,
    .custom-file:disabled .custom-file-label .pcg-success-upload,
    .custom-file:disabled .custom-file-label i.pcg-error-upload {
      color: #FFFFFF; }

.pcg-input-label {
  font-size: 0.86em;
  color: #585858;
  font-weight: 500;
  line-height: 1rem;
  margin-bottom: 0.71rem;
  display: block; }
  .pcg-input-label-value {
    font-size: 0.86em;
    color: #9D9D9D;
    line-height: 1rem;
    font-weight: 300;
    margin-bottom: .71rem;
    display: block; }

.pcg-input-label-required::after {
  content: '*';
  color: #00B4EB;
  padding-left: 2px; }

.pcg-input, .pcg-datetimepicker-input {
  padding-left: 1.43rem;
  height: 2.86rem;
  border-radius: 100px; }

.pcg-textarea {
  padding: 1.43rem;
  border-radius: 5px; }

.pcg-field, .pcg-datetimepicker-input {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border: none;
  font-size: 0.86em;
  line-height: 1rem;
  color: #585858; }
  .pcg-field::placeholder, .pcg-datetimepicker-input::placeholder {
    color: #9D9D9D;
    opacity: 1; }
  .pcg-field:-ms-input-placeholder, .pcg-datetimepicker-input:-ms-input-placeholder, .pcg-field::-ms-input-placeholder, .pcg-datetimepicker-input::-ms-input-placeholder {
    color: #9D9D9D; }
  .pcg-field:hover, .pcg-datetimepicker-input:hover {
    color: #00B4EB; }
    .pcg-field:hover::placeholder, .pcg-datetimepicker-input:hover::placeholder {
      color: #00B4EB;
      opacity: 1; }
    .pcg-field:hover:-ms-input-placeholder, .pcg-datetimepicker-input:hover:-ms-input-placeholder, .pcg-field:hover::-ms-input-placeholder, .pcg-datetimepicker-input:hover::-ms-input-placeholder {
      color: #00B4EB; }
  .pcg-field:focus, .pcg-datetimepicker-input:focus {
    color: #585858;
    box-shadow: 0 3px 10px rgba(0, 180, 235, 0.3); }
    .pcg-field:focus::placeholder, .pcg-datetimepicker-input:focus::placeholder {
      color: #9D9D9D;
      opacity: 1; }
    .pcg-field:focus:-ms-input-placeholder, .pcg-datetimepicker-input:focus:-ms-input-placeholder, .pcg-field:focus::-ms-input-placeholder, .pcg-datetimepicker-input:focus::-ms-input-placeholder {
      color: #9D9D9D; }
  .pcg-field:invalid, .pcg-datetimepicker-input:invalid {
    color: #585858;
    box-shadow: 0 3px 10px rgba(252, 71, 71, 0.3); }
    .pcg-field:invalid::placeholder, .pcg-datetimepicker-input:invalid::placeholder {
      color: #9D9D9D;
      opacity: 1; }
    .pcg-field:invalid:-ms-input-placeholder, .pcg-datetimepicker-input:invalid:-ms-input-placeholder, .pcg-field:invalid::-ms-input-placeholder, .pcg-datetimepicker-input:invalid::-ms-input-placeholder {
      color: #9D9D9D; }
  .pcg-field:disabled, .pcg-datetimepicker-input:disabled, .pcg-field:read-only, .pcg-datetimepicker-input:read-only {
    cursor: not-allowed;
    color: #BDBEBD;
    background-color: #EEF0F5;
    box-shadow: none; }
    .pcg-field:disabled:hover, .pcg-datetimepicker-input:disabled:hover, .pcg-field:read-only:hover, .pcg-datetimepicker-input:read-only:hover {
      color: #BDBEBD; }
      .pcg-field:disabled:hover::placeholder, .pcg-datetimepicker-input:disabled:hover::placeholder, .pcg-field:read-only:hover::placeholder, .pcg-datetimepicker-input:read-only:hover::placeholder {
        color: #9D9D9D;
        opacity: 1; }
      .pcg-field:disabled:hover:-ms-input-placeholder, .pcg-datetimepicker-input:disabled:hover:-ms-input-placeholder, .pcg-field:disabled:hover::-ms-input-placeholder, .pcg-datetimepicker-input:disabled:hover::-ms-input-placeholder, .pcg-field:read-only:hover:-ms-input-placeholder, .pcg-datetimepicker-input:read-only:hover:-ms-input-placeholder, .pcg-field:read-only:hover::-ms-input-placeholder, .pcg-datetimepicker-input:read-only:hover::-ms-input-placeholder {
        color: #9D9D9D; }

.ng-invalid ~ .pcg-field-invalid,
.ng-valid ~ .pcg-field-valid,
.pcg-field:invalid ~ .pcg-field-invalid,
.pcg-datetimepicker-input:invalid ~ .pcg-field-invalid,
.pcg-field:valid ~ .pcg-field-valid,
.pcg-datetimepicker-input:valid ~ .pcg-field-valid {
  background: none;
  border: none;
  padding: 0;
  padding-top: 0.57rem;
  font-size: 0.71em;
  line-height: 0.79rem; }

.ng-invalid ~ .pcg-field-invalid,
.pcg-field ~ .pcg-field-invalid,
.pcg-datetimepicker-input ~ .pcg-field-invalid {
  color: #FC4747; }

.ng-valid ~ .pcg-field-valid,
.pcg-field ~ .pcg-field-valid,
.pcg-datetimepicker-input ~ .pcg-field-valid {
  color: #55C64C; }

.ng-invalid ~ .pcg-field-valid,
.ng-valid ~ .pcg-field-invalid,
.pcg-field:valid ~ .pcg-field-invalid,
.pcg-datetimepicker-input:valid ~ .pcg-field-invalid,
.pcg-field:invalid ~ .pcg-field-valid,
.pcg-datetimepicker-input:invalid ~ .pcg-field-valid {
  display: none; }

.pcg-wcag-contrast-green-black .pcg-input-label {
  color: #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-input-label-value {
    color: #FFFFFF; }

.pcg-spinner {
  animation: rotator 1.4s linear infinite;
  width: 4.64rem;
  height: 4.64rem; }

@keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

.pcg-spinner-path,
.pcg-spinner-path-file {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center; }

.pcg-spinner-path {
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite; }

.pcg-spinner-path-file {
  animation: dash 1.4s ease-in-out infinite, colors2 2.8s ease-in-out infinite; }

@keyframes colors2 {
  0% {
    stroke: #253B80; }
  100% {
    stroke: #253B80; } }

@keyframes colors {
  0% {
    stroke: #4285F4; }
  25% {
    stroke: #DE3E35; }
  50% {
    stroke: #F7C223; }
  75% {
    stroke: #1B9A59; }
  100% {
    stroke: #4285F4; } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

.nav-tabs .nav-item .nav-link {
  font-size: 1.14em;
  font-weight: 500;
  line-height: 1.36rem;
  border: none;
  padding-bottom: 0.71rem;
  color: #BDBEBD; }
  .nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item .pcg-sidebar-container .panel-group .panel-open .nav-link.pcg-sidebar-section-header, .pcg-sidebar-container .panel-group .panel-open .nav-tabs .nav-item .nav-link.pcg-sidebar-section-header {
    background-color: transparent;
    color: #00B4EB;
    border-bottom: 2px solid #00B4EB; }

.tab-content .tab-pane {
  margin-top: 1.43rem; }

.pcg-slide-tabs {
  display: inline-block;
  border-radius: 100px;
  background-color: #ABE3F5;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05); }
  .pcg-slide-tabs .pcg-slide-tab-btn {
    margin: 0;
    border-radius: 100px;
    font-weight: 500;
    cursor: pointer;
    background-color: #ABE3F5;
    color: #FFFFFF;
    border: none;
    padding: 0 2.14rem;
    font-size: 0.86em;
    height: 2.14rem; }
    .pcg-slide-tabs .pcg-slide-tab-btn.active, .pcg-slide-tabs .pcg-sidebar-container .panel-group .panel-open .pcg-slide-tab-btn.pcg-sidebar-section-header, .pcg-sidebar-container .panel-group .panel-open .pcg-slide-tabs .pcg-slide-tab-btn.pcg-sidebar-section-header {
      border: 2px solid #00B4EB;
      background-color: #00B4EB;
      box-shadow: 0px 2px 3px #00b4eb; }
    .pcg-slide-tabs .pcg-slide-tab-btn:disabled {
      cursor: not-allowed; }

.pcg-section {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 1.43rem; }
  .pcg-section .panel-default,
  .pcg-section .card-header {
    border: none; }
  .pcg-section .pcg-section-header,
  .pcg-section .panel-heading {
    font-weight: 500;
    padding: 1rem 2.14rem 0.79rem 2.14rem;
    border-radius: 5px;
    background-color: #FFFFFF; }
    .pcg-section .pcg-section-header .pcg-section-title,
    .pcg-section .panel-heading .pcg-section-title {
      color: #253B80;
      font-size: 1.14em;
      letter-spacing: 0.02rem; }
    .pcg-section .pcg-section-header .pcg-section-opened,
    .pcg-section .panel-heading .pcg-section-opened {
      display: none;
      color: #FFFFFF; }
    .pcg-section .pcg-section-header .pcg-section-closed,
    .pcg-section .panel-heading .pcg-section-closed {
      color: #00B4EB; }
    .pcg-section .pcg-section-header .pcg-section-btn,
    .pcg-section .panel-heading .pcg-section-btn {
      padding: 0; }
    .pcg-section .pcg-section-header .pcg-section-opened,
    .pcg-section .pcg-section-header .pcg-section-closed,
    .pcg-section .panel-heading .pcg-section-opened,
    .pcg-section .panel-heading .pcg-section-closed {
      cursor: pointer;
      float: right;
      font-size: 0.86em; }
  .pcg-section .pcg-section-content,
  .pcg-section .panel-body {
    display: none;
    background: #FFFFFF;
    border-radius: 0 0 5px 5px;
    padding: 2.14rem; }
  .pcg-section.pcg-section-active .pcg-section-header,
  .pcg-section.pcg-section-active .panel-heading,
  .pcg-section.pcg-section-active .card-header, .pcg-section.panel-open .pcg-section-header,
  .pcg-section.panel-open .panel-heading,
  .pcg-section.panel-open .card-header {
    border: none;
    background-color: #00B4EB;
    border-radius: 5px 5px 0 0; }
    .pcg-section.pcg-section-active .pcg-section-header .pcg-section-title,
    .pcg-section.pcg-section-active .panel-heading .pcg-section-title,
    .pcg-section.pcg-section-active .card-header .pcg-section-title, .pcg-section.panel-open .pcg-section-header .pcg-section-title,
    .pcg-section.panel-open .panel-heading .pcg-section-title,
    .pcg-section.panel-open .card-header .pcg-section-title {
      color: #FFFFFF; }
    .pcg-section.pcg-section-active .pcg-section-header .pcg-section-closed,
    .pcg-section.pcg-section-active .panel-heading .pcg-section-closed,
    .pcg-section.pcg-section-active .card-header .pcg-section-closed, .pcg-section.panel-open .pcg-section-header .pcg-section-closed,
    .pcg-section.panel-open .panel-heading .pcg-section-closed,
    .pcg-section.panel-open .card-header .pcg-section-closed {
      display: none; }
    .pcg-section.pcg-section-active .pcg-section-header .pcg-section-opened,
    .pcg-section.pcg-section-active .panel-heading .pcg-section-opened,
    .pcg-section.pcg-section-active .card-header .pcg-section-opened, .pcg-section.panel-open .pcg-section-header .pcg-section-opened,
    .pcg-section.panel-open .panel-heading .pcg-section-opened,
    .pcg-section.panel-open .card-header .pcg-section-opened {
      display: block; }
  .pcg-section.pcg-section-active .pcg-section-content,
  .pcg-section.pcg-section-active .panel-body, .pcg-section.panel-open .pcg-section-content,
  .pcg-section.panel-open .panel-body {
    display: block; }

.pcg-section-info {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.86em;
  line-height: 1.29rem;
  padding: 1.57rem 2.14rem 1.43rem 2.14rem;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  margin: 0.71rem 0; }
  .pcg-section-info .pcg-info-property {
    color: #585858; }
  .pcg-section-info .pcg-info-value {
    float: right;
    color: #00B4EB; }

.pcg-table-header-I {
  font-weight: 500;
  font-size: 1.14em;
  letter-spacing: 0.02rem;
  display: table;
  padding: 1rem 2.14rem 0.86rem 2.14rem;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  color: #FFFFFF;
  background-color: #253B80; }
  .pcg-table-header-I span {
    display: table-cell;
    vertical-align: middle;
    line-height: 1.36rem; }
  .pcg-table-header-I .pcg-header-link {
    cursor: pointer;
    display: table-cell;
    text-align: right;
    line-height: 1rem;
    float: right;
    font-size: 0.86em;
    letter-spacing: 0;
    color: #00B4EB; }
  .pcg-table-header-I .pcg-header-info {
    text-align: right;
    line-height: 1rem;
    display: flex;
    font-size: 0.86em;
    letter-spacing: 0;
    color: #00B4EB; }
  .pcg-table-header-I.pcg-danger-header {
    background-color: #FC4747; }

.pcg-table-header-II {
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.14rem;
  padding: 0.86rem 2.14rem 0.93rem 2.14rem;
  color: #585858;
  background-color: #ECF9FD; }
  .pcg-table-header-II span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.14rem; }

.pcg-table-container {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1.43rem;
  background-color: #FFFFFF; }
  .pcg-table-container .pcg-data-table-content {
    padding: 2.14rem;
    border-radius: 0 0 5px 5px; }
    .pcg-table-container .pcg-data-table-content .pcg-data-table-section {
      margin-bottom: 1.43rem;
      font-size: 0.86em;
      line-height: 1rem; }
      .pcg-table-container .pcg-data-table-content .pcg-data-table-section:last-child {
        margin: 0; }
      .pcg-table-container .pcg-data-table-content .pcg-data-table-section .pcg-data-table-property {
        margin-bottom: 0.71rem;
        font-weight: 500;
        letter-spacing: 0.04rem;
        color: #585858; }
      .pcg-table-container .pcg-data-table-content .pcg-data-table-section .pcg-data-table-value {
        font-weight: 300;
        color: #808080; }
  .pcg-table-container .pcg-table-content {
    padding: 2.14rem 0;
    background-color: #FFFFFF;
    border-radius: 0 0 5px 5px; }
    .pcg-table-container .pcg-table-content-without-margin {
      margin: -30px;
      margin-bottom: 0;
      padding: 0; }
    .pcg-table-container .pcg-table-content .pcg-table {
      width: 100%;
      border-collapse: collapse;
      font-size: 0.86em; }
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-header-row {
        color: #BDBEBD;
        font-weight: 500;
        line-height: 1.14em; }
        .pcg-table-container .pcg-table-content .pcg-table .pcg-table-header-row th {
          text-align: left;
          border-bottom: 1px solid #EEF0F5;
          padding-bottom: 0.71rem;
          padding-right: 5px;
          padding-left: 5px; }
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-header-row th:first-child {
            padding-left: 2.14rem; }
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-header-row th:last-child {
            padding-right: 2.14em; }
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row,
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row {
        color: #808080;
        font-weight: 300;
        line-height: 1.29em; }
        .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row td,
        .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row td {
          border-bottom: 1px solid #EEF0F5;
          padding-right: 5px;
          padding-left: 5px; }
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row td pcg-table-cell-ul,
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row td pcg-table-cell-ul {
            padding-left: 15px; }
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row td:first-child,
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row td:first-child {
            padding-left: 2.14rem; }
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row td:last-child,
          .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row td:last-child {
            padding-right: 2.14rem; }
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row td,
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row > div {
        min-height: 3.14rem;
        padding: 1.07rem 5px; }
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row td,
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row > div {
        height: 5.26rem;
        padding: 1.5rem 5px; }
      .pcg-table-container .pcg-table-content .pcg-table .pcg-table-summary-row {
        color: #253B80;
        font-weight: 500; }
        .pcg-table-container .pcg-table-content .pcg-table .pcg-table-summary-row td,
        .pcg-table-container .pcg-table-content .pcg-table .pcg-table-summary-row > div {
          border-bottom: none; }
  .pcg-table-container.pcg-table-responsive {
    min-width: unset;
    overflow-x: scroll; }
    .pcg-table-container.pcg-table-responsive .pcg-table-content {
      min-width: max-content; }

.pcg-table-section {
  cursor: pointer; }
  .pcg-table-section .pcg-section-header {
    font-weight: 500;
    letter-spacing: 0.02rem;
    line-height: 1.14rem;
    margin-bottom: 0.14rem;
    padding: 0.93rem 2.14rem 0.86rem 2.14rem;
    color: #585858;
    background-color: #ECF9FD; }
    .pcg-table-section .pcg-section-header .pcg-icon-section-header {
      cursor: pointer;
      margin-right: 0.71rem;
      color: #FFFFFF;
      background-color: #00B4EB;
      font-size: 1rem;
      vertical-align: middle;
      float: left;
      line-height: 1.14rem;
      border-radius: 50%;
      width: 1.07rem;
      height: 1.07rem;
      text-align: center; }
  .pcg-table-section .pcg-section-content {
    display: none; }
  .pcg-table-section.pcg-table-section-active .pcg-section-content {
    display: block; }
  .pcg-table-section:last-child {
    padding-bottom: 2.14rem; }
    .pcg-table-section:last-child .pcg-table-content {
      padding-bottom: 0; }
    .pcg-table-section:last-child .pcg-section-header {
      margin-bottom: 0; }

.pcg-table-subgroup {
  text-align: left;
  border-bottom: 1px solid #585858;
  line-height: 0;
  margin: 2.86rem 2.14rem; }
  .pcg-table-subgroup span {
    font-size: 1rem;
    font-weight: 500;
    color: #585858;
    background: #FFFFFF;
    padding-right: 0.71rem; }

.pcg-wcag-contrast-green-black .pcg-table-header-I {
  color: #000000;
  background-color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-table-header-I .pcg-header-link {
    color: #000000; }
  .pcg-wcag-contrast-green-black .pcg-table-header-I .pcg-header-info {
    color: #000000; }

.pcg-wcag-contrast-green-black .pcg-table-container {
  background-color: #000000;
  border: 1px solid;
  border-color: #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-table-container .pcg-data-table-content {
    border: 1px solid #FFFFFF; }
    .pcg-wcag-contrast-green-black .pcg-table-container .pcg-data-table-content .pcg-data-table-section .pcg-data-table-property {
      color: #FFFFFF; }
    .pcg-wcag-contrast-green-black .pcg-table-container .pcg-data-table-content .pcg-data-table-section .pcg-data-table-value {
      color: #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content {
    background-color: #000000; }
    .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-header-row {
      color: #FFFFFF;
      background-color: #000000; }
      .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-header-row th {
        border-bottom: 1px solid #EEF0F5; }
    .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row,
    .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row {
      color: #FFFFFF;
      background-color: #000000; }
      .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-narrow-row td,
      .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-wide-row td {
        border-bottom: 1px solid #EEF0F5; }
    .pcg-wcag-contrast-green-black .pcg-table-container .pcg-table-content .pcg-table .pcg-table-summary-row {
      color: #FFFFFF; }

.pcg-notification-group {
  cursor: pointer;
  display: inline-block; }
  .pcg-notification-group .pcg-notification-container {
    display: none;
    position: absolute;
    z-index: 4;
    width: 21.43rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #FFFFFF; }
    .pcg-notification-group .pcg-notification-container.pcg-notification-open {
      display: block; }
    .pcg-notification-group .pcg-notification-container .pcg-notification-header {
      color: #253B80;
      font-size: 1.14em;
      font-weight: 500;
      line-height: 1.36rem;
      padding-top: 1.5rem;
      padding-bottom: 1.43rem;
      text-align: center; }
    .pcg-notification-group .pcg-notification-container .pcg-notification-body {
      padding-bottom: 1.43rem;
      line-height: 1.07rem; }
      .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification {
        padding: 0.68rem 2.14rem;
        font-size: 0.86em;
        background-color: #F7F8FA;
        border-bottom: 1px solid #FFFFFF;
        cursor: pointer; }
        .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification .pcg-notification-icon {
          font-size: 2.93rem;
          padding: 0; }
        .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification .pcg-notification-title,
        .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification .pcg-notification-message {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 0; }
        .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification .pcg-notification-title {
          color: #585858;
          font-weight: 500;
          padding-bottom: 0.36rem; }
        .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification .pcg-notification-message {
          color: #808080; }
        .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification.pcg-notification-unreaded {
          background-color: #ECF9FD; }
          .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification.pcg-notification-unreaded .pcg-notification-title,
          .pcg-notification-group .pcg-notification-container .pcg-notification-body .pcg-notification.pcg-notification-unreaded .pcg-notification-message {
            color: #00B4EB; }

.popover {
  background-color: #253B80;
  border-radius: 10px; }
  .popover .popover-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    .popover .popover-header::before {
      border: none; }
  .popover .popover-body {
    border-radius: 10px; }
  .popover .popover-header,
  .popover .popover-body {
    background-color: #253B80;
    color: #FFFFFF;
    font-size: 0.71em;
    font-weight: 300;
    padding: 0.71rem 1.43rem; }

.bs-popover-left .arrow:after {
  border-left-color: #253B80; }

.bs-popover-right .arrow:after {
  border-right-color: #253B80; }

.bs-popover-top .arrow:after {
  border-top-color: #253B80; }

.bs-popover-bottom .arrow:after {
  border-bottom-color: #253B80; }

.pcg-search {
  margin-bottom: 1.43rem; }
  .pcg-search .pcg-input, .pcg-search .pcg-datetimepicker-input {
    padding-right: 2.86rem; }
  .pcg-search span {
    float: right;
    margin-right: 1.46rem;
    margin-top: -1.80rem;
    position: relative;
    z-index: 2;
    color: #00B4EB; }

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select .dropdown-toggle {
  color: #9D9D9D;
  box-shadow: 0 3px 10px rgba(32, 19, 19, 0.2);
  border-radius: 100px;
  height: 2.86rem;
  line-height: 1rem;
  font-size: 0.86em;
  padding: 1rem 1.5rem 0.86rem 1.5rem;
  border: none;
  background-color: #FFFFFF; }
  .bootstrap-select .dropdown-toggle::after {
    color: #00B4EB; }
  .bootstrap-select .dropdown-toggle:hover {
    box-shadow: 0 3px 10px rgba(0, 180, 235, 0.3);
    color: #00B4EB;
    background-color: #FFFFFF; }
  .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    text-overflow: ellipsis; }
  .bootstrap-select .dropdown-toggle:focus {
    outline: none !important; }

.bootstrap-select.show .dropdown-toggle {
  color: #FFFFFF;
  background-color: #00B4EB; }
  .bootstrap-select.show .dropdown-toggle::after {
    color: #FFFFFF; }

.bootstrap-select .dropdown-menu {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 0.71rem;
  padding: 0;
  background-color: #FFFFFF;
  font-size: 0.86em;
  font-weight: 300;
  line-height: 1rem;
  border: none; }
  .bootstrap-select .dropdown-menu .dropdown-item {
    color: #9D9D9D;
    padding: 1.29rem 1.43rem;
    white-space: initial; }
    .bootstrap-select .dropdown-menu .dropdown-item a:focus {
      outline: none !important; }
    .bootstrap-select .dropdown-menu .dropdown-item:hover {
      background-color: #EEF0F5;
      color: #00B4EB; }
    .bootstrap-select .dropdown-menu .dropdown-item.selected, .bootstrap-select .dropdown-menu .dropdown-item.active, .bootstrap-select .dropdown-menu .pcg-sidebar-container .panel-group .panel-open .dropdown-item.pcg-sidebar-section-header, .pcg-sidebar-container .panel-group .panel-open .bootstrap-select .dropdown-menu .dropdown-item.pcg-sidebar-section-header {
      font-weight: 500;
      background-color: #5090CD;
      color: #FFFFFF; }

.dropdown-list {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 0.71rem;
  padding: 0 !important;
  background-color: #FFFFFF;
  font-size: 0.86em;
  font-weight: 300;
  line-height: 1rem;
  border-radius: 5px; }
  .dropdown-list .arrow-up {
    display: none; }
  .dropdown-list .list-area {
    border-radius: 5px;
    border: none; }
    .dropdown-list .list-area .select-all {
      color: #9D9D9D;
      border: none; }
    .dropdown-list .list-area .list-filter {
      color: #9D9D9D;
      border-radius: 100px;
      border: solid 1px #9D9D9D;
      padding-right: 1.43rem; }
  .dropdown-list ul {
    border-radius: 5px;
    padding: 0 !important; }
    .dropdown-list ul li.pure-checkbox {
      color: #9D9D9D;
      padding: 1.29rem 0.5rem;
      white-space: initial;
      line-height: 1rem; }
      .dropdown-list ul li.pure-checkbox:hover {
        background-color: #EEF0F5;
        color: #00B4EB; }
      .dropdown-list ul li.pure-checkbox.selected-item {
        font-weight: 500;
        background-color: #5090CD;
        color: #FFFFFF; }
    .dropdown-list ul li:first-child {
      padding-top: 1.29rem !important; }
    .dropdown-list ul li:last-child {
      padding-bottom: 1.29rem !important; }
    .dropdown-list ul li label {
      margin: 0;
      cursor: pointer; }

.pcg-select .selected-list {
  color: #9D9D9D;
  box-shadow: 0 3px 10px rgba(32, 19, 19, 0.2);
  border-radius: 100px;
  line-height: 1rem;
  font-size: 0.86em;
  border: none;
  background-color: #FFFFFF;
  cursor: pointer; }
  .pcg-select .selected-list span {
    padding-left: 0.36rem; }
  .pcg-select .selected-list .c-btn {
    width: unset !important; }
  .pcg-select .selected-list .c-angle-down,
  .pcg-select .selected-list .c-angle-up {
    right: 1.43rem !important;
    width: 0.71rem !important; }
    .pcg-select .selected-list .c-angle-down svg,
    .pcg-select .selected-list .c-angle-up svg {
      fill: #00B4EB !important; }
  .pcg-select .selected-list:hover {
    box-shadow: 0 3px 10px rgba(0, 180, 235, 0.3);
    color: #00B4EB;
    background-color: #FFFFFF; }

.pcg-select.ng-invalid .selected-list {
  box-shadow: 0 3px 10px rgba(252, 71, 71, 0.3); }

.pcg-select .ng-arrow-wrapper {
  width: auto;
  padding-right: 1.43rem; }
  .pcg-select .ng-arrow-wrapper .ng-arrow {
    display: none !important;
    font-size: .875rem; }

.pcg-select.ng-select .ng-select-container {
  padding-left: .71rem;
  background-color: #FFFFFF;
  border-radius: 100px;
  border: none;
  color: #585858;
  height: 2.86rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.86em;
  font-weight: 300;
  cursor: pointer; }
  .pcg-select.ng-select .ng-select-container input {
    cursor: pointer !important; }
  .pcg-select.ng-select .ng-select-container .ng-arrow-wrapper:before {
    content: "";
    display: inline-block;
    font: normal normal normal 1.43rem/1 'PCGFont';
    font-size: 1.43rem;
    vertical-align: middle;
    text-rendering: auto;
    color: #00B4EB; }
  .pcg-select.ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding-left: .71rem;
    padding-top: .5rem;
    font-size: 0.86em;
    font-weight: 300;
    text-rendering: auto;
    color: #FFFFFF; }

.pcg-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #ECF9FD;
  border-color: #5090CD;
  color: #585858; }
  .pcg-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right-color: #5090CD; }
    .pcg-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:hover {
      color: #FFFFFF;
      background-color: #5090CD; }

.pcg-select:hover.ng-select  .ng-select-container {
  color: #00B4EB;
  box-shadow: 0 3px 10px rgba(0, 180, 235, 0.3); }

.pcg-select.ng-select-disabled.ng-select .ng-select-container {
  background-color: #EEF0F5;
  color: #BDBEBD;
  box-shadow: none;
  cursor: not-allowed; }
  .pcg-select.ng-select-disabled.ng-select .ng-select-container input {
    cursor: not-allowed !important; }
  .pcg-select.ng-select-disabled.ng-select .ng-select-container .ng-arrow-wrapper:before {
    color: #BDBEBD; }

.pcg-select.ng-select-opened.ng-select-bottom .ng-select-container, .pcg-select.ng-select-opened.ng-select-top .ng-select-container, .pcg-select.ng-select-focused.ng-select-bottom .ng-select-container, .pcg-select.ng-select-focused.ng-select-top .ng-select-container {
  border-radius: 100px;
  color: #FFFFFF;
  background-color: #00B4EB;
  box-shadow: 0 3px 10px rgba(0, 180, 235, 0.3); }
  .pcg-select.ng-select-opened.ng-select-bottom .ng-select-container .ng-value-container .ng-placeholder, .pcg-select.ng-select-opened.ng-select-top .ng-select-container .ng-value-container .ng-placeholder, .pcg-select.ng-select-focused.ng-select-bottom .ng-select-container .ng-value-container .ng-placeholder, .pcg-select.ng-select-focused.ng-select-top .ng-select-container .ng-value-container .ng-placeholder {
    color: #FFFFFF; }
  .pcg-select.ng-select-opened.ng-select-bottom .ng-select-container .ng-arrow-wrapper:before, .pcg-select.ng-select-opened.ng-select-top .ng-select-container .ng-arrow-wrapper:before, .pcg-select.ng-select-focused.ng-select-bottom .ng-select-container .ng-arrow-wrapper:before, .pcg-select.ng-select-focused.ng-select-top .ng-select-container .ng-arrow-wrapper:before {
    content: "";
    display: inline-block;
    font: normal normal normal 1.43rem/1 'PCGFont';
    font-size: 1.43rem;
    text-rendering: auto;
    vertical-align: middle;
    color: #00B4EB; }

.pcg-select.ng-select-focused.ng-select-bottom .ng-select-container .ng-arrow-wrapper:before, .pcg-select.ng-select-focused.ng-select-top .ng-select-container .ng-arrow-wrapper:before {
  content: "";
  color: #FFFFFF; }

.pcg-select.ng-select-opened.ng-select-bottom .ng-select-container .ng-arrow-wrapper:before, .pcg-select.ng-select-opened.ng-select-top .ng-select-container .ng-arrow-wrapper:before {
  content: "";
  color: #FFFFFF; }

.pcg-select.ng-dropdown-panel {
  min-width: min-content;
  margin-top: .71rem;
  border: none;
  border-radius: 5px; }
  .pcg-select.ng-dropdown-panel ::-webkit-scrollbar {
    width: .71rem !important; }
  .pcg-select.ng-dropdown-panel .ng-dropdown-panel-items {
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); }
    .pcg-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      white-space: normal;
      color: #9D9D9D;
      font-size: 0.86em;
      font-weight: 300;
      line-height: 1rem;
      padding: 1.29rem 1.43rem; }
      .pcg-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, .pcg-select.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
        color: #00B4EB;
        background-color: #EEF0F5;
        font-weight: 500; }

.pcg-select.ng-invalid .ng-select-container {
  box-shadow: 0 3px 10px rgba(252, 71, 71, 0.3); }

.pcg-datetimepicker-btn {
  margin: 0.5rem 0 0 1.43rem;
  padding: 0 1.21rem;
  border-radius: 100px !important; }

.pcg-datetimepicker-input {
  border-radius: 100px !important; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: unset; }
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days,
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months,
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years,
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades,
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries {
    padding: 1.86rem; }
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table thead tr th,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table thead tr th,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table thead tr th,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table thead tr th,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table thead tr th {
      border: none; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table thead tr th.prev,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table thead tr th.prev,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table thead tr th.prev,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table thead tr th.prev,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table thead tr th.prev {
        text-align: left;
        color: #00B4EB;
        padding-bottom: 2.14rem; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table thead tr th.picker-switch,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table thead tr th.picker-switch,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table thead tr th.picker-switch,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table thead tr th.picker-switch,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table thead tr th.picker-switch {
        font-size: 1.07rem;
        line-height: 1.29rem;
        color: #253B80;
        font-weight: 500;
        padding-bottom: 2.14rem; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table thead tr th.next,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table thead tr th.next,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table thead tr th.next,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table thead tr th.next,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table thead tr th.next {
        text-align: right;
        color: #00B4EB;
        padding-bottom: 2.14rem; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table thead tr th.dow,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table thead tr th.dow,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table thead tr th.dow,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table thead tr th.dow,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table thead tr th.dow {
        width: 2.57rem;
        padding-bottom: 1.07rem;
        line-height: 1.14em;
        color: #9D9D9D;
        font-weight: 500;
        border-bottom: solid 1px #EEF0F5; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table thead tr th:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table thead tr th:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table thead tr th:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table thead tr th:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table thead tr th:hover {
        background-color: transparent; }
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td,
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td {
      border: none;
      font-size: 1rem;
      font-weight: 300;
      color: #253B80;
      vertical-align: middle; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.day,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.day,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.day,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.day,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.day {
        height: 2.57rem;
        line-height: 1.14rem; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td .month,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td .year,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td .decade,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td .century,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td .month,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td .year,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td .decade,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td .century,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td .month,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td .year,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td .decade,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td .century,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td .month,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td .year,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td .decade,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td .century,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td .month,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td .year,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td .decade,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td .century {
        width: 3.21rem;
        height: 3.21rem;
        line-height: 3.21rem; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.day:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.day:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.day:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.day:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.day:hover,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span:hover {
        color: #FFFFFF;
        background-color: #4C97B7;
        border-radius: 50%; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.day.active, .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header, .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.day.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.day.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.day.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.day.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.day.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.day.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.day.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.day.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.day.pcg-sidebar-section-header,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span.active,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span.pcg-sidebar-section-header {
        color: #FFFFFF;
        background-color: #00B4EB;
        border-radius: 50%; }
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.day.active:hover, .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header:hover, .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.day.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.day.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.day.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.day.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.day.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.day.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.day.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.day.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr .pcg-sidebar-container .panel-group .panel-open td.day.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.day.pcg-sidebar-section-header:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span.active:hover,
        .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td .pcg-sidebar-container .panel-group .panel-open span.pcg-sidebar-section-header:hover,
        .pcg-sidebar-container .panel-group .panel-open .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span.pcg-sidebar-section-header:hover {
          background-color: #00B4EB; }
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span.old, .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody tr td span.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-months table tbody tr td span.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-years table tbody tr td span.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-decades table tbody tr td span.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span.old,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td.new,
      .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-centuries table tbody tr td span.new {
        color: #EEF0F5; }
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody td.today::before {
    border-bottom-color: #00B4EB;
    bottom: 0.57rem;
    right: 0.57rem; }
    .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody td.today::before:hover {
      border-bottom-color: #FFFFFF; }
  .bootstrap-datetimepicker-widget.dropdown-menu .datepicker-days table tbody:before {
    content: "";
    display: block;
    line-height: 1em; }
  .bootstrap-datetimepicker-widget.dropdown-menu .picker-switch span {
    color: #253B80; }
    .bootstrap-datetimepicker-widget.dropdown-menu .picker-switch span:hover {
      color: #FFFFFF;
      background-color: #4C97B7;
      border-radius: 5px; }
  .bootstrap-datetimepicker-widget.dropdown-menu .timepicker {
    padding: 10px; }
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table td, .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table td span {
      height: 2.14rem;
      width: 2.14rem;
      line-height: 2.14rem; }
      .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table td.hour:hover, .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table td.minute:hover, .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table td span.hour:hover, .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table td span.minute:hover {
        color: #FFFFFF;
        background-color: #4C97B7;
        border-radius: 50%; }
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table .timepicker-hour,
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table .timepicker-minute {
      color: #00B4EB;
      font-size: 1em; }
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table .btn,
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table .separator,
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table .hour,
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table .minute {
      color: #253B80; }
    .bootstrap-datetimepicker-widget.dropdown-menu .timepicker table span:hover {
      color: #FFFFFF;
      background-color: #4C97B7;
      border-radius: 50%; }

.bs-timepicker-field {
  padding-left: 0.86rem; }

timepicker table {
  margin: 0.71rem 0; }

timepicker .bs-chevron {
  color: #253B80; }

timepicker .btn:focus .bs-chevron {
  color: #167AA5; }

timepicker .btn:hover .bs-chevron {
  color: #4C97B7; }

.bs-datepicker {
  box-shadow: none; }

.bs-datepicker-container {
  padding: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); }
  .bs-datepicker-container .bs-datepicker-head {
    text-align: center;
    background-color: #FFFFFF !important;
    padding: 2.14rem 2.14rem 0px 2.14rem; }
    .bs-datepicker-container .bs-datepicker-head button {
      color: #00B4EB; }
      .bs-datepicker-container .bs-datepicker-head button.previous {
        text-align: left;
        float: left; }
      .bs-datepicker-container .bs-datepicker-head button.next {
        text-align: right;
        float: right; }
      .bs-datepicker-container .bs-datepicker-head button.current {
        color: #253B80;
        padding: 0;
        font-weight: 500;
        font-size: 1.07rem; }
      .bs-datepicker-container .bs-datepicker-head button.current:hover, .bs-datepicker-container .bs-datepicker-head button.previous:hover, .bs-datepicker-container .bs-datepicker-head button.next:hover {
        color: #4C97B7;
        background-color: transparent; }
  .bs-datepicker-container .bs-datepicker-body {
    border: none;
    padding: 0;
    margin-top: 1.79rem;
    min-height: unset; }
    .bs-datepicker-container .bs-datepicker-body table {
      margin-bottom: 1.07rem; }
      .bs-datepicker-container .bs-datepicker-body table thead tr th {
        color: #9D9D9D;
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 0.79rem;
        border-bottom: solid 1px #EEF0F5; }
        .bs-datepicker-container .bs-datepicker-body table thead tr th:first-child {
          text-align: left;
          padding-left: 2.14rem; }
        .bs-datepicker-container .bs-datepicker-body table thead tr th:last-child {
          text-align: right;
          padding-right: 2.14rem; }
      .bs-datepicker-container .bs-datepicker-body table tbody tr td {
        color: #585858;
        font-weight: 300;
        font-size: 14px;
        vertical-align: middle; }
        .bs-datepicker-container .bs-datepicker-body table tbody tr td:first-child {
          padding-left: 1.07rem; }
        .bs-datepicker-container .bs-datepicker-body table tbody tr td:last-child {
          padding-right: 1.07rem; }
        .bs-datepicker-container .bs-datepicker-body table tbody tr td .selected {
          color: #FFFFFF;
          background-color: #00B4EB; }
        .bs-datepicker-container .bs-datepicker-body table tbody tr td .is-highlighted:not(.selected) {
          color: #FFFFFF;
          background-color: #4C97B7; }
        .bs-datepicker-container .bs-datepicker-body table tbody tr td.is-highlighted:not(.selected) span {
          color: #FFFFFF;
          background-color: #4C97B7; }
      .bs-datepicker-container .bs-datepicker-body table.days span {
        width: 2.57rem;
        height: 2.57rem;
        line-height: 2.57rem;
        margin: 0.36rem; }
      .bs-datepicker-container .bs-datepicker-body table.days .is-other-month {
        display: none;
        margin: 0; }

.pcg-message-group {
  cursor: pointer;
  display: inline-block; }
  .pcg-message-group .pcg-message-container {
    display: none;
    position: absolute;
    z-index: 4;
    width: 27.07rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #FFFFFF; }
    .pcg-message-group .pcg-message-container.pcg-message-open {
      display: block; }
    .pcg-message-group .pcg-message-container .pcg-message-header {
      color: #253B80;
      font-size: 1.14em;
      font-weight: 500;
      line-height: 1.36rem;
      padding-top: 1.5rem;
      padding-bottom: 1.43rem;
      text-align: center; }
    .pcg-message-group .pcg-message-container .pcg-message-body {
      line-height: 1.07rem; }
      .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message {
        padding: 0.68rem 2.14rem;
        font-size: 0.86em;
        background-color: #F7F8FA;
        border-bottom: 1px solid #FFFFFF;
        cursor: pointer; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-icon {
          font-size: 2.93rem;
          padding: 0; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-text {
          padding-top: 0.36rem;
          padding-bottom: 0.36rem; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-from,
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-content,
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-time {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 0; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-from {
          color: #585858;
          font-weight: 500;
          border-right: 1.5px solid #585858;
          margin-right: 2px; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-content {
          color: #585858;
          font-weight: 300; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message .pcg-message-time {
          color: #808080;
          font-weight: 300;
          font-size: 0.71em;
          line-height: 0.79rem; }
        .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message.pcg-message-unreaded {
          background-color: #ECF9FD; }
          .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message.pcg-message-unreaded .pcg-message-from,
          .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message.pcg-message-unreaded .pcg-message-content,
          .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message.pcg-message-unreaded .pcg-message-time {
            color: #00B4EB; }
          .pcg-message-group .pcg-message-container .pcg-message-body .pcg-message.pcg-message-unreaded .pcg-message-from {
            border-right-color: #00B4EB; }
    .pcg-message-group .pcg-message-container .pcg-message-footer {
      color: #00B4EB;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.07rem;
      padding-top: 1.61rem;
      padding-bottom: 1.64rem;
      text-align: center; }

.pcg-profile-group {
  display: inline-block;
  margin-left: auto;
  cursor: pointer; }
  .pcg-profile-group .pcg-profile-btn, .pcg-profile-group .pcg-profile-btn-img {
    width: 63px;
    height: 2.86rem;
    background-color: #F7F8FA;
    color: #00B4EB;
    border-radius: 100px;
    text-align: center;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 0 2px; }
    .pcg-profile-group .pcg-profile-btn .pcg-icon-person, .pcg-profile-group .pcg-profile-btn-img .pcg-icon-person {
      font-size: 18px;
      margin: 8px;
      vertical-align: middle;
      padding: 0rem; }
    .pcg-profile-group .pcg-profile-btn .pcg-icon-profile-arrow, .pcg-profile-group .pcg-profile-btn-img .pcg-icon-profile-arrow {
      font-size: 1.2rem;
      vertical-align: middle; }
    .pcg-profile-group .pcg-profile-btn:after, .pcg-profile-group .pcg-profile-btn-img:after {
      display: none; }
  .pcg-profile-group .pcg-profile-btn-img {
    width: max-content;
    padding: 0 0.57rem 0 0.57rem;
    text-align: right; }
    .pcg-profile-group .pcg-profile-btn-img .pcg-profile-img {
      width: 2.21rem;
      height: 2.21rem;
      display: inline-block;
      border-radius: 50%; }
  .pcg-profile-group.show .pcg-profile-container {
    display: block;
    right: 1.4rem;
    width: 200px; }
    .pcg-profile-group.show .pcg-profile-container a:last-child {
      border-radius: 0 0 5px 5px; }
    .pcg-profile-group.show .pcg-profile-container a:first-child {
      border-radius: 5px 5px 0 0; }
    @media screen and (min-width: 768px) {
      .pcg-profile-group.show .pcg-profile-container {
        right: 4.11rem; } }
  .pcg-profile-group.btn-group {
    display: inline-flex; }

.pcg-profile-container,
.dropdown-menu {
  border: none;
  display: none;
  position: absolute;
  z-index: 4;
  padding: 0px;
  margin-top: 0.86rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #FFFFFF; }
  .pcg-profile-container .pcg-profile-option,
  .dropdown-menu .pcg-profile-option {
    line-height: 3.57rem;
    padding: 0 1.43rem;
    cursor: pointer; }
    .pcg-profile-container .pcg-profile-option .pcg-profile-option-txt,
    .pcg-profile-container .pcg-profile-option .pcg-font-icon,
    .dropdown-menu .pcg-profile-option .pcg-profile-option-txt,
    .dropdown-menu .pcg-profile-option .pcg-font-icon {
      font-size: 0.86em;
      display: inline-block;
      color: #585858;
      font-weight: 400;
      text-decoration: none; }
      .pcg-profile-container .pcg-profile-option .pcg-profile-option-txt:hover,
      .pcg-profile-container .pcg-profile-option .pcg-font-icon:hover,
      .dropdown-menu .pcg-profile-option .pcg-profile-option-txt:hover,
      .dropdown-menu .pcg-profile-option .pcg-font-icon:hover {
        text-decoration: none; }
    .pcg-profile-container .pcg-profile-option .pcg-font-icon,
    .dropdown-menu .pcg-profile-option .pcg-font-icon {
      margin-right: 1.43rem;
      width: 1rem;
      text-align: center; }
    .pcg-profile-container .pcg-profile-option:active, .pcg-profile-container .pcg-profile-option:hover,
    .dropdown-menu .pcg-profile-option:active,
    .dropdown-menu .pcg-profile-option:hover {
      background-color: #EEF0F5;
      color: #00B4EB; }
      .pcg-profile-container .pcg-profile-option:active .pcg-profile-option-txt,
      .pcg-profile-container .pcg-profile-option:active .pcg-font-icon, .pcg-profile-container .pcg-profile-option:hover .pcg-profile-option-txt,
      .pcg-profile-container .pcg-profile-option:hover .pcg-font-icon,
      .dropdown-menu .pcg-profile-option:active .pcg-profile-option-txt,
      .dropdown-menu .pcg-profile-option:active .pcg-font-icon,
      .dropdown-menu .pcg-profile-option:hover .pcg-profile-option-txt,
      .dropdown-menu .pcg-profile-option:hover .pcg-font-icon {
        color: #00B4EB; }
  .pcg-profile-container.show,
  .dropdown-menu.show {
    display: block; }

.pcg-basic-alert .alert, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid,
.pcg-basic-alert .ng-valid ~ .pcg-field-valid,
.pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid,
.pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid,
.pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid,
.pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid {
  border-radius: 5px;
  border: none;
  margin: 0.5rem 0; }
  .pcg-basic-alert .alert .pcg-alert-section .card, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card {
    background-color: unset;
    background-clip: unset;
    border: none;
    border-radius: unset; }
    .pcg-basic-alert .alert .pcg-alert-section .card .card-header, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .card-header, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .card-header, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .card-header {
      padding: 0;
      margin: 0;
      background-color: unset;
      border: none; }
      .pcg-basic-alert .alert .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .card-header .btn.focus, .pcg-basic-alert .alert .pcg-alert-section .card .card-header .btn:focus, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header .btn:focus, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .card-header .btn:focus, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header .btn:focus, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-header .btn:focus, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .card-header .btn:focus, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .card-header .btn:focus {
        outline: none;
        box-shadow: none; }
    .pcg-basic-alert .alert .pcg-alert-section .card .card-body, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-body, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .card-body, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-body, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .card-body, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .card-body, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .card-body {
      padding: 0;
      padding-left: 1.25rem; }
    .pcg-basic-alert .alert .pcg-alert-section .card .pcg-alert-message, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-message, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-message, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-message, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-message, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-message, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-message,
    .pcg-basic-alert .alert .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-error-code,
    .pcg-basic-alert .alert .pcg-alert-section .card .pcg-alert-additional-info,
    .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-additional-info,
    .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-additional-info,
    .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-additional-info,
    .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-additional-info,
    .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-additional-info,
    .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-additional-info {
      font-size: 0.71em;
      color: #808080;
      font-weight: 500; }
      .pcg-basic-alert .alert .pcg-alert-section .card .pcg-alert-message span, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-message span, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-message span, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-message span, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-message span, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-message span, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-message span,
      .pcg-basic-alert .alert .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-error-code span,
      .pcg-basic-alert .alert .pcg-alert-section .card .pcg-alert-additional-info span,
      .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-additional-info span,
      .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-additional-info span,
      .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-additional-info span,
      .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-section .card .pcg-alert-additional-info span,
      .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-additional-info span,
      .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-section .card .pcg-alert-additional-info span {
        font-weight: 300;
        display: block;
        margin-bottom: 5px; }
  .pcg-basic-alert .alert .pcg-alert-header, .pcg-basic-alert .ng-invalid ~ .pcg-field-invalid .pcg-alert-header, .pcg-basic-alert .ng-valid ~ .pcg-field-valid .pcg-alert-header, .pcg-basic-alert .pcg-field:invalid ~ .pcg-field-invalid .pcg-alert-header, .pcg-basic-alert .pcg-datetimepicker-input:invalid ~ .pcg-field-invalid .pcg-alert-header, .pcg-basic-alert .pcg-field:valid ~ .pcg-field-valid .pcg-alert-header, .pcg-basic-alert .pcg-datetimepicker-input:valid ~ .pcg-field-valid .pcg-alert-header {
    font-size: 0.86em;
    font-weight: 500;
    padding: 0; }

.pcg-basic-alert .alert-danger {
  background-color: #FED8D8; }
  .pcg-basic-alert .alert-danger .pcg-alert-header {
    color: #FC4747; }

.pcg-basic-alert .alert-success {
  background-color: #E2F4E1; }
  .pcg-basic-alert .alert-success .pcg-alert-header {
    color: #55C64C; }

.pcg-basic-alert .alert-info {
  background-color: #ECF9FD; }
  .pcg-basic-alert .alert-info .pcg-alert-header {
    color: #00B4EB; }

.pcg-basic-alert .alert-warning {
  background-color: #FFEFC8; }
  .pcg-basic-alert .alert-warning .pcg-alert-header {
    color: #FFB302; }

.toast-container .ngx-toastr {
  border-radius: 5px;
  box-shadow: none;
  padding: 1rem;
  width: 250px;
  background: none; }
  .toast-container .ngx-toastr .toast-title {
    font-size: 0.86em;
    font-weight: 500; }
  .toast-container .ngx-toastr .toast-message {
    font-size: 0.71em; }
  .toast-container .ngx-toastr.toast-info {
    background-image: none;
    background-color: #E7ECF8; }
    .toast-container .ngx-toastr.toast-info .toast-title {
      color: #167AA5; }
    .toast-container .ngx-toastr.toast-info .toast-message {
      color: #808080; }
  .toast-container .ngx-toastr.toast-success {
    background-image: none;
    background-color: #E2F4E1; }
    .toast-container .ngx-toastr.toast-success .toast-title {
      color: #55C64C; }
    .toast-container .ngx-toastr.toast-success .toast-message {
      color: #808080; }
  .toast-container .ngx-toastr:hover {
    box-shadow: none; }

.pcg-wcag-contrast-green-black .pcg-basic-alert .alert-danger {
  background-color: #000000;
  border: 1px solid #FC4747; }

.pcg-wcag-contrast-green-black .pcg-basic-alert .alert-success {
  background-color: #000000;
  border: 1px solid #55C64C; }

.pcg-wcag-contrast-green-black .pcg-basic-alert .alert-info {
  background-color: #000000;
  border: 1px solid #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-basic-alert .alert-info .pcg-alert-header {
    color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-basic-alert .alert-warning {
  border: 1px solid #FFB302;
  background-color: #000000; }

.pcg-panel {
  font-family: Roboto;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px; }
  .pcg-panel-collapsed .pcg-panel-content {
    display: none; }
  .pcg-panel-collapsed .pcg-action-collapse .action-hide {
    display: none; }
  .pcg-panel-collapsed .pcg-action-collapse .action-show {
    display: block; }
  .pcg-panel-expanded {
    display: block; }
    .pcg-panel-expanded .pcg-action-collapse .action-hide {
      display: block; }
    .pcg-panel-expanded .pcg-action-collapse .action-show {
      display: none; }
  .pcg-panel-content {
    padding: 0px 30px 50px 30px; }
    .pcg-panel-content .pcg-action-column {
      display: flex;
      align-items: center;
      align-content: center;
      height: 100%;
      flex-wrap: wrap; }
      .pcg-panel-content .pcg-action-column a {
        margin-right: 10px;
        font-size: 14px; }
        .pcg-panel-content .pcg-action-column a:hover, .pcg-panel-content .pcg-action-column a:active, .pcg-panel-content .pcg-action-column a:focus {
          text-decoration: none; }
  .pcg-panel-header {
    cursor: pointer;
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .pcg-panel-header-title {
      color: #253B80;
      font-weight: 500;
      font-size: 1.71em;
      line-height: 42px; }
    .pcg-panel-header-actions {
      margin-left: auto;
      color: #00B4EB;
      font-size: 0.86em;
      display: flex;
      font-weight: 500; }
      .pcg-panel-header-actions .pcg-action-item {
        display: flex;
        align-items: center;
        min-width: 40px;
        padding-left: 30px; }
        .pcg-panel-header-actions .pcg-action-item .pcg-action-collapse {
          cursor: pointer; }
        .pcg-panel-header-actions .pcg-action-item .pcg-action-priority {
          display: flex;
          flex-direction: column; }
          .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button {
            text-decoration: none;
            font-size: 0.86em; }
            .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button:active, .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button:focus, .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button:hover {
              text-decoration: none; }
          .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .pcg-icon {
            background-color: #00B4EB;
            color: #FFFFFF;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-left: 10px; }
            .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .pcg-icon:hover {
              background-color: #5090CD; }
            .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .pcg-icon-priority-up {
              border-radius: 100px 100px 0 0;
              margin-bottom: 1px; }
            .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .pcg-icon-priority-down {
              border-radius: 0 0 100px 100px;
              margin-top: 1px; }
          .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .aspNetDisabled .pcg-icon {
            background-color: #ABE3F5; }
            .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .aspNetDisabled .pcg-icon:hover {
              background-color: #ABE3F5; }
      .pcg-panel-header-actions span {
        font-size: 1em; }

.pcg-wcag-contrast-green-black .pcg-panel {
  border: 1px solid #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-panel-header-title {
    color: #FFFFFF; }
  .pcg-wcag-contrast-green-black .pcg-panel-header-actions {
    color: #66FFA7; }
    .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button {
      text-decoration: none;
      font-size: 0.86em; }
      .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button:active, .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button:focus, .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority-button:hover {
        text-decoration: none; }
    .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .pcg-icon {
      background-color: #66FFA7;
      color: #000000; }
      .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .pcg-icon:hover {
        background-color: #66FFA7; }
    .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .aspNetDisabled .pcg-icon {
      background-color: #000000;
      border: 1px solid #FFFFFF;
      color: #FFFFFF !important; }
      .pcg-wcag-contrast-green-black .pcg-panel-header-actions .pcg-action-item .pcg-action-priority .aspNetDisabled .pcg-icon:hover {
        background-color: #000000;
        color: #FFFFFF !important; }
  .pcg-wcag-contrast-green-black .pcg-panel-content .pcg-input-label, .pcg-wcag-contrast-green-black .pcg-panel-content .pcg-input-label-value {
    color: #FFFFFF; }

.pcg-tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.pcg-tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #253B80; }

.pcg-tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.pcg-tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #253B80; }

.pcg-tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before,
.pcg-tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #253B80; }

.pcg-tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.pcg-tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #253B80; }

.pcg-tooltip .tooltip-inner {
  background-color: #253B80;
  border-radius: 10px; }

.modal-dialog {
  pointer-events: all;
  width: auto; }

.pcg-modal {
  border-radius: 5px;
  pointer-events: all; }
  .pcg-modal-xs {
    max-width: 300px; }
  .pcg-modal-sm {
    max-width: 500px; }
  .pcg-modal-md {
    max-width: 700px; }
  .pcg-modal-lg {
    max-width: 1000px; }
  .pcg-modal-xl {
    max-width: 1200px; }
  .pcg-modal-header {
    padding: 10px 30px;
    min-height: 44px;
    line-height: 1.29em;
    font-size: 1.14em;
    color: #FFFFFF;
    background-color: #253B80;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center; }
  .pcg-modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding-top: 30px; }
    .pcg-modal-footer .pcg-button, .pcg-modal-footer .pcg-datetimepicker-btn {
      margin-left: 20px;
      margin-bottom: 10px; }
  .pcg-modal-body {
    padding: 30px 30px 20px 30px;
    background-color: #FFFFFF;
    border-radius: 0 0 5px 5px; }
    .pcg-modal-body-without-padding {
      padding: 0;
      padding-bottom: 20px; }
      .pcg-modal-body-without-padding > .pcg-modal-footer {
        padding-right: 30px;
        padding-left: 30px; }
    .pcg-modal-body .pcg-table-container {
      padding-bottom: 0px;
      margin-bottom: 0px; }
      .pcg-modal-body .pcg-table-container .pcg-table-empty-row td {
        padding-top: 30px;
        padding-left: 30px;
        color: #808080; }
  .pcg-modal.pcg-modal-danger .pcg-modal-header {
    background-color: #FC4747; }

.pcg-wcag-contrast-green-black .pcg-modal {
  border: 1px solid #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-modal-header {
    border-radius: unset;
    color: #000000;
    background-color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-modal-body {
    background-color: #000000; }
  .pcg-wcag-contrast-green-black .pcg-modal.pcg-modal-danger {
    border: 1px solid #FC4747; }
    .pcg-wcag-contrast-green-black .pcg-modal.pcg-modal-danger .pcg-modal-header {
      border-radius: unset;
      background-color: #FC4747;
      color: #FFFFFF; }

.pcg-languages-list {
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  width: 60px;
  height: 20px;
  background: #00B4EB;
  box-shadow: 0px 5px 10px rgba(0, 180, 235, 0.4);
  z-index: 1000;
  border-radius: 100px;
  line-height: initial; }
  .pcg-languages-list:hover {
    background: #5090CD; }
  .pcg-languages-list .selected-language {
    color: #FFFFFF;
    font-size: 0.71em;
    font-weight: 400;
    padding: 0 7px;
    display: flex;
    align-items: center;
    height: 100%; }
  .pcg-languages-list .language-arrow {
    margin-left: auto; }

.pcg-languages-list > .pcg-lang-options {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  list-style-type: none;
  background: #FFFFFF;
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); }
  .pcg-languages-list > .pcg-lang-options > a,
  .pcg-languages-list > .pcg-lang-options > button {
    border: none;
    background-color: transparent;
    width: 100%;
    min-height: 20px;
    padding: 3px 7px;
    display: flex;
    align-items: center; }
    .pcg-languages-list > .pcg-lang-options > a:hover,
    .pcg-languages-list > .pcg-lang-options > button:hover {
      background-color: #EEF0F5; }
    .pcg-languages-list > .pcg-lang-options > a:first-child,
    .pcg-languages-list > .pcg-lang-options > button:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .pcg-languages-list > .pcg-lang-options > a:last-child,
    .pcg-languages-list > .pcg-lang-options > button:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
  .pcg-languages-list > .pcg-lang-options a {
    text-decoration: none; }

.pcg-languages-list.show > .pcg-lang-options {
  display: block; }

.lenguage-name {
  color: #9D9D9D;
  font-size: 0.71em; }

.lenguage-icon {
  margin-right: 3px;
  width: 14px;
  height: 14px; }

.icon-pl {
  background-image: url("../../images/src/republic-of-poland.svg");
  background-repeat: no-repeat;
  background-position: center; }

.icon-en {
  background-image: url("../../images/src/united-kingdom.svg");
  background-repeat: no-repeat;
  background-position: center; }

.icon-es {
  background-image: url("../../images/src/spain.svg");
  background-repeat: no-repeat;
  background-position: center; }

.icon-it {
  background-image: url("../../images/src/italy.svg");
  background-repeat: no-repeat;
  background-position: center; }

.icon-de {
  background-image: url("../../images/src/germany.svg");
  background-repeat: no-repeat;
  background-position: center; }

.icon-fr {
  background-image: url("../../images/src/france.svg");
  background-repeat: no-repeat;
  background-position: center; }

.pcg-wcag-contrast-green-black .pcg-languages-list {
  background: #66FFA7;
  box-shadow: none; }
  .pcg-wcag-contrast-green-black .pcg-languages-list:hover {
    background: #66FFA7;
    opacity: 80%; }
  .pcg-wcag-contrast-green-black .pcg-languages-list .selected-language {
    color: #000000; }

.pcg-wcag-contrast-green-black .pcg-languages-list > .pcg-lang-options {
  background: #000000;
  border: 1px solid #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-languages-list > .pcg-lang-options > button:hover {
    background-color: #66FFA7; }
    .pcg-wcag-contrast-green-black .pcg-languages-list > .pcg-lang-options > button:hover .lenguage-name {
      color: #000000; }

.pcg-wcag-contrast-green-black .lenguage-name {
  color: #66FFA7; }

.shadow-box-b0 {
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1); }

.shadow-box-b1 {
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.1); }

.shadow-box-b2 {
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2); }

.rounded-box-5 {
  border-radius: 5px; }

.pcg-page-container {
  min-height: 100vh;
  min-width: 320px;
  max-width: 1600px;
  background-color: #F7F8FA; }

.pcg-body-container {
  height: calc(100vh - 7.5em); }

.pcg-content-container {
  background-color: #F7F8FA;
  overflow: auto;
  width: 100%;
  min-height: calc(100vh - 7.5em - 4.27em);
  padding: 30px 20px; }
  @media screen and (min-width: 768px) {
    .pcg-content-container {
      padding: 30px 60px; } }

.pcg-scrollable {
  overflow: auto;
  height: 100%; }

.form-group {
  position: relative;
  margin-bottom: 20px; }

.pcg-wcag-contrast-green-black .pcg-page-container,
.pcg-wcag-contrast-green-black .pcg-content-container {
  background-color: #000000; }

.pcg-footer-container {
  margin-top: 15px;
  line-height: 1.71em;
  min-height: 4.27em;
  background-color: #F7F8FA;
  margin: 0 20px;
  display: flex;
  align-items: center;
  border-top: solid 1px #EEF0F5; }
  @media screen and (min-width: 768px) {
    .pcg-footer-container {
      margin: 0 60px; } }
  .pcg-footer-container img {
    height: 25px; }
  .pcg-footer-container .pcg-footer-text {
    font-size: 0.71em;
    color: #9D9D9D;
    margin-left: auto;
    letter-spacing: 0.4px; }

.pcg-footer-custom-container {
  display: flex; }

.pcg-client-footer-container {
  display: flex; }

.pcg-custom-footer-container {
  display: flex; }

.pcg-footer-custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: flex; }
  .pcg-footer-custom-img {
    background-image: url("../../build/images/src/logo-pcg-footer.svg");
    width: 120px;
    height: 25px;
    background-repeat: no-repeat; }

.pcg-client-footer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px; }
  .pcg-client-footer-img {
    background-image: url("../../build/images/src/pcg_logo.svg");
    width: 80%;
    height: 100%;
    background-repeat: no-repeat; }

.pcg-custom-footer {
  display: flex;
  padding-top: 20px;
  padding-bottom: 40px; }

footer .container {
  background: #F7F8FA;
  padding: 10px; }
  footer .container img {
    float: left;
    left: 20px;
    position: relative; }

footer a {
  color: #fff;
  font-size: 20px;
  padding: 10px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease; }
  footer a:hover {
    position: relative;
    bottom: 1px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    color: #fff; }

.pcg-footer-icons {
  margin-right: 2rem; }

.footer-mid .footer-mid-container {
  height: auto;
  line-height: 25px; }

.footer-mid .pcg-footer-text {
  text-align: right;
  font: Regular 12px/20px Roboto;
  letter-spacing: 0.48px;
  color: #9D9D9D;
  opacity: 1; }

.footer-mid .pcg-footer-section {
  margin-top: 1rem;
  margin-bottom: 1.5rem; }

.pcg-footer-info {
  text-align: left;
  font-size: 0.85714em;
  letter-spacing: 0.48px;
  color: #9D9D9D;
  opacity: 1;
  line-height: 2px; }

.pcg-footer-container .img-ue {
  height: 60px; }

@media screen and (max-width: 868px) {
  .pcg-footer-img {
    display: none; } }

.pcg-footer-social-media {
  display: inline-block;
  color: #BDBEBD; }
  .pcg-footer-social-media a,
  .pcg-footer-social-media a:hover {
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block; }
  .pcg-footer-social-media .pcg-icon {
    color: #BDBEBD;
    font-size: 1.71em;
    margin-left: 5px; }
  .pcg-footer-social-media .pcg-icon-facebook:hover {
    color: #3b5998; }
  .pcg-footer-social-media .pcg-icon-instagram:hover {
    color: #E1306C; }
  .pcg-footer-social-media .pcg-icon-youtube:hover {
    color: #ff0000; }
  .pcg-footer-social-media .pcg-icon-twitter:hover {
    color: #1da1f2; }
  .pcg-footer-social-media .pcg-icon-linkedin:hover {
    color: #00a0dc; }

.pcg-wcag-contrast-green-black .pcg-footer-social-media {
  display: inline-block;
  color: #BDBEBD; }
  .pcg-wcag-contrast-green-black .pcg-footer-social-media a,
  .pcg-wcag-contrast-green-black .pcg-footer-social-media a:hover {
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block; }
    .pcg-wcag-contrast-green-black .pcg-footer-social-media a:hover,
    .pcg-wcag-contrast-green-black .pcg-footer-social-media a:hover:hover {
      color: #BDBEBD; }
  .pcg-wcag-contrast-green-black .pcg-footer-social-media .pcg-icon {
    color: #BDBEBD; }

.pcg-wcag-contrast-green-black .pcg-footer-container {
  background-color: #000000;
  border-top: solid 1px #EEF0F5; }
  .pcg-wcag-contrast-green-black .pcg-footer-container .pcg-footer-text {
    color: #FFFFFF; }

.pcg-header-container {
  height: 7.5em;
  position: relative; }
  .pcg-header-container .pcg-navbar-container {
    z-index: 1;
    width: 100%;
    background-color: #FFFFFF;
    min-height: 65px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #F7F8FA; }
    .pcg-header-container .pcg-navbar-container .pcg-toolbar-items {
      margin-left: auto; }
    .pcg-header-container .pcg-navbar-container .pcg-icon-dot {
      height: 12px;
      width: 12px;
      background-color: #00B4EB;
      border-radius: 50%;
      display: inline-block;
      margin-right: 20px; }
    .pcg-header-container .pcg-navbar-container .logged-user-info {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      color: #253B80; }
  .pcg-header-container .pcg-logo-container {
    z-index: 2;
    width: 260px;
    line-height: 104px;
    background-color: #FFFFFF;
    border-bottom: solid 1px #EEF0F5;
    padding: 0 30px;
    display: none;
    box-shadow: 6px 0 30px rgba(0, 0, 0, 0.1); }
    @media screen and (min-width: 768px) {
      .pcg-header-container .pcg-logo-container {
        display: block; } }
    .pcg-header-container .pcg-logo-container-img {
      max-width: 200px;
      max-height: 104px;
      background-image: url("../../build/images/src/logo_pcg_color.svg"); }
  .pcg-header-container .pcg-top-container {
    width: 100%;
    background-color: #F7F8FA;
    display: flex;
    min-height: 40px !important;
    height: auto !important;
    justify-content: space-between; }
    .pcg-header-container .pcg-top-container .pcg-txt-right {
      display: flex;
      align-items: center; }
    .pcg-header-container .pcg-top-container .pcg-txt-right:only-child {
      margin-left: auto; }
    .pcg-header-container .pcg-top-container-img {
      background-image: url("../../build/images/src/logo_pcg_color.svg");
      width: 200px;
      height: 42px;
      background-repeat: no-repeat; }
  .pcg-header-container .pcg-top-container,
  .pcg-header-container .pcg-navbar-container {
    padding: 10px 20px; }
    @media screen and (min-width: 768px) {
      .pcg-header-container .pcg-top-container,
      .pcg-header-container .pcg-navbar-container {
        padding: 10px 60px; } }

.pcg-top-btn {
  font-size: 0.86em;
  height: 20px;
  line-height: 20px;
  padding: 0 1rem;
  margin: 0px 0px 0px 10px;
  box-shadow: 0px 5px 10px rgba(0, 180, 235, 0.4); }

.pcg-login-container-img {
  background-image: url("../../build/images/src/logo_pcg_color.svg");
  width: 200px;
  height: 42px;
  background-repeat: no-repeat; }

.pcg-wcag-contrast-green-black .pcg-header-container {
  border: 1px solid #66FFA7; }

.pcg-wcag-contrast-green-black .pcg-logo-container {
  background-color: #000000;
  border: none; }

.pcg-wcag-contrast-green-black .pcg-top-container {
  background-color: #000000; }

.pcg-wcag-contrast-green-black .pcg-navbar-container {
  background-color: #000000;
  border: none; }
  .pcg-wcag-contrast-green-black .pcg-navbar-container .pcg-icon-dot {
    background-color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-navbar-container .logged-user-info {
    color: #FFFFFF; }

.pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-btn, .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-btn-img {
  background-color: #66FFA7;
  color: #000000; }

.pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container {
  background-color: #000000;
  border: 0.1rem solid #66FFA7;
  box-shadow: 0px 10px 20px rgba(102, 209, 167, 0.4); }
  .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a {
    color: #66FFA7;
    background-color: #000000; }
    .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a .pcg-profile-option {
      background-color: transparent; }
    .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a:hover {
      color: #000000;
      background-color: #66FFA7; }
      .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a:hover .pcg-font-icon, .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a:hover .pcg-profile-option-txt {
        color: #000000; }
    .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a .pcg-font-icon, .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container a .pcg-profile-option-txt {
      color: #66FFA7; }
  .pcg-wcag-contrast-green-black .pcg-profile-group .pcg-profile-container:hover {
    background-color: #66FFA7; }

.pcg-sidebar-container {
  z-index: 2;
  width: 18.57rem;
  background-color: #FFFFFF;
  padding: 0;
  display: none;
  max-height: inherit;
  box-shadow: 6px 0 30px 0 rgba(0, 0, 0, 0.1); }
  .pcg-sidebar-container.open-menu {
    position: absolute;
    display: block;
    height: calc(100vh - 7.5em); }
  @media screen and (min-width: 768px) {
    .pcg-sidebar-container {
      display: block; }
      .pcg-sidebar-container.open-menu {
        position: relative; } }
  .pcg-sidebar-container .panel-group .card {
    border: none;
    border-radius: 0;
    background: none; }
    .pcg-sidebar-container .panel-group .card .card-header {
      padding: 0;
      border: none;
      background: none;
      border-radius: 0; }
      .pcg-sidebar-container .panel-group .card .card-header button.btn.btn-link.btn-block.clearfix {
        padding: 0;
        border: none; }
    .pcg-sidebar-container .panel-group .card .card-body {
      padding: 0; }
  .pcg-sidebar-container .panel-group .panel-open .list-group-item {
    padding-left: 5rem;
    background-color: #F7F8FA; }
    .pcg-sidebar-container .panel-group .panel-open .list-group-item.active, .pcg-sidebar-container .panel-group .panel-open .list-group-item.pcg-sidebar-section-header {
      background-color: #5090CD;
      color: #FFFFFF; }
  .pcg-sidebar-container .list-group {
    height: 100%; }
    .pcg-sidebar-container .list-group .list-group-item,
    .pcg-sidebar-container .list-group .pcg-sidebar-section-header {
      border-radius: 0;
      line-height: 1.14rem;
      padding: 0.96rem 2.14rem 1.1rem 2.14rem;
      margin: 0;
      border: none;
      color: #808080;
      text-align: left;
      font-weight: 400;
      cursor: pointer; }
      .pcg-sidebar-container .list-group .list-group-item .pcg-sidebar-icon,
      .pcg-sidebar-container .list-group .pcg-sidebar-section-header .pcg-sidebar-icon {
        font-size: 1.14em;
        margin-right: 1.07rem;
        width: 1.79rem;
        display: inline-block;
        text-align: center;
        vertical-align: middle; }
      .pcg-sidebar-container .list-group .list-group-item:hover,
      .pcg-sidebar-container .list-group .pcg-sidebar-section-header:hover {
        background-color: #4C97B7;
        color: #FFFFFF; }
      .pcg-sidebar-container .list-group .list-group-item.active, .pcg-sidebar-container .list-group .panel-group .panel-open .list-group-item.pcg-sidebar-section-header, .pcg-sidebar-container .panel-group .panel-open .list-group .list-group-item.pcg-sidebar-section-header,
      .pcg-sidebar-container .list-group .pcg-sidebar-section-header.active,
      .pcg-sidebar-container .list-group .panel-group .panel-open .pcg-sidebar-section-header,
      .pcg-sidebar-container .panel-group .panel-open .list-group .pcg-sidebar-section-header {
        background-color: #253B80;
        color: #00B4EB; }
  .pcg-sidebar-container .pcg-sidebar-section-header .pcg-icon-sidebar-section {
    vertical-align: middle;
    float: right;
    line-height: 1.14rem; }
  .pcg-sidebar-container .pcg-sidebar-section-header .pcg-icon-nav {
    color: #00B4EB; }
  .pcg-sidebar-container .pcg-sidebar-section-header.active .pcg-icon-sidebar-section, .pcg-sidebar-container .panel-group .panel-open .pcg-sidebar-section-header .pcg-icon-sidebar-section {
    color: #00B4EB; }
  .pcg-sidebar-container .pcg-sidebar-section-header:hover .pcg-icon-nav {
    color: #FFFFFF; }
  .pcg-sidebar-container .pcg-sidebar-section-content .list-group-item {
    padding-left: 5rem;
    background-color: #F7F8FA; }
    .pcg-sidebar-container .pcg-sidebar-section-content .list-group-item.active, .pcg-sidebar-container .pcg-sidebar-section-content .panel-group .panel-open .list-group-item.pcg-sidebar-section-header, .pcg-sidebar-container .panel-group .panel-open .pcg-sidebar-section-content .list-group-item.pcg-sidebar-section-header {
      background-color: #5090CD;
      color: #FFFFFF; }

.pcg-btn-sidebar-menu {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #253B80;
  width: 1.43rem;
  height: 2.14rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-right-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomright: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  position: fixed;
  z-index: 1000;
  display: none; }
  .pcg-btn-sidebar-menu:hover {
    background-color: #4C97B7; }
  @media screen and (min-width: 768px) {
    .pcg-btn-sidebar-menu {
      display: flex; } }

.pcg-wcag-contrast-green-black .pcg-sidebar-container {
  border: 1px solid #66FFA7;
  border-top: none;
  background-color: #000000; }
  .pcg-wcag-contrast-green-black .pcg-sidebar-container .list-group .list-group-item,
  .pcg-wcag-contrast-green-black .pcg-sidebar-container .list-group .pcg-sidebar-section-header {
    color: #66FFA7;
    background-color: #000000; }
    .pcg-wcag-contrast-green-black .pcg-sidebar-container .list-group .list-group-item:hover,
    .pcg-wcag-contrast-green-black .pcg-sidebar-container .list-group .pcg-sidebar-section-header:hover {
      background-color: #66FFA7;
      color: #000000; }
