.pcg-checkbox-container,
.pcg-radio-container {
  display: inline-block;
  position: relative;
  padding-left: 2.43rem;
  margin-right: 2.43rem;
  margin-bottom: 0.86rem;
  cursor: pointer;
  color: $pcg-dark-gray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &:hover{
    color: $main-active-color;
    .pcg-checkmark,
    .pcg-radio{
      border-color: $main-active-color;
    }
  }
}


.pcg-checkbox-container {  
  line-height: 1.14rem;
  .pcg-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: $pcg-white;
    border-radius: 2px;
    border: 2px solid $pcg-light-gray;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover{
    .pcg-checkmark {
      border-color: $main-active-color;
    }
  }
  input:checked ~ .pcg-checkmark {
    background-color: $main-active-color;
    border-color: $main-active-color;
    &:after {
      display: block;
      left: 0.25rem;
      top: 0.1rem;
      width: 0.29rem;
      height: 0.43rem;
      border: solid white;
      border-width: 0 0.11rem 0.11rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:indeterminate ~ .pcg-checkmark {
    background-color: $main-active-color;
    border-color: $main-active-color;
    &:after {
	  content: "";
      display: block;
      left: 0.05rem;
      top: 0.03rem;
	  font-color: $pcg-white;
      width: 0.62rem;
      height: 0.43rem;
      border: solid white;
      border-width: 0 0 0.20rem 0;
    }
  }
}

.pcg-radio-container {
  line-height: 1.29rem;
  input:checked ~ .pcg-radio {
    background-color: $pcg-white;
    border-color: $main-active-color;
    border-width: 0.43rem;
    height: 1.29rem;
    width: 1.29rem;
  }
  .pcg-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.29rem;
    width: 1.29rem;
    border: 2px solid $pcg-light-gray;
    background-color: $pcg-white;
    border-radius: 50%;
  }  
}



.pcg-wcag-contrast-green-black {
  .pcg-checkbox-container,
  .pcg-radio-container {
    color: $color-wcag-static;

    &:hover{
      color: $color-wcag-contrast;
     
      .pcg-checkmark,
      .pcg-radio{
        border-color: $color-wcag-contrast;
      }
    }
  }
  
  
  .pcg-checkbox-container {  
    &:hover{
      .pcg-checkmark {
        border-color: $color-wcag-contrast;
      }
    }
	 input:indeterminate ~ .pcg-checkmark {
      background-color: $color-wcag-contrast;
      border-color: $color-wcag-contrast;
	  
	  &:after {    
      border: solid black;
      border-width: 0 0.11rem 0.11rem 0;
      }
    }
	
    input:checked ~ .pcg-checkmark {
      background-color: $color-wcag-contrast;
      border-color: $color-wcag-contrast;

      &:after {    
      border: solid black;
      border-width: 0 0.11rem 0.11rem 0;
      }
    }
  }
  
  .pcg-radio-container {
    input:checked ~ .pcg-radio {
      background-color: $pcg-white;
      border-color: $color-wcag-contrast;
    }
  }
}