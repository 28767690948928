.shadow-box-b0
{
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);  
}

.shadow-box-b1
{ 
    box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.1);  
}

.shadow-box-b2
{ 
    box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);  
}

.rounded-box-5
{
    border-radius: 5px;
}

.pcg-page-container {
    min-height: 100vh;
    min-width: 320px;
    max-width: 1600px;
    background-color: $pcg-the-lightest-gray;
}
.pcg-body-container {    
    height: calc(100vh - #{$header-height});
}
.pcg-content-container {
    background-color: $pcg-the-lightest-gray;
    overflow: auto;
    width: 100%;
    min-height: calc(100vh - #{$header-height} - #{$footer-height});   
    padding: 30px 20px;
    @include medium-up {
        padding: 30px 60px ;
    } 
}

.pcg-scrollable {
    overflow: auto;
    height: 100%;
}


.form-group {
    position: relative;
    margin-bottom: 20px;
}

.pcg-wcag-contrast-green-black {
    .pcg-page-container,
    .pcg-content-container
    {
        background-color: $color-wcag-background;
    }
}