.pcg-section {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);  
  border-radius: 5px;
  margin-bottom: 1.43rem;
  .panel-default,
  .card-header {
    border: none;
  }
  .pcg-section-header,
  .panel-heading {
    font-weight: $bold;
    padding: 1rem 2.14rem 0.79rem 2.14rem;
    border-radius: 5px;
    background-color: $pcg-white;
    .pcg-section-title {
      color: $main-color;
      font-size: $font-size-m;
      letter-spacing: 0.02rem;  
    }
    .pcg-section-opened {
      display: none;
      color: $pcg-white;
    }
    .pcg-section-closed {    
      color: $main-active-color;
    }
    .pcg-section-btn {
      padding: 0;
    }
    .pcg-section-opened,
    .pcg-section-closed {
      cursor: pointer;
      float: right;  
      font-size: $font-size-s;
    }
  }
  
  .pcg-section-content,
  .panel-body {
    display: none;
    background: $pcg-white;  
    border-radius: 0 0 5px 5px;
    padding: 2.14rem;
  }
  &.pcg-section-active,
  &.panel-open {
    .pcg-section-header,
    .panel-heading,
    .card-header {
      border: none;
      background-color: $main-active-color;
      border-radius: 5px 5px 0 0;
      .pcg-section-title {
        color: $pcg-white;
      }
      .pcg-section-closed {
        display: none;
      }
      .pcg-section-opened {
        display: block;
      }
    }    
    .pcg-section-content,
    .panel-body {
      display: block;
    }
  }
}

.pcg-section-info {
  font-family: 'Roboto', sans-serif;
  font-weight: $bold;
  font-size: $font-size-s;
  line-height: 1.29rem;
  padding: 1.57rem 2.14rem 1.43rem 2.14rem;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: $pcg-white;
  margin: 0.71rem 0;
  .pcg-info-property {
    color: $pcg-the-darkest-gray;
  }
  .pcg-info-value {
    float: right;
    color: $main-active-color;
  }
}