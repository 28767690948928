.pcg-table-header-I {
    font-weight: $bold;
    font-size: $font-size-m;
    letter-spacing: 0.02rem;
    display: table;
    padding: 1rem 2.14rem 0.86rem 2.14rem;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    color: $pcg-white;
    background-color: $main-color;
    span {
        display: table-cell;
        vertical-align: middle;
        line-height: 1.36rem;
    }
    .pcg-header-link {
        cursor: pointer;
        display: table-cell;
        text-align: right;
        line-height: 1rem;
        float: right;
        font-size: $font-size-s;
        letter-spacing: 0;
        color: $main-active-color;
    }

    .pcg-header-info{
        text-align: right;
        line-height: 1rem;
        display: flex;
        font-size: $font-size-s;
        letter-spacing: 0;
        color: $main-active-color;
    }
    &.pcg-danger-header{
        background-color: $alert-error
    }
}
.pcg-table-header-II {
    font-weight: $bold;
    letter-spacing: 0;
    line-height: 1.14rem;
    padding: 0.86rem 2.14rem 0.93rem 2.14rem;    
    color: $pcg-the-darkest-gray;
    background-color: $additional-color;
    span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.14rem;
    }
} 
.pcg-table-container {
    box-shadow: 0 10px 20px rgba(0,0,0, 0.1);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1.43rem;
    background-color: $pcg-white;
    
    .pcg-data-table-content {
        padding: 2.14rem;
        border-radius: 0 0 5px 5px;
        .pcg-data-table-section {
            margin-bottom: 1.43rem; 
            font-size: $font-size-s;
            line-height: 1rem;
            &:last-child { 
                margin: 0;
            }
            .pcg-data-table-property {
                margin-bottom: 0.71rem;
                font-weight: $bold;
                letter-spacing: 0.04rem;
                color: $pcg-the-darkest-gray;
            }
            .pcg-data-table-value { 
                font-weight: $regular;  
                color: $pcg-dark-gray;
            }
        }    
    }
    .pcg-table-content {
        padding: 2.14rem 0;
        background-color: $pcg-white;
        border-radius: 0 0 5px 5px;
        
        &-without-margin{
            margin: -30px;
            margin-bottom: 0;
            padding: 0;
        }
 
        .pcg-table {
            width: 100%;  
            border-collapse: collapse;    
            font-size: $font-size-s;
            .pcg-table-header-row {
                color: $pcg-light-gray;
                font-weight: $bold;
                line-height: $font-size-m;
                th{
                    text-align: left;
                    border-bottom: 1px solid $pcg-lightest-gray;
                    padding-bottom: 0.71rem;
                    padding-right: 5px;
                    padding-left: 5px;
                    
                    &:first-child {
                        padding-left: 2.14rem;
                    }
                    &:last-child {
                        padding-right: 2.14em;
                    } 
                }
            }
            .pcg-table-narrow-row,
            .pcg-table-wide-row {
                color: $pcg-dark-gray;
                font-weight: $regular;
                line-height: $font-size-l;
                td{
                    border-bottom: 1px solid $pcg-lightest-gray;
                    padding-right: 5px;
                    padding-left: 5px;

                    pcg-table-cell-ul {
                        padding-left: 15px;
                    }
                    &:first-child {
                        padding-left: 2.14rem;
                    }
                    &:last-child {
                        padding-right: 2.14rem;
                    }
                }        
            }
            .pcg-table-narrow-row {
                td,
                &>div {
                    min-height: 3.14rem;
                    padding: 1.07rem 5px;
                }
            }
            .pcg-table-wide-row {  
                td, 
                &>div {          
                    height: 5.26rem;
                    padding: 1.5rem 5px;
                }
            }
            
            .pcg-table-summary-row {
                color: $main-color;
                font-weight: $bold;
                td, 
                &>div {                    
                    border-bottom: none;
                } 
            }
        }
    }  
    &.pcg-table-responsive {
        min-width: unset;
        overflow-x: scroll;

        .pcg-table-content {
            min-width: max-content;
        }
    }
}

.pcg-table-section {  
    cursor: pointer;
    .pcg-section-header {
        font-weight: $bold;
        letter-spacing: 0.02rem;
        line-height: 1.14rem;
        margin-bottom: 0.14rem;
        padding: 0.93rem 2.14rem 0.86rem 2.14rem;
        color: $pcg-the-darkest-gray;
        background-color: $additional-color;
        .pcg-icon-section-header {
            cursor: pointer;
            margin-right: 0.71rem;
            color: $pcg-white;
            background-color: $main-active-color;
            font-size: 1rem;
            vertical-align: middle;
            float: left; 
            line-height: 1.14rem;
            border-radius: 50%;
            width: 1.07rem;
            height: 1.07rem;
            text-align: center;
            @extend .pcg-icon-arrow-down;
        }
    }
    .pcg-section-content {
        display: none;
    }
    
    &.pcg-table-section-active {
        .pcg-section-content {
            display: block;
        }
        .pcg-section-header {
            .pcg-icon-section-header {
                @extend .pcg-icon-arrow-up;
            }
        }        
    }
    
    &:last-child {    
        padding-bottom: 2.14rem;
        .pcg-table-content {
            padding-bottom: 0;
        }
        .pcg-section-header {
            margin-bottom: 0;
        }
    }
}

.pcg-table-subgroup {
    text-align: left; 
    border-bottom: 1px solid $pcg-the-darkest-gray; 
    line-height: 0;
    margin: 2.86rem 2.14rem; 
    span { 
        font-size: 1rem;
        font-weight: $bold;
        color: $pcg-the-darkest-gray;
        background: $pcg-white; 
        padding-right: 0.71rem; 
    }
 } 

 .pcg-wcag-contrast-green-black {
    .pcg-table-header-I {
        color: $color-wcag-background;
        background-color: $color-wcag-contrast;

        .pcg-header-link {
            color: $color-wcag-background;
        }
    
        .pcg-header-info{
            color: $color-wcag-background;
        }
    }

    .pcg-table-container {
        background-color: $color-wcag-background;
        border: 1px solid;
        border-color: $color-wcag-static;
        .pcg-data-table-content {    
            border: 1px solid $color-wcag-static;   
            .pcg-data-table-section {
                .pcg-data-table-property {
 
                    color:$color-wcag-static;
                }
                .pcg-data-table-value { 
 
                    color: $color-wcag-static;
                }
            }    
        }
        .pcg-table-content {
            background-color: $color-wcag-background;
    
            .pcg-table {           
                .pcg-table-header-row {
                    color:$color-wcag-static;
                    background-color:$color-wcag-background;
                    th{
                     
                        border-bottom: 1px solid $pcg-lightest-gray;
                    }
                }
                .pcg-table-narrow-row,
                .pcg-table-wide-row {
                    color:$color-wcag-static;
                    background-color:$color-wcag-background;
                    td{
                        border-bottom: 1px solid $pcg-lightest-gray;
                    }        
                }
             
                .pcg-table-summary-row {
                    color:$color-wcag-static;
                }
            }
        }  
       
    }
 }    