.pcg-footer-container {
    margin-top: 15px;
    line-height: $font-size-xl;
    min-height: $footer-height;
    background-color: $pcg-the-lightest-gray; 
    margin: 0 20px;
    display: flex;
    align-items: center;
    border-top: solid 1px $pcg-lightest-gray; 
    @include medium-up {
        margin: 0 60px;
    }

    img {
        height: 25px;
    }

    .pcg-footer-text {
        font-size: $font-size-xs;
        color: $pcg-gray;
        margin-left: auto;
        letter-spacing: 0.4px;
 
    }
}
.pcg-footer-custom-container{
    display: $pcg-footer-display;
}
.pcg-client-footer-container{
    display: $pcg-client-footer-display;
}
.pcg-custom-footer-container{
    display: $pcg-custom-footer-display;
}

.pcg-footer-custom{
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: $pcg-footer-display;

    &-img{
        background-image: $logo-footer-url;
        width:  $logo-footer-width;
        height:  $logo-footer-height;
        background-repeat: no-repeat;
    }
}

.pcg-client-footer{
    display: $pcg-client-footer-display;
    padding-top: 20px;
    padding-bottom: 20px;

    &-img{
        background-image: $logo-client-footer-url;
        width:  $logo-client-footer-width;
        height:  $logo-client-footer-height;
        background-repeat: no-repeat;
    }
}

.pcg-custom-footer{
    display: $pcg-custom-footer-display;
    padding-top: 20px;
    padding-bottom: 40px;
}

footer {
    .container{
        background:$pcg-the-lightest-gray; 
        padding: 10px;
        
        img{
          float: left;
          left: 20px;
          position: relative;
        }
    }

    a {
        color: #fff;
        font-size: 20px;
        padding: 10px;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;

        &:hover{
          position:relative;
          bottom:1px;
          -webkit-transition: all .5s ease;
          transition: all .5s ease;
          color: #fff;
        }
    }
}

  
.pcg-footer-icons {
	margin-right: 2rem;
}

.footer-mid {
	.footer-mid-container {
		height: auto;
		line-height: 25px;
	}
	
	.pcg-footer-text {
		text-align: right;
		font: Regular 12px/20px Roboto;
		letter-spacing: 0.48px;
		color: #9D9D9D;
		opacity: 1;
	}
	
	.pcg-footer-section {
		margin-top: 1rem;
		margin-bottom: 1.5rem;
	}
}

.pcg-footer-info {
	text-align: left;
	font-size: 0.85714em;
	letter-spacing: 0.48px;
	color: #9D9D9D;
	opacity: 1; 
	line-height: 2px;
}
.pcg-footer-container .img-ue{
	height: 60px;
}
@media screen and (max-width: 868px) {
	.pcg-footer-img {
		display: none;
	}
}
  
.pcg-footer-social-media {
    display: inline-block;
    color: $pcg-light-gray;

    a,
    a:hover {
        text-decoration: none;
        margin-top: 10px;
        margin-bottom: 10px;
        display: inline-block;
    }

    .pcg-icon {
        color: $pcg-light-gray;
        font-size: $font-size-xl;
        margin-left: 5px;
    }
 
    .pcg-icon-facebook:hover {
        color: $facebook-color;
    }

    .pcg-icon-instagram:hover {
        color: $instagram-color;
    }

    .pcg-icon-youtube:hover {
        color: $youtube-color; 
    }

    .pcg-icon-twitter:hover {
        color: $twitter-color;
    }

    .pcg-icon-linkedin:hover {
        color: $linkedin-color;
    }
} 

.pcg-wcag-contrast-green-black {

    .pcg-footer-social-media {
        display: inline-block;
        color: $pcg-light-gray;

        a,
        a:hover {
            text-decoration: none;
            margin-top: 10px;
            margin-bottom: 10px;
            display: inline-block;

            &:hover {
                color: $pcg-light-gray;
            }
        }

        .pcg-icon {
            color: $pcg-light-gray;
        }
    }
}

.pcg-wcag-contrast-green-black {
    .pcg-footer-container {
        background-color: $color-wcag-background;
        border-top: solid 1px $pcg-lightest-gray;

        .pcg-footer-text {
            color:  $color-wcag-static;
        }
    }
}