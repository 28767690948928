.pcg-title {
    font-size: $font-size-xl;
    font-weight: $bold;
    line-height: 2rem;
    letter-spacing: 0.04rem;
    color: $main-color;
    margin: 40px 0;
    text-transform: uppercase;
    display: block;
}

.pcg-login-title{
    font-family: 'Roboto';
    font-size: $font-size-xxl;
    font-weight: $bold;
    letter-spacing: 1.52px;
    color: $main-color;
}

.pcg-subtitle {
    font-size: 2em;
    color: $main-color;
    font-weight: $bold;
    line-height: 1.36em;
}

h2 {
    font-size: $font-size-l;
    font-weight: $bold;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
}
  
h3 {
    font-size: $font-size-m;
    font-weight: $bold;
    line-height: 1.36rem;
    letter-spacing: 0.02rem;
}
  
.pcg-date {
    font-size: $font-size-m;
    font-weight: $medium; 
    line-height: 1.36rem;
    letter-spacing: 0.04rem;
}
  
.pcg-name {
    font-weight: $bold;
    line-height: 1.14rem;
    letter-spacing: 0.04rem;
}
  
h4 {
    font-size: 1rem;
    font-weight: $bold;
    line-height: 1.14rem;
    letter-spacing: 0.02rem;
}
.pcg-text-medium{
    font-weight: $medium;
}
.pcg-menu-txt {
    font-weight: $medium;
    line-height: 1.14rem;
    letter-spacing: 0.04rem;
}
  
.pcg-txt-small-bold {
    font-size: $font-size-s;
    font-weight: $bold;
    line-height: 1rem;
}
  
.pcg-txt-small {
    font-size: $font-size-s;
    font-weight: $regular;
    line-height: 1rem;
}
  
.pcg-txt-xs {
    font-size: $font-size-xs;
    font-weight: $regular;
    line-height: 1rem;
}

.pcg-txt-right {
    float: right;
}

.pcg-text{
    font-family: 'Roboto', sans-serif;
 
    &-bold{
        font-weight: $bold;
    }

    &-font-size-s{
        font-size:  $font-size-s;
    }

    &-font-size-m{
        font-size:  $font-size-m;
    }

    &-font-size-l{
        font-size:  $font-size-l;
    }

    &-color-gray{
        color: $pcg-gray;
    }
    
    &-color-dark-gray{
        color: $pcg-dark-gray;
    }

    &-color-the-darkest-gray{
        color: $pcg-the-darkest-gray;
    }

    &-color-light-gray{
        color: $pcg-the-darkest-gray;
    }
}

.pcg-wcag-contrast-green-black {
    .pcg-title {     
        color: $color-wcag-static;
    }
    
    .pcg-login-title{
        color: $color-wcag-static;
    }

    .pcg-subtitle {
        color: $color-wcag-static;
    }

    .pcg-text{
        &-color-gray{
            color: $color-wcag-static;
        }
        
        &-color-dark-gray{
            color: $color-wcag-static;
        }
    
        &-color-the-darkest-gray{
            color: $color-wcag-static;
        }
    }
}