.pcg-sidebar-container {
    z-index: 2;
    width: 18.57rem;
    background-color: $pcg-white;    
    padding: 0;
    display: none;
    max-height: inherit;
    box-shadow: 6px 0 30px 0 rgba(0, 0, 0, 0.1);
    &.open-menu {
        position: absolute;
        display: block;
        height: calc(100vh - #{$header-height});
    }

    @include medium-up {
        display: block;
        &.open-menu {
            position: relative;
        }
    }
    .panel-group {
        .card {
            border: none;
            border-radius: 0;
            background: none;
            .card-header {
                padding: 0;
                border: none;
                background: none;
                border-radius: 0;
                button.btn.btn-link.btn-block.clearfix {
                    padding: 0;
                    border: none;
                }
            }
            .card-body {
                padding: 0;
            }
        }
        
        .panel-open {
           .pcg-sidebar-section-header {
            @extend .active;
           }            
           .list-group-item {
                padding-left: 5rem;
                background-color: $pcg-the-lightest-gray;
                &.active {
                    background-color:$main-select-color;
                    color: $pcg-white;
                }
            }    
        }
    }
    .list-group {
        height: 100%;
        .list-group-item,
        .pcg-sidebar-section-header {
            border-radius: 0;
            line-height: 1.14rem;
            padding: 0.96rem 2.14rem 1.1rem 2.14rem;
            margin: 0;
            border: none;
            color: $pcg-dark-gray;
            text-align: left;
            font-weight: $medium;
            cursor: pointer;
            .pcg-sidebar-icon {
                font-size: $font-size-m;
                margin-right: 1.07rem;
                width: 1.79rem;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
            }
            &:hover {
                background-color: $hover-color;
                color: $pcg-white;
            }
            &.active {
                background-color: $main-color;
                color: $main-active-color;
            }
        }
    }
    .pcg-sidebar-section-header {   
        .pcg-icon-sidebar-section {
            vertical-align: middle;
            float: right; 
            line-height: 1.14rem;
            @extend .pcg-icon-arrow-down;
        }
        .pcg-icon-nav {
            color: $main-active-color;
        }
        &.active {
           .pcg-icon-sidebar-section {
                color: $main-active-color;
                @extend .pcg-icon-arrow-up;
            }
        }
        &:hover {
            .pcg-icon-nav {
                color: $pcg-white;
            }
        }
    }
    .pcg-sidebar-section-content {
        .list-group-item {
            padding-left: 5rem;
            background-color: $pcg-the-lightest-gray;
            &.active {
                background-color:$main-select-color;
                color: $pcg-white;
            }
        }    
    }
}

.pcg-btn-sidebar-menu {
    cursor: pointer;
    color: $pcg-white;
    background-color: $main-color;
    width: 1.43rem;
    height: 2.14rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    -webkit-border-top-right-radius: 100px;
    -webkit-border-bottom-right-radius: 100px;
    -moz-border-radius-topright: 100px;
    -moz-border-radius-bottomright: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    position: fixed;
    z-index: 1000;
    display: none;
    .pcg-icon-menu-arrow {
        @extend .pcg-icon-arrow-left;
    }
    &.hidden {
        .pcg-icon-menu-arrow {
            @extend .pcg-icon-arrow-right;
        }
    }
    &:hover{
        background-color: $hover-color;
    }
    @include medium-up {
        display: flex;
    }
}

.pcg-wcag-contrast-green-black {
    .pcg-sidebar-container {
        border: 1px solid $color-wcag-contrast;
        border-top: none;
        background-color: $color-wcag-background;

        .list-group .list-group-item, 
        .list-group .pcg-sidebar-section-header{
            color: $color-wcag-contrast;
            background-color: $color-wcag-background;

            &:hover{
                background-color: $color-wcag-contrast;
                color: $color-wcag-background;   
            }
        }
    }

}