//NOTIFY
.pcg-stack {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: auto;
}

.pcg-icon-notify:before {
    color: $main-active-color;
    background-color: $pcg-white;
    border-radius: 100%;
    font-size: 0.7em;
    position: absolute;
}

.pcg-icon-envelope-notify:before {
    right: 0.7em;  
    top: -0.2em;
}

.pcg-icon-bell-notify:before {
    right: 1em; 
    top: -0.1em;
}

.pcg-icon-disabled{
    color: #BDBEBD !important;
}

//CIRCLE BACKGROUNDS
.pcg-icon-circle {
    &-action {
        border-radius: 100%;
        background-color: $additional-color;
        color: $main-active-color;
        &:hover {
            text-decoration: none;
        }
    }

    &-notify-unreaded {
        border-radius: 100%;
        background-color: $main-active-color;
        color: $pcg-white;
        &:hover {
            text-decoration: none;
        }
    }

    &-notify-readed {
        border-radius: 100%;
        background-color: $pcg-light-gray;
        color: $pcg-white;
        &:hover {
            text-decoration: none;
        }
    }
}

.pcg-icon-circle {
    background-color: $additional-color;
    border-radius: 100%;
    color: $main-active-color;

    &:active,
    &:focus,
    &:hover {
        color: $hover-color;
        text-decoration: none;
    }
}
.pcg-icon-circle {
    &-arrow {
        border-radius: 100%;
        background-color: $main-active-color;
        color: $pcg-white;
    }

    &-action {
        border-radius: 100%;
        background-color: $additional-color;
        color: $main-active-color;
    }

    &-notify-unreaded {
        border-radius: 100%;
        background-color: $main-active-color;
        color: $pcg-white;
    }

    &-notify-readed {
        border-radius: 100%;
        background-color: $pcg-light-gray;
        color: $pcg-white;
    }
}

//PROCES DECISION
.pcg-icon-proces-negative-decision {
    color: $alert-error;
}
.pcg-icon-proces-positive-decision {
    color: $alert-success;
}

//WCAG CONTRAST
.pcg-wcag-contrast-green-black {
    .pcg-icon-notify:before {
        color: $color-wcag-contrast;
        background-color: $color-wcag-background;
    }


    .pcg-icon-circle {
        background-color: $color-wcag-contrast;
        color: $color-wcag-background;

        &:active,
        &:focus,
        &:hover {
            color: $color-wcag-background;
        }
    }

    .pcg-icon-arrow-circle {
        background-color: $color-wcag-contrast;
        color: $color-wcag-background;

        &:active,
        &:focus,
        &:hover {
            color: $color-wcag-background;
        }
    }

    .pcg-icon-circle {
        &-arrow {
            background-color: $color-wcag-contrast;
            color:  $color-wcag-background;
        }

        &-action {
            background-color: $additional-color;
            color: $main-active-color;
        }

        &-notify-unreaded {
            background-color: $color-wcag-contrast;
            color: $color-wcag-background;
            border: 1px solid $color-wcag-background;
        }

        &-notify-readed {
            background-color: $color-wcag-background;
            color: $color-wcag-contrast;
            border: 1px solid $color-wcag-contrast;
        }
    }
}

//SIZING
.pcg-xs {
    font-size: .45em;
}

.pcg-sm {
    font-size: .75em;
} 

.pcg-md {
    font-size: .875em;
}

.pcg-lg {
    font-size: 1.33333em
}

.pcg-2x {
    font-size: 2em;
}

.pcg-3x {
    font-size: 3em;
}

.pcg-4x {
    font-size: 4em;
}

.pcg-5x {
    font-size: 5em;
}

.pcg-6x {
    font-size: 6em;
}

.pcg-7x {
    font-size: 7em;
}