.modal-dialog{
    pointer-events: all;
    width: auto;
}
.pcg-modal{
    border-radius: 5px;
    pointer-events: all;

    &-xs{
        max-width: 300px;
    }

    &-sm{
        max-width: 500px;
    }

    &-md{
        max-width: 700px; 
    }

    &-lg{
        max-width: 1000px;
    }

    &-xl{
        max-width: 1200px;
    }

    &-header{
        padding: 10px 30px;
        min-height: 44px;
        line-height: $font-size-l;
        font-size: $font-size-m;
        color: $pcg-white;
        background-color: $main-color;
        font-weight: $bold;
        border-radius: 5px 5px 0 0;
        display: flex;
        flex-direction: row; 
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
    &-footer{
        display: flex; 
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center; 
        align-content: center; 
        padding-top: 30px;

        .pcg-button{
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }
    &-body{
        padding: 30px 30px 20px 30px;
        background-color: $pcg-white;
        border-radius: 0 0 5px 5px;
 
            &-without-padding{
                padding: 0;
                padding-bottom: 20px;

                & > .pcg-modal-footer{
                    padding-right: 30px;
                    padding-left: 30px;
                }
            }

            .pcg-table-container{
                padding-bottom: 0px; 
                margin-bottom: 0px;

                .pcg-table-empty-row td{
                    padding-top: 30px;
                    padding-left: 30px;
                    color: $pcg-dark-gray;

                }
            }
    }
        
     
    &.pcg-modal-danger{
        .pcg-modal-header{
            background-color: $alert-error;
        }
    }
}
.pcg-wcag-contrast-green-black {
    .pcg-modal{

        border: 1px solid $color-wcag-contrast;
        &-header{
            border-radius: unset;
            color: $color-wcag-background;
            background-color: $color-wcag-contrast;
        }
     
        &-body{
            background-color: $color-wcag-background;
        } 

        &.pcg-modal-danger{
            border: 1px solid $alert-error;
            .pcg-modal-header{
                border-radius: unset;
                background-color: $alert-error;
                color: $color-wcag-static;
            }
        }
    }
}