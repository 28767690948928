.pcg-datetimepicker-btn {
    @extend .pcg-button;
    @extend .pcg-main-btn;
    @extend .pcg-small-btn;
    margin: 0.5rem 0 0 1.43rem;
    padding: 0 1.21rem;
    border-radius: 100px !important;
}

.pcg-datetimepicker-input {
    @extend .pcg-input;
    @extend .pcg-field;
    border-radius: 100px !important;
}


.bootstrap-datetimepicker-widget.dropdown-menu {
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    width: unset;
    .datepicker-days,
    .datepicker-months,
    .datepicker-years,
    .datepicker-decades,
    .datepicker-centuries {
        padding: 1.86rem;
        table {
            thead {
                tr {
                    th {
                        border: none;
                        &.prev {
                            text-align: left;
                            color: $main-active-color;
                            padding-bottom: 2.14rem;
                        }
                        &.picker-switch {
                            font-size: 1.07rem;
                            line-height: 1.29rem;
                            color: $main-color;
                            font-weight: $bold;
                            padding-bottom: 2.14rem;
                        }
                        &.next {
                            text-align: right;
                            color: $main-active-color;
                            padding-bottom: 2.14rem;
                        }
    
                        &.dow {
                            width: 2.57rem;
                            padding-bottom: 1.07rem;
                            line-height: 1.14em;
                            color: $pcg-gray;
                            font-weight: $bold;
                            border-bottom: solid 1px $pcg-lightest-gray;                       
                        }
                        &:hover{
                            background-color: transparent;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        border: none;
                        font-size: 1rem;
                        font-weight: $regular;
                        color: $main-color;
                        vertical-align: middle;
                        &.day {
                            height: 2.57rem;
                            line-height: 1.14rem;     
                        }
                        .month,
                        .year,
                        .decade,
                        .century {
                            width: 3.21rem;
                            height: 3.21rem;
                            line-height: 3.21rem;
                        }
                        &.day,
                        span {
                            &:hover{
                                color: $pcg-white;
                                background-color: $hover-color;
                                border-radius: 50%;
                            }
                            &.active {
                                color: $pcg-white;
                                background-color: $main-active-color;
                                border-radius: 50%;
                                &:hover{
                                    background-color: $main-active-color;
                                }
                            }
                        }
                        &.old,
                        span.old,
                        &.new,
                        span.new {
                            color: $pcg-lightest-gray;
                        }
                    }
                    
                }
            }
        }
    }
    .datepicker-days {
        table {            
            tbody{
                                
                td.today::before {
                    border-bottom-color: $main-active-color;
                    bottom: 0.57rem;
                    right: 0.57rem;
                    &:hover{
                        border-bottom-color: $pcg-white;
                    }
                }
                &:before {
                    content: "";
                    display: block;
                    line-height: 1em;
                }
            }
        }
    }
    .picker-switch {
        span {
            color: $main-color;
            &:hover{
                color: $pcg-white;
                background-color: $hover-color;
                border-radius: 5px;
            }
        }
    }
    .timepicker {
        padding: 10px;
        table { 
            td, td span { 
                height: 2.14rem;
                width: 2.14rem; 
                line-height: 2.14rem;

                &.hour,
                &.minute {
                    &:hover {
                        color: $pcg-white;
                        background-color: $hover-color;
                        border-radius: 50%;
                    }
                }
            } 
            .timepicker-hour, 
            .timepicker-minute { 
                color: $main-active-color; 
                font-size: 1em; 
            }  
            .btn, 
            .separator,
            .hour,
            .minute { 
                color: $main-color; 
            } 
            span {                
                &:hover {
                    color: $pcg-white;
                    background-color: $hover-color;
                    border-radius: 50%;
                }
            }
        } 
    }
}

.bs-timepicker-field {
    padding-left: 0.86rem;
}

timepicker {
    table {
        margin: 0.71rem 0;
    }
    .bs-chevron {
        color: $main-color;
    }
    .btn {
        &:focus {
            .bs-chevron {
                color: $pressed-color;
            }
        }
        &:hover {
            .bs-chevron {
                color: $hover-color;
            }
        }
    }
}


.bs-datepicker {
    box-shadow: none;
}
.bs-datepicker-container {
    padding: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    .bs-datepicker-head {
        text-align: center;
        background-color: $pcg-white !important;
        padding: 2.14rem 2.14rem 0px 2.14rem;
        button {
            color: $main-active-color;
            &.previous {
                text-align: left;
                float: left;
            }
            &.next {
                text-align: right;
                float: right;
            }
            &.current {
                color: $main-color;
                padding: 0;
                font-weight: $bold;
                font-size: 1.07rem;
            }
            &.current, &.previous, &.next {
                &:hover {
                    color: $hover-color;
                    background-color: transparent;
                }
            }
        }
    }
    .bs-datepicker-body {
        border: none;
        padding: 0;
        margin-top: 1.79rem;
        min-height: unset;
        table {           
            margin-bottom: 1.07rem;
            thead {
                tr {                    
                    th {
                        color: $pcg-gray;
                        font-size: $font-size-base;
                        font-weight: $bold;
                        padding-bottom: 0.79rem;
                        border-bottom: solid 1px $pcg-lightest-gray;
                        &:first-child {
                            text-align: left;
                            padding-left: 2.14rem;
                        }                    
                        &:last-child {
                            text-align: right;
                            padding-right: 2.14rem;
                        }
                    }
                }
            }
            tbody {
                tr { 
                    td {
                        color: $pcg-the-darkest-gray;
                        font-weight: $regular;
                        font-size: $font-size-base;
                        vertical-align: middle;
                        &:first-child {
                            padding-left: 1.07rem;
                        }                    
                        &:last-child {
                            padding-right: 1.07rem;
                        }  
                        .selected {
                            color: $pcg-white;
                            background-color: $main-active-color;
                        }     
                        .is-highlighted:not(.selected) {
                            color: $pcg-white;
                            background-color: $hover-color;                            
                        }  
                        &.is-highlighted:not(.selected) {
                            span {
                                color: $pcg-white;
                                background-color: $hover-color;   
                            }                         
                        }           
                    }         
                }
            }                
            &.days {
                span {
                    width: 2.57rem;
                    height: 2.57rem;
                    line-height: 2.57rem;
                    margin: 0.36rem;
                }
                .is-other-month {
                    display: none;
                    margin: 0;
                }
            }
        }
    }
}