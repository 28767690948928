.pcg-tooltip{    
    &.bs-tooltip-auto[x-placement^=top] .arrow::before, 
    &.bs-tooltip-top .arrow::before {
        border-top-color: $main-color;  
    }
    &.bs-tooltip-auto[x-placement^=bottom] .arrow::before, 
    &.bs-tooltip-bottom .arrow::before {
        border-bottom-color: $main-color;  
    }
    &.bs-tooltip-auto[x-placement^=left] .arrow::before, 
    &.bs-tooltip-left .arrow::before {
        border-left-color: $main-color;  
    }
    &.bs-tooltip-auto[x-placement^=right] .arrow::before, 
    &.bs-tooltip-right .arrow::before {
        border-right-color: $main-color;  
    }
    .tooltip-inner {
        background-color: $main-color;  
        border-radius: 10px;      
    }
}