.pcg-input-label {
  font-size: $font-size-s;
  color: $pcg-the-darkest-gray;
  font-weight: $bold;
  line-height: 1rem;
  margin-bottom: 0.71rem;
  display: block;
  
  &-value{
    font-size: $font-size-s;
    color: $pcg-gray;
    line-height: 1rem;
    font-weight: $regular;
    margin-bottom: .71rem;
    display: block;
  }
}
.pcg-input-label-required::after {
  content: '*';
  color: $main-active-color;
  padding-left: 2px;
}
.pcg-input {
  padding-left: 1.43rem;
  height: 2.86rem;
  border-radius: 100px;
}
.pcg-textarea {
  padding: 1.43rem;
  border-radius: 5px;
}
.pcg-field {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border: none;
  font-size: $font-size-s;
  line-height: 1rem;
  color: $pcg-the-darkest-gray;  
  
  &::placeholder {
    color: $pcg-gray;
    opacity: 1;
  }
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $pcg-gray;
  }
  
  &:hover {
    color: $main-active-color;
    &::placeholder {
      color: $main-active-color;
      opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $main-active-color;
    }
  }
  &:focus {    
    color: $pcg-the-darkest-gray; 
    box-shadow: 0 3px 10px rgba($main-active-color, 0.3);
    &::placeholder {
      color: $pcg-gray;
      opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $pcg-gray;
    }
  }
  &:invalid {
    color: $pcg-the-darkest-gray;
    box-shadow: 0 3px 10px rgba($alert-error, 0.3);
    &::placeholder {
      color: $pcg-gray;
      opacity: 1;
    }
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $pcg-gray;
    }
  }
  &:disabled,
  &:read-only {
    cursor: not-allowed;
    color: $pcg-light-gray;
    background-color: $pcg-lightest-gray;        
    box-shadow: none;
    &:hover {
      color: $pcg-light-gray;
      &::placeholder {
        color: $pcg-gray;
        opacity: 1;
      }
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: $pcg-gray;
      }
    }
  }
}

.ng-invalid ~ .pcg-field-invalid,
.ng-valid ~ .pcg-field-valid,
.pcg-field:invalid ~ .pcg-field-invalid,
.pcg-field:valid ~ .pcg-field-valid {
  @extend .alert;
  background: none;
  border: none;
  padding: 0;
  padding-top: 0.57rem;
  font-size: $font-size-xs;
  line-height: 0.79rem;
}

.ng-invalid ~ .pcg-field-invalid,
.pcg-field ~ .pcg-field-invalid {  
  color: $alert-error;
}

.ng-valid ~ .pcg-field-valid,
.pcg-field ~ .pcg-field-valid {  
  color: $alert-success;
}

.ng-invalid ~ .pcg-field-valid,
.ng-valid ~ .pcg-field-invalid,
.pcg-field:valid ~ .pcg-field-invalid,
.pcg-field:invalid ~ .pcg-field-valid {
  display: none;
}

.pcg-wcag-contrast-green-black {
  .pcg-input-label {
      color: $color-wcag-static;
  
    &-value{
      color: $color-wcag-static;
    }
  }
}