.pcg-button-without-outline {

  &:focus,
  .focus {

    &:focus,
    .focus {
      outline: none;
    }
  }
}

.pcg-button {
  border-radius: 100px;
  font-weight: $bold;
  cursor: pointer;
  min-width: max-content;
  @extend .pcg-button-without-outline;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: max-content;

  &-login {
    min-height: 40px;
    border: none;
    width: 100%;
    position: relative;
    font-weight: $bold;
    border-radius: 100px;
    letter-spacing: 0.28px;

    &-emphasize {
      background:$main-color;
      box-shadow: 0px 3px 10px rgba($main-color, 0.2); 
      max-width: 220px;
      width: 100%;
      line-height: 18px;
      color: $pcg-white;
      &:hover {
        background-color: lighten($main-color, 10%);
        box-shadow: 0px 10px 20px rgba(lighten($main-color, 10%), 0.4);
      }
    }

    &-external {
      background: $pcg-white;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      color: $pcg-dark-gray;
      margin-bottom: 20px;
      &:hover {
        box-shadow: 0px 10px 20px rgba($main-active-color, 0.4);
      }
    }

    .provider-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 15px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.btn-google {
      color: #808080;

      .provider-icon {
        background-image: url('../../images/src/google.svg');
      }
    }

    &.btn-fb {
      color: #3B5998;

      .provider-icon {
        background-image: url('../../images/src/facebook.svg');
      }
    }

    &.btn-twitter {
      .provider-icon {

      }
    }

    &.btn-oic {
      .provider-icon {
 
      }
    }

    &.btn-epuap {
      .provider-icon {

      }
    }

    &.btn-saml {
      .provider-icon {
  
      }
    }

    &.btn-cas {
      .provider-icon {

      }
    }
  }
}


.pcg-btn-return {
  margin-bottom: 2.86rem;
  display: inline-flex;
  align-self: start;
  .pcg-button{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 0 15px;

  }

  .pcg-nav-btn{
      height: 30px;
      width: auto;
  }

  .pcg-return-title {
      margin-left: 2.14rem;
      font-size: 1.29em;
      color: $main-color;
      font-weight: $bold;
      line-height: 2rem;
  }

  & .pcg-icon-arrow-left {
      line-height: 1.21rem;
  }
}


.pcg-main-btn {
  span {
    color: $pcg-white;
  }

  background-color: $main-active-color;
  color: $pcg-white;
  border: none;
  padding: 0 2.14rem;
  box-shadow: 0px 10px 20px rgba($main-active-color, 0.4);

  &:hover {
    background-color: $hover-color;
    box-shadow: 0px 10px 20px rgba($hover-color, 0.4);
  }

  &:active,
  &:focus {
    background-color: $pressed-color;
    box-shadow: 0px 10px 20px rgba($pressed-color, 0.4);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $disabled-color;
    box-shadow: 0px 10px 20px rgba($disabled-color, 0.4);
  }

  &.pcg-danger-btn {
    background-color: $alert-error;
    box-shadow: 0px 10px 20px rgba($alert-error, 0.4);

    &:hover,
    &:active,
    &:focus {
      background-color: darken($alert-error, 10%);
      box-shadow: 0px 10px 20px rgba($alert-error, 0.4);
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background-color: lighten($alert-error, 22%);
      box-shadow: 0px 10px 20px rgba($alert-error, 0.25);
    }
  }
}

.pcg-normal-btn {
  height: 2.86rem;
}

.pcg-small-btn {
  font-size: $font-size-s;
  height: 2.14rem;
}


.pcg-additional-btn {
  background-color: transparent;
  color: $main-active-color;
  border: solid 2px $main-active-color;
  padding: 0 2rem;

  &:hover {
    color: $hover-color;
    border-color: $hover-color;
  }

  &:active,
  &:focus {
    color: $pressed-color;
    border-color: $pressed-color;
  }

  &:disabled {
    cursor: not-allowed;
    color: $disabled-color;
    border-color: $disabled-color;
  }

  &.pcg-danger-btn {
    color: $alert-error;
    border-color: $alert-error;

    &:hover,
    &:active,
    &:focus {
      color: darken($alert-error, 10%);
      border-color: darken($alert-error, 10%);
    }
  }
}
 
.pcg-nav-btn {
  background-color: transparent;
  color: $main-active-color;
  border: 2px solid $main-active-color;
  height: 2.14rem;
  padding: 0;
  width: 3.57rem;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  span{
    color: $main-active-color;
  }

  &:hover {
    color: $hover-color;
    border-color: $hover-color;
    text-decoration: none;
    span{
      color: $hover-color;
    }
  }

  &:active,
  &:focus {
    color: $pressed-color;
    border-color: $pressed-color;
    text-decoration: none;
    span{
      color: $pressed-color;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: $disabled-color;
    border-color: $disabled-color;
    text-decoration: none;
    span{
      color: $disabled-color;
    }
  }
}

.pcg-menu-btn {
  background: transparent;
  border: none;

  &:hover {
    i {
      color: $hover-color;
    }
  }

  &:active {
    i {
      color: $pressed-color;
    }
  }

  @extend .pcg-button-without-outline;

  i {
    cursor: pointer;
    color: $main-color;
  }

  @include medium-up {
    display: none;
  }
}

.pcg-dropdown {
  .dropdown-toggle::after {
    display: none;
  }

  .pcg-icon-dropdown-arrow {
    font-size: 1rem;
    vertical-align: middle;
    @extend .pcg-icon-arrow-down;
  }

  &.show {
    .pcg-icon-dropdown-arrow {
      @extend .pcg-icon-arrow-up;
    }
  }

  .pcg-dropdown-option-container {
    border: none;
    display: none;
    position: absolute;
    z-index: 4;
    padding: 0px;
    margin-top: 0.86rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: $pcg-white;

    .pcg-dropdown-option {
      cursor: pointer;
      font-size: $font-size-s;
      line-height: 3.57rem;
      padding: 0 1.43rem;
      color: $pcg-gray;
      font-weight: $medium;
      text-decoration: none;
      display: block;

      &:hover {
        border-radius: 5px;
        text-decoration: none;
        background-color: $pcg-lightest-gray;
        color: $main-active-color;
      }
    }
  }
}

.pcg-wcag-contrast-green-black {
  .pcg-nav-btn,
  .pcg-nav-btn:hover,
  .pcg-nav-btn:focus {
    border-color: $color-wcag-contrast;
    color: $color-wcag-contrast;

    span{
      color: $color-wcag-contrast;
    }
  }


    .pcg-button {
      &-login {
        &-emphasize {
          background-color:$color-wcag-contrast;
          box-shadow: none;
          color: $color-wcag-background;
        }
    
        &-external {
          background-color: $color-wcag-contrast;
          box-shadow: none;
          color: $color-wcag-background;
          
        }

        &:hover {
          color: $color-wcag-background;
          background-color: $color-wcag-contrast;
          opacity: 80%;
        }

        .provider-icon {
          background-image: none;
        }
      }
    }

    .pcg-main-btn {
      span {
        color: $pcg-white;
      }
    
      background-color: $color-wcag-contrast;
      color: $color-wcag-background;
      box-shadow: none;
    
      &:hover,
      &:active,
      &:focus  {
        background-color: $color-wcag-contrast;
        color: $color-wcag-background;
        opacity: 80%;
      }
      &:disabled {
        background-color: $color-wcag-contrast;
        color: $color-wcag-background;
        box-shadow: none;
        opacity: 40%;
      }

    }

    .pcg-additional-btn {
      color: $color-wcag-contrast;
      border: solid 2px $color-wcag-contrast;
    
      &:hover {
        color: $color-wcag-contrast;
        border-color: $color-wcag-contrast;
        opacity: 80%;
      }
    
      &:active,
      &:focus {
        color: $color-wcag-contrast;
        border-color: $color-wcag-contrast;
        opacity: 80%;
      }
    
      &:disabled {
        cursor: not-allowed;
        color:  $color-wcag-contrast;
        border-color:  $color-wcag-contrast;
        opacity: 40%;
      }
  }
}



